//// Colour dictionary
//// Wake me up when Tailwind supports dynamic classes
export const colourDict = [
	{ colour: "25201C", bg: "bg-[#25201C]", border: "border-[#25201C]" },
	{ colour: "0D675B", bg: "bg-[#0D675B]", border: "border-[#0D675B]" },
	{ colour: "7CAC25", bg: "bg-[#7CAC25]", border: "border-[#7CAC25]" },
	{ colour: "2167BC", bg: "bg-[#2167BC]", border: "border-[#2167BC]" },
	{ colour: "4A91E9", bg: "bg-[#4A91E9]", border: "border-[#4A91E9]" },
	{ colour: "3AB3BB", bg: "bg-[#3AB3BB]", border: "border-[#3AB3BB]" },
	{ colour: "A1683D", bg: "bg-[#A1683D]", border: "border-[#A1683D]" },
	{ colour: "C5533B", bg: "bg-[#C5533B]", border: "border-[#C5533B]" },
	{ colour: "F59D18", bg: "bg-[#F59D18]", border: "border-[#F59D18]" },
	{ colour: "FF8A9D", bg: "bg-[#FF8A9D]", border: "border-[#FF8A9D]" },
	{ colour: "8487B5", bg: "bg-[#8487B5]", border: "border-[#8487B5]" },
	{ colour: "D392DC", bg: "bg-[#D392DC]", border: "border-[#D392DC]" }
];


//// Helper methods
export const reorderList = <T,>(list: T[], startIndex: number, endIndex: number) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

export const capFirstLetter = ([first, ...rest]: string, locale = navigator.language) => {
	return first === undefined ? "" : first.toLocaleUpperCase(locale) + rest.join("");
};

export const randomString = () => {
	let result = '';
	const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	for (let i = 0; i < 16; i++) {
		result += chars.charAt(Math.floor(Math.random() * chars.length));
	}
	return result;
};

export const decodeFirebaseID = (id: string) => {
	const pushChars = "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";
	const relID = id.substring(0, 8);
	let timestamp = 0;

	for (let i = 0; i < relID.length; i++) {
		const c = relID.charAt(i);
		timestamp = timestamp * 64 + pushChars.indexOf(c);
	}

	return timestamp;
};
