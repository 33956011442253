import { Node, Edge, XYPosition } from "reactflow";


/**************** Story enums ****************/
export enum StoryType {
	Story = "story",
	Test = "test",
	Onboarding = "onboarding",
	ReOnboarding = "reOnboarding",
	ChooseProgramme = "chooseProgramme",
	DailyCheck = "dailyCheck"
};

export enum StoryCat {
	Dev = "dev",
	AboutApp = "aboutApp",
	Activity = "activity",
	Body = "body",
	StressAnxiety = "stressAnxiety",
	LowMood = "lowMood",
	BeActive = "beActive",
	Sleep = "sleep",
	Help = "help"
};

export enum StorySubCat {
	Relax = "relax",
	Thinking = "thinking",
	LookAfterYourself = "lookAfterYourself"
};

export enum MediaLocation {
	Local = "local",
	Internet = "internet"
};

export enum MediaType {
	Image = "images",
	Icon = "icons",
	Animation = "animation",
	Video = "video",
	Audio = "audio"
};

export enum MsgType {
	BotText = "botText",
	BotNetImg = "botMedia",   // Relic of the past, as internet images
	BotMedia = "botIntMedia", // are still referred to as type "botMedia"
	UserButton = "userButton",
	UserList = "userList",
	UserText = "userText",
	UserDate = "userDate",
	UserTime = "userTime",
	UserSlider = "userSlider",
	PickProgram = "pickProgram",
	PickQuest = "pickQuest",
	AddToProgram = "addToProgram",
	NotifyPerm = "notifyPerm",
	Input = "input",
	Output = "output",
	Comment = "comment"
};

export enum MsgSaveAs {
	Default = "default",
	FeedbackGeneral = "feedback",
	FeedbackStory = "feedbackStory",
	Name = "saveName",
	DoB = "dob",
	DailyCheck = "dailyCheck",
	DailyCheckDetail = "dailyCheckDetail",
	DailyCheckText = "dailyCheckText",
	Goal = "goal",
	PhysicalSyms = "physical",
	PhysicalSymsOther = "otherPhysical",
	HeardOfFuncSyms = "heardOfFuncSyms",
	PhysicalSymsWorrying = "physicalWorrying",
	StoppedActivities = "stoppedActivities",
	ExerciseLevel = "exerciseLevel",
	LowMood = "lowMood",
	EnergyLevel = "energyLevel",
	SleepTrouble = "sleepTrouble",
	NotificationTime = "notifyTime"
};

export enum EdgeType {
	CondEdge = "conditionalEdge"
};

export enum CondTrait {
	None = "none",
	Checklist = "checklist",
	History = "history",
	Program = "programme",
	Goal = "goal",
	PhysicalSyms = "physical",
	HeardOfFuncSyms = "heardOfFuncSyms",
	PhysicalSymsWorrying = "physicalWorrying",
	StoppedActivities = "stoppedActivities",
	ExerciseLevel = "exerciseLevel",
	LowMood = "lowMood",
	EnergyLevel = "energyLevel",
	SleepTrouble = "sleepTrouble",
	DevMode = "devMode"
};

export enum CondOp {
	True = "true",
	False = "false",
	Empty = "empty",
	Equals = "equals",
	NotEquals = "notequals",
	Includes = "includes",
	NotIncludes = "notincludes",
	None = "none"
};


/*************** General types ***************/
export type TreeNode = {
	id: number | string;
	parent: number | string;
	droppable?: boolean;
	text: string;
};



/**************** Story types ****************/
////// Stories
export type Story = {
	id: string;
	name: string;
	title?: string | null;
	type: StoryType;
	category: StoryCat;
	subcategory?: StorySubCat | null;
	priority?: number | null; 
	live: boolean;
	desc?: string | null;
	summary?: string | null;
	duration?: string | null;
	quests?: string[] | null;
	flows: Record<string, string>;
	banner?: string | null;
	bannerColour?: string | null;
	notes?: Record<string, StoryNote> | null;
	[key: string]: string | string[] | boolean | {} | null | undefined;
};

export type StoryDownload = Omit<Story, "live" | "flows" | "notes" > & {
	live?: boolean;
	flow?: Record<string, StoryFlowMsg>;
	flows?: Record<string, string>;
};

export type StoryNote = {
	author: string;
	content: string;
	date: number;
};

////// Story flows
export type StoryFlowMsg = {
	type: MsgType;
	content: string[];
	next?: MsgNext[];
	nextLeft?: MsgNext[];
	nextRight?: MsgNext[];
	position: XYPosition;
	meta?: {
		type?: MediaType;
	} | null;
	effect?: MsgEffect | null;
};

export type MsgNext = {
	id: string;
	condition?: MsgCondition | null;
};

export type MsgCondition = {
	trait: CondTrait;
	op: CondOp;
	value: string;
};

export type MsgEffect = {
	saveAs?: MsgSaveAs;
	payload?: string[];
	hybrid?: boolean;
	listOther?: boolean;
	listUnsure?: boolean;
	listNone?: boolean;
	autoplay?: boolean;
	loop?: boolean;
};

////// Story editor
export type NodeData = {
	nodeID: string;
	flowID: string;
	label?: string;
};

export type EdgeData = {
	nextIndex: number;
	condition?: MsgCondition;
};

export type FlowSet = {
	nodes: Node[];
	edges: Edge[];
};



/***************** Media types ****************/
export type MediaFolder = {
	id: string;
	name: string;
	type: MediaType;
	parent: string;
};

export type Media = {
	id: string;
	name: string;
	created: number;
	type: MediaType;
	folder: string;
	alt?: string | null;
	duration?: number | null;
};

export type MediaDownload = {
	id?: string;
	name: string;
	created?: number;
	type?: MediaType;
	folder?: string;
	alt?: string;
	duration?: number;
};



/***************** Program types ****************/
export type Program = {
	id: string;
	name: string;
	live: boolean;
	nodes: ProgramNode[];
	description: string;
	banner: string;
	bannerColour?: string | null;
};

export type ProgramNode = {
	nodeID: string;
	storyID: string;
};



/**************** Quest types ***************/
export type DEPRECATED_Questline = {
	id: string;
	name: string;
	category: StoryCat;
	quests: DEPRECATED_Quest[];
};

export type DEPRECATED_Quest = {
	id: string;
	title: string;
	icon: string;
	description: string;
	banner: string;
};

export enum QuestCat {
	None = "none",
	Relax = "relax",
	BeActive = "beActive",
	ManageLife = "manageLife"
};

export type Quest = {
	id: string;
	title: string;
	category: QuestCat;
	live: boolean;
	icon: string;
	description: string;
	banner: string;
};

export type QuestDownload = {
	id: string;
	title: string;
	category: QuestCat;
	live?: boolean;
	icon: string;
	description: string;
	banner: string;
};



/**************** Variable types ***************/
export enum VariableType {
	SingleSelect = "singleSelect",
	MultiSelect = "multiSelect",
	FreeText = "freeText",
	Date = "date",
	Time = "time"
};

export type Variable = {
	id: string;
	name: string;
	prettyName: string;
	type: VariableType;
};

export type VariableValue = {
	id: string;
	name: string;
	prettyName: string;
};



/***************** Tip types ****************/
export type Tip = {
	id: string;
	content: string;
	header?: string | null;
	day?: string | null;
	program?: string | null;
};



/**************** User types ****************/
export type User = {
	id: string;
	convoID: string;
	created: number;
	passphrase: string;
	platform: string;
	type: string;
};

export type ConvoMessage = {
	id: string;
	sender: string;
	date: number;
	content: string;
	buttons?: ConvoButton[];
};

export type ConvoButton = {
	id: string;
	type: string;
	content: string;
	meta?: any;
};



/*************** Feedback types **************/
export type FeedbackItem = {
	type: "bug" | "feat" | "story" | "program" | "general";
	message: string;
	done?: boolean;
	storyID?: string;
	programID?: string;
	deviceInfo?: {
		OS: string;
		OSVersion: string;
		OSBuildId: string;
		APILevel: number;
		brand: string;
		designName: string;
		deviceYearClass: string;
		modelName: string;
	};
	userInfo?: {
		userID: string;
		version: string;
		userAge: number;
	};
};
