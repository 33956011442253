import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import firebase from "../../../firebase";
import { getAuth } from "firebase/auth";
import { getDatabase, push, ref } from "firebase/database";
import { useAuthState } from "react-firebase-hooks/auth";
import { Story, StoryNote } from "../../../types";
import Button from "../../../utils/Button";
import { Tab } from "@headlessui/react";
import { PlusLg } from "react-bootstrap-icons";


const auth = getAuth(firebase);
const db = getDatabase(firebase);

type StorySettingsNotesProps = {
	story: Story;
};

const StorySettingsNotes = ({ story }: StorySettingsNotesProps) => {
	const [user, userLoading, userError] = useAuthState(auth);
	useEffect(() => {
		if (userError) console.error(userError);
	}, [userError]);

	const [draftNote, setDraftNote] = useState("");

	const handleNoteFormUpdate = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setDraftNote(event.target.value);
	};

	const handleNewNote = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		createNote();
	};

	const createNote = () => {
		if (!draftNote) return;

		const note: StoryNote = {
			author: userLoading || userError || !user?.displayName ? "Unknown author" : user.displayName,
			content: draftNote,
			date: Date.now()
		};
		push(ref(db, 'stories/' + story.id + '/notes'), note);

		setDraftNote("");
	};

	return (
		<Tab.Panel>
			<h3 className="mt-4">Notes</h3>
			<form
				onSubmit={handleNewNote}
				className="relative flex-grow mr-2"
			>
				<textarea
					value={draftNote}
					onChange={handleNoteFormUpdate}
					placeholder="Add a note"
					className="w-full min-h-12 pl-4 pr-10 py-4 bg-olive-50 hover:bg-olive-100 focus:bg-olive-100 text-olive-900 rounded-md placeholder:text-olive-600 transition"
				/>
				<Button submit
					className="h-12"
				>
					<PlusLg /> Add note
				</Button>
			</form>

			<div className="mt-8">
				{ Object.values(story.notes ?? []).reverse().map((note, index) => (
					<div key={index} className="mt-4">
						<p className="text-brown-600">{note.author} at {new Date(note.date).toLocaleString("en-gb")}</p>
						<p>{note.content.replaceAll("\\n", "\n")}</p>
					</div>
				))}
			</div>
		</Tab.Panel>
	);
};

export default StorySettingsNotes;
