import { ChangeEvent, forwardRef, KeyboardEvent, useRef } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import sanitizeHtml, { IOptions } from 'sanitize-html';


const sanitizeOptions: IOptions = {
    allowedTags: ["a"],
    allowedAttributes: { a: ["href"] }
};

type EditTextProps = {
    name: string;
    init: string;
    spellCheck?: boolean;
    readOnly?: boolean;
    restrictHeight?: boolean;
    className?: string;
    fn: (name: string, value: string, baggage?: any) => void;
    baggage?: any;
}

const EditText = forwardRef<HTMLElement, EditTextProps>(({name, init, spellCheck, readOnly, restrictHeight, className, fn, baggage}, ref) => {
    const value = useRef(init);


    // Handle editing the component
    const handleChange = (event: ContentEditableEvent) => {
        value.current = event.target.value;
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        // Lose focus when Enter is pressed
        if(event.key === 'Enter') (event.target as HTMLDivElement).blur();
    };

    const handleSubmit = (event: ChangeEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.target.scrollLeft = 0;
        const sanitizedText = sanitizeHtml(value.current, sanitizeOptions);
        fn(name, sanitizedText, baggage);
    };


    return (
        <ContentEditable
            innerRef={ref!}
            html={value.current}
            className={`block w-full resize-none border-0 overflow-auto
            bg-transparent p-1 rounded text-left cursor-text
            focus:bg-[#00000011] focus:outline-none
            ${!readOnly && "hover:bg-[#00000011]"}
            ${restrictHeight && "whitespace-nowrap overflow-hidden text-ellipsis focus:text-clip"}
            editText ${className}`}
            disabled={readOnly}
            spellCheck={spellCheck}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleSubmit}
        />
    );
});

export default EditText;
