import Tooltip from "../../../utils/Tooltip";
import FillIcon from "../../../utils/FillIcon";
import { Icon } from "react-bootstrap-icons";


type HeaderButtonProps = {
	title: string,
	active?: boolean,
	tipTarget?: any,
	Line: Icon,
	Fill: Icon,
	onClick: () => void,
};

const HeaderButton = ({ title, active, tipTarget, Line, Fill, onClick }: HeaderButtonProps) => {
	return (
		<Tooltip content={title} singleton={tipTarget}>
			<button
				className={`group-icon hidden group-hover:block ml-2 bg-none border-0 rounded-lg active:opacity-70`}
				onClick={onClick}
			>
				<FillIcon Line={Line} Fill={Fill} iconStyle="w-4 h-4" alwaysShowFill={active} />
			</button>
		</Tooltip>
	);
};

export default HeaderButton;
