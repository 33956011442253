type LoadingProps = {
    customText?: string,
    className?: string
}

const Loading = ({ customText, className }: LoadingProps) => {
    return (
        <div className={className}>
            <div className="inline-block relative h-5 w-5 mr-5">
                <div className="absolute w-5 h-5 m-1 border-2 border-transparent border-t-green-400 rounded-full animate-spin1" />
                <div className="absolute w-5 h-5 m-1 border-2 border-transparent border-t-green-400 rounded-full animate-spin2" />
                <div className="absolute w-5 h-5 m-1 border-2 border-transparent border-t-green-400 rounded-full animate-spin3" />
            </div>
            { customText ?? "Loading..."}
        </div>
    );
}

export default Loading;