import { useEffect, useState } from "react";
import { FeedbackItem } from "../types";
import { decodeFirebaseID } from "../helpers";
import firebase from "../firebase";
import { get, getDatabase, ref, remove, set } from "firebase/database";
import { Bug, CheckCircle, Compass, Diagram3, Lightbulb, Tags, TrashFill, XCircle } from "react-bootstrap-icons";
import Button from "../utils/Button";
import Popover from "../utils/Popover";


const db = getDatabase(firebase);

type FeedbackCardProps = FeedbackItem & {
    id: string;
};

const FeedbackCard = ({ id, type, message, done, storyID, programID, deviceInfo, userInfo }: FeedbackCardProps) => {
    const [open, setOpen] = useState(false);
    
    const [storyName, setStoryName] = useState("");
    const [programName, setProgramName] = useState("");

    useEffect(() => {
        if(!storyID) return;

        const getStoryName = async () => {
            const name: string = await get(ref(db, "stories/" + storyID)).then(val => val.val()?.name) ?? "[Deleted story]";
            setStoryName(name);
        };

        getStoryName();
    }, [storyID]);

    useEffect(() => {
        if(!programID) return;

        const getProgramName = async () => {
            const name: string = await get(ref(db, "programs/" + programID)).then(val => val.val()?.name) ?? "[Deleted programme]";
            setProgramName(name);
        };

        getProgramName();
    }, [programID]);


	const markAsDone = () => {
		set(ref(db, "feedback/" + id + "/done"), true);
	};
	
	const unmarkAsDone = () => {
		set(ref(db, "feedback/" + id + "/done"), false);
	};
	
	const deleteFeedback = () => {
		remove(ref(db, "feedback/" + id));
	};


    return (
        <div
            className={`flex flex-row w-[800px] mb-4 p-4 border rounded text-left
                ${done ? `${style["done"].bg} ${style["done"].border}` : `${style[type].bg} ${style[type].border}`}
                ${open ? "select-text cursor-default" : "hover:-mt-[3px] hover:border-b-4 active:mt-0 active:border-b cursor-pointer"}
            `}
			tabIndex={0}
            onClick={!open ? () => { setOpen(true) } : undefined}
			onKeyDown={!open ? (event) => { return event.key === "Enter" ? setOpen(true) : undefined } : undefined}
        >
            { type === "bug" ?
                <Bug className={`w-8 h-8 ${done ? "fill-gray-650" : "fill-rose-700"}`} />
            : type === "feat" ?
                <Lightbulb className={`w-8 h-8 ${done ? "fill-gray-650" : "fill-purple-750"}`} />
            : type === "story" ?
                <Diagram3 className={`w-8 h-8 ${done ? "fill-gray-650" : "fill-brown-650"}`} />
            : type === "program" ?
                <Compass className={`w-8 h-8 ${done ? "fill-gray-650" : "fill-teal-650"}`} />
            :
                <Tags className={`w-8 h-8 ${done ? "fill-gray-650" : "fill-stone-650"}`} />
            }

            <div className={`grow ml-2 pl-2`}>
				<div className={`flex justify-between ${done ? `${style["done"].text}` : `${style[type].text}`}`}>
					<b>
						{ type === "bug" ? "Problem" : type === "feat" ? "Suggestion" : type === "story" ? storyName : type === "program" ? programName : "General"}
					</b>
					<p>
						{ new Date(decodeFirebaseID(id)).toLocaleString("en-gb") }
					</p>
				</div>

                <p className="max-w-[600px] overflow-x-auto">
                    { message }
                </p>

                { open && <>
                    <div className="flex mt-4 gap-20">
                        <p>
                            Feedback item ID: { id }<br />
							{ type === "story" && <>Story ID: { storyID }<br /></>}
                            User ID: { userInfo?.userID }<br />
                            User age: { userInfo?.userAge ?? "N/A" }<br />
                        </p>
                        <p>
                            { deviceInfo?.OS } &middot; { deviceInfo?.OSVersion } { deviceInfo?.OS === "Android" && `(API ${deviceInfo?.APILevel})` } &middot; { deviceInfo?.OSBuildId }<br />
                            { deviceInfo?.brand } &middot; { deviceInfo?.modelName } { deviceInfo?.OS === "Android" && `(${deviceInfo?.designName})` } &middot; { deviceInfo?.deviceYearClass }<br />
							App version: { userInfo?.version }
                        </p>
                    </div>
                    <div className="flex mt-4">
						{ done ?
							<Button
								className=""
								onClick={unmarkAsDone}
							>
								<XCircle className="w-5 h-5 mr-1" /> Unmark as done
							</Button>
						:
							<Button
								className=""
								onClick={markAsDone}
							>
								<CheckCircle className="w-5 h-5 mr-1" /> Mark as done
							</Button>
						}
                        <Popover
							variant="danger"
							className="cursor-auto"
							content={ <>
								<p className="mb-4">
									Are you sure you want to delete this piece of feedback? This can't be reversed!
								</p>
								<Button
									variant="danger"
									onClick={deleteFeedback}
								>
									Delete feedback
								</Button>
							</> }
						>
							<Button
								variant="danger"
								className="ml-4 pl-3"
							>
								<TrashFill className="w-5 h-5 mr-1" /> Delete
							</Button>
						</Popover>
                        <Button
                            className={`ml-auto text-white ${done ? `${style["done"].bgDark} ${style["done"].shadow}` : `${style[type].bgDark} ${style[type].shadow}`}`}
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </Button>
                    </div>
                </> }
            </div>
        </div>
    );
};

const style = {
    bug: {
        bg: "bg-rose-50", bgDark: "bg-rose-700", border: "border-rose-700", shadow: "shadow-rose-800", text: "text-rose-700"
    },
    feat: {
        bg: "bg-purple-50", bgDark: "bg-purple-750", border: "border-purple-750", shadow: "shadow-purple-800", text: "text-purple-750"
    },
    story: {
        bg: "bg-brown-50", bgDark: "bg-brown-650", border: "border-brown-650", shadow: "shadow-brown-800", text: "text-brown-650"
    },
    program: {
        bg: "bg-teal-50", bgDark: "bg-teal-650", border: "border-teal-650", shadow: "shadow-teal-800", text: "text-teal-650"
    },
    general: {
        bg: "bg-stone-50", bgDark: "bg-stone-650", border: "border-stone-650", shadow: "shadow-stone-800", text: "text-stone-650"
    },
	done: {
		bg: "bg-gray-50", bgDark: "bg-gray-650", border: "border-gray-650", shadow: "shadow-gray-800", text: "text-gray-650"
	}
};

export default FeedbackCard;
