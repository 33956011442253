type MenuPlaceholderProps = {
	depth: number;
};

const MenuPlaceholder = ({ depth }: MenuPlaceholderProps) => {
	const left = depth * 24;
	return <div className="absolute h-[2px] top-0 right-0 bg-brown-500 -translate-y-1/2" style={{ left }} />;
};

export default MenuPlaceholder;
