import { Fragment, useEffect, useState } from 'react';
import Node from './Node';
import EffectContainer from './EffectContainer';
import HeaderButton from './HeaderButton';
import EditText from '../../../utils/EditText';
import Select from '../../../utils/Select';
import MediaPreview from '../../../utils/MediaPreview';
import Tooltip from '../../../utils/Tooltip';
import FillIcon from '../../../utils/FillIcon';
import { MediaLocation, MsgSaveAs, MsgType, NodeData, StoryFlowMsg } from '../../../types';
import firebase from '../../../firebase';
import { getDatabase, ref, set } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useSingleton } from '@tippyjs/react';
import { BagPlus, BagPlusFill, BagX, BagXFill, CheckCircleFill, Circle, CircleHalf, Diagram2, HSquare, HSquareFill, Icon, PlusCircle, PlusCircleFill, Save, SaveFill, Trash, TrashFill } from 'react-bootstrap-icons';


const db = getDatabase(firebase);

const TextInputNode = (type: MsgType, title: string, Icon: Icon, data: NodeData, disableMultiple?: boolean) => {
	const [tipSource, tipTarget] = useSingleton();


	// Get the node from the database
	const nodePath = "storyFlows/" + data.flowID + "/" + data.nodeID;
	const nodeRef = ref(db, nodePath);
	const [node, loading, error] = useObjectVal<StoryFlowMsg>(nodeRef);


	// Set up node state
	const [showSaveAs, setShowSaveAs] = useState(false);
	useEffect(() => {
		if(!node) return;
		setShowSaveAs(node.effect?.saveAs !== undefined);
	}, [node]);

	const [showPayload, setShowPayload] = useState(false);
	useEffect(() => {
		if(!node) return;
		setShowPayload(node.effect?.payload !== undefined);
	}, [node]);


	// Return intermediary node if loading or error
	// handleTop, handleBottom, and tipSource are still properly set to reduce console warnings and errors
	if(!node || loading || error) return (
		<Node
			title={loading ? "Loading..." : "Error"}
			Icon={Diagram2}
			handleTop={type !== MsgType.Comment}
			handleBottom={type === MsgType.Comment ? 0 : 1}
			tipSource={tipSource}
		>
			<p>
				{ loading ? "Loading..." : error ? error.toString() : "Error: node not found." }
			</p>
		</Node>
	);

	
	// Shorthands
	const hybrid = node.effect?.hybrid ?? false;
	const saveAs = node.effect?.saveAs ?? "none";
	const listOther = node.effect?.listOther ?? false;
	const listUnsure = node.effect?.listUnsure ?? false;
	const listNone = node.effect?.listNone ?? false;


	// Handle multiple fields
	const handleAddField = () => {
		const newContent = [...node.content];
		newContent.push("");
		set(ref(db, nodePath + "/content"), newContent);

		if (!node.effect?.payload) return;
		const newPayload = [...node.effect.payload];
		newPayload.push("");
		set(ref(db, nodePath + "/effect/payload"), newPayload);
	};

	const handleDeleteField = (index: number) => {
		if (node.content.length <= 1) return;

		const newContent = [...node.content];
		newContent.splice(index, 1);
		set(ref(db, nodePath + "/content"), newContent);

		if (!node.effect?.payload) return;
		const newPayload = [...node.effect.payload];
		newPayload.splice(index, 1);
		set(ref(db, nodePath + "/effect/payload"), newPayload);
	};

	const disablePayload = () => {
		set(ref(db, nodePath + "/effect/payload/"), null);
	};


	// Handle input
	const handleTextUpdate = (index: number, value: string) => {
		set(ref(db, nodePath + "/content/" + index), value.trim());
	};

	const handlePayloadUpdate = (index: number, value: string) => {
		set(ref(db, nodePath + "/effect/payload/" + index), value.trim());
	};

	const handleEffectSelectUpdate = (val: string) => {
		const selectRef = ref(db, nodePath + "/effect/saveAs/");

		if (val === "none") set(selectRef, null);
		else set(selectRef, val);
	};

	const handleHybridUpdate = () => {
		set(ref(db, nodePath + "/effect/hybrid"), !hybrid);
	};

	const handleListOtherUpdate = () => {
		set(ref(db, nodePath + "/effect/listOther"), !listOther);
	};

	const handleListUnsureUpdate = () => {
		set(ref(db, nodePath + "/effect/listUnsure"), !listUnsure);
	};

	const handleListNoneUpdate = () => {
		set(ref(db, nodePath + "/effect/listNone"), !listNone);
	};


	// Render the node
	return (
		<Node
			title={hybrid ? "Hybrid button" : title}
			handleTop={type !== MsgType.Comment}
			handleBottom={type === MsgType.Comment ? 0 : 1}
			tipSource={tipSource}
			headerButtons={<>
				{ (type === MsgType.UserButton || type === MsgType.UserList) && !showSaveAs &&
					<HeaderButton
						title="Save response as"
						Line={Save} Fill={SaveFill}
						tipTarget={tipTarget}
						onClick={() => setShowSaveAs(true)}
					/>
				}
				{ (type === MsgType.UserButton || type === MsgType.UserList) && showSaveAs &&
					<HeaderButton
						title={showPayload ? "Remove custom payload" : "Add custom payload"}
						Line={showPayload ? BagX : BagPlus} Fill={showPayload ? BagXFill : BagPlusFill}
						tipTarget={tipTarget}
						onClick={() => showPayload ? disablePayload() : setShowPayload(true)}
					/>
				}
				{ type === MsgType.UserButton &&
					<HeaderButton
						title="Hybrid button/text input"
						Line={hybrid ? HSquareFill : HSquare} Fill={HSquareFill}
						tipTarget={tipTarget}
						onClick={handleHybridUpdate}
					/>
				}
				{ !disableMultiple &&
					<HeaderButton
						title="Add option"
						Line={PlusCircle} Fill={PlusCircleFill}
						tipTarget={tipTarget}
						onClick={handleAddField}
					/>
				}
			</>}
			Icon={hybrid ? CircleHalf : Icon}
		>
			<div>
				{ showPayload &&
					<div className="flex flex-row mx-1">
						<p className="w-2/3 px-1 text-white text-[0.6rem]">
							Text displayed
						</p>
						<p className="w-1/3 px-1 text-white text-[0.6rem] ml-1">
							Data saved
						</p>
					</div>
				}
				{ node.content.map((c, index) => (
					<Fragment key={`${c}-${index}-${node.effect?.payload?.[index]}`}>
						{ type === MsgType.BotNetImg &&
							<div className="w-full px-1 mb-1">
								<MediaPreview
									location={MediaLocation.Internet}
									src={c}
									className="rounded"
								/>
							</div>
						}
						<div className="group-sec relative flex flex-row space-x-1 mb-1 mx-1 nodrag">
							<EditText
								name={type}
								init={c}
								spellCheck={type !== MsgType.BotNetImg}
								restrictHeight={type === MsgType.BotNetImg}
								className="px-2"
								fn={(_, value) => handleTextUpdate(index, value)}
							/>
							{ node.content.length > 1 &&
								<Tooltip content="Delete option">
									<button
										className={`group-icon absolute hidden h-full 
											group-sec-hover:block group-sec-focus-within:block
											top-0 right-0 px-[0.375rem] bg-[#FFFFFFAA] border-0 rounded
											hover:bg-red-500
										`}
										onClick={() => { handleDeleteField(index) }}
									>
										<FillIcon Line={Trash} Fill={TrashFill}
											iconStyle="w-4 h-4"
											fillStyle="fill-white"
										/>
									</button>
								</Tooltip>
							}
							{ showPayload &&
								<EditText
									name="payload"
									init={node.effect?.payload?.[index] ?? ""}
									className="!w-[47%] px-2"
									fn={(_, value) => handlePayloadUpdate(index, value)}
								/>
							}
						</div>
					</Fragment>
				)) }

				{ type === MsgType.UserList && showPayload &&
					<hr className="mx-1.5 mb-1 border-white/50" />
				}

				{ type === MsgType.UserList &&
					<div className="flex flex-row mx-1 mb-1 space-between space-x-1">
						<button
							className="check group-icon flex flex-row w-1/3 px-2 py-1 rounded"
							onClick={handleListOtherUpdate}
						>
							<FillIcon Line={Circle} Fill={CheckCircleFill}
								groupStyle="mr-2 overflow-visible"
								iconStyle="w-4 h-4"
								alwaysShowFill={listOther}
							/>
							<p>Other</p>
						</button>
						<button
							className="check group-icon flex flex-row w-1/3 px-2 py-1 rounded"
							onClick={handleListUnsureUpdate}
						>
							<FillIcon Line={Circle} Fill={CheckCircleFill}
								groupStyle="mr-2 overflow-visible"
								iconStyle="w-4 h-4"
								alwaysShowFill={listUnsure}
							/>
							<p>Unsure</p>
						</button>
						<button
							className="check group-icon flex flex-row w-1/3 px-2 py-1 rounded"
							onClick={handleListNoneUpdate}
						>
							<FillIcon Line={Circle} Fill={CheckCircleFill}
								groupStyle="mr-2"
								iconStyle="w-4 h-4"
								alwaysShowFill={listNone}
							/>
							<p>None</p>
						</button>
					</div>
				}

				{ (type === MsgType.UserButton || type === MsgType.UserList) && showSaveAs &&
					<EffectContainer
						title="Save as"
						Icon={Save}
					>
						<div className="relative w-full">
							<Select
								value={saveAs}
								options={
									type === MsgType.UserButton && hybrid ? [
										[MsgSaveAs.Default, "N/A"],
										[MsgSaveAs.FeedbackGeneral, "Feedback - general"],
										[MsgSaveAs.FeedbackStory, "Feedback - story"],
										[MsgSaveAs.PhysicalSymsOther, "Other physical symptoms"],
										[MsgSaveAs.StoppedActivities, "Stopped activities"]
									] : type === MsgType.UserButton ? [
										[MsgSaveAs.Default, "N/A"],
										[MsgSaveAs.FeedbackGeneral, "Feedback - general"],
										[MsgSaveAs.FeedbackStory, "Feedback - story"],
										[MsgSaveAs.DailyCheck, "Daily check-in"],
										[MsgSaveAs.DailyCheckDetail, "Daily check-in detail"],
										[MsgSaveAs.Goal, "Goal"],
										[MsgSaveAs.HeardOfFuncSyms, "Heard of func. syms."],
										[MsgSaveAs.PhysicalSymsWorrying, "Phys. sym. worrying"],
										[MsgSaveAs.StoppedActivities, "Stopped activities"],
										[MsgSaveAs.ExerciseLevel, "Exercise levels"],
										[MsgSaveAs.LowMood, "Low mood"],
										[MsgSaveAs.EnergyLevel, "Energy levels"],
										[MsgSaveAs.SleepTrouble, "Sleeping trouble"],
										[MsgSaveAs.NotificationTime, "Notification time"]
									] : [ // type === MsgType.UserList
										[MsgSaveAs.Default, "N/A"],
										[MsgSaveAs.PhysicalSyms, "Physical symptoms"]
									]
								}
								size="sm"
								onChange={handleEffectSelectUpdate}
							/>
						</div>
					</EffectContainer>
				}
			</div>
		</Node>
	);
};

export default TextInputNode;
