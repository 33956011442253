import Button from "../../utils/Button";
import firebase from '../../firebase';
import { get, getDatabase, ref } from 'firebase/database';
import { MediaDownload, Program, Quest, QuestDownload, Story, StoryDownload } from "../../types";
import { Tab } from "@headlessui/react";
import { Clipboard2CheckFill, CollectionPlayFill, CompassFill, Diagram3Fill } from "react-bootstrap-icons";


const db = getDatabase(firebase);

const downloadFile = (uriComponent: string | number | boolean, fileName: string) => {
	const data = "data:text/json;charset=utf-8," + encodeURIComponent(uriComponent);
	const downloadAnchor = document.createElement("a");
	downloadAnchor.setAttribute("href", data);
	downloadAnchor.setAttribute("download", fileName + ".json");
	document.body.appendChild(downloadAnchor);
	downloadAnchor.click();
	downloadAnchor.remove();
};

const Prep = () => {
	const downloadStories = async () => {
		const allStories: Record<string, Story> = (await get(ref(db, 'stories/'))).val();

		const liveStories = Object.keys(allStories)
			.filter(id => allStories[id].live)
			.reduce((res: Record<string, StoryDownload>, key) => {
				res[key] = allStories[key];
				return res;
			}, {});

		for (const key in liveStories) {
			const prodFlowArr = Object.entries(liveStories[key].flows!).find(([_, name]) => name === "Production");
			
			if(!prodFlowArr) {
				delete liveStories[key];
			}
			else {
				const prodFlow = (await get(ref(db, "storyFlows/" + prodFlowArr[0]))).val();
				liveStories[key].flow = prodFlow;

				delete liveStories[key].live;
				delete liveStories[key].flows;
				delete liveStories[key].notes;
			}
		}

		downloadFile(JSON.stringify(liveStories), "stories");
	};

	const downloadMediaData = async () => {
		const media: Record<string, MediaDownload> = (await get(ref(db, 'media/'))).val();

		for (const key in media) {
			delete media[key].created;
			delete media[key].folder;
			delete media[key].id;
			delete media[key].type;
		}

		downloadFile(JSON.stringify(media), "mediaData");
	};

	const downloadPrograms = async () => {
		const programs: Record<string, Program> = (await get(ref(db, 'programs/'))).val();
		downloadFile(JSON.stringify(programs), "programmes");
	};

	const downloadQuests = async () => {
		const allQuests: Record<string, Quest> = (await get(ref(db, 'quests/'))).val();

		const liveQuests = Object.keys(allQuests)
			.filter(id => allQuests[id].live)
			.reduce((res: Record<string, QuestDownload>, key) => {
				res[key] = allQuests[key];
				return res;
			}, {});

		for (const key in liveQuests) {
			delete liveQuests[key].live;
		}
	
		downloadFile(JSON.stringify(liveQuests), "quests");
	};

	return (
		<Tab.Panel>
			<h2 className="text-2xl font-bold mt-3 mb-3">Prepare production</h2>

			<div className="space-y-2">
				<Button
					variant="dark"
					onClick={downloadStories}
				>
					<Diagram3Fill className="mr-2" /> Download stories
				</Button>
				<br />
				<Button
					variant="dark"
					onClick={downloadMediaData}
				>
					<CollectionPlayFill className="mr-2" /> Download media metadata
				</Button>
				<br />
				<Button
					variant="dark"
					onClick={downloadPrograms}
				>
					<CompassFill className="mr-2" /> Download programmes
				</Button>
				<br />
				<Button
					variant="dark"
					onClick={downloadQuests}
				>
					<Clipboard2CheckFill className="mr-2" /> Download quests
				</Button>
			</div>
		</Tab.Panel>
	);
};

export default Prep;
