import { useState } from "react";
import TabSpace from "../../utils/TabSpace";
import TabButtonL from "../../utils/TabButtonL";
import { Icon, RocketTakeoff } from "react-bootstrap-icons";
import Prep from "./Prep";


const Config = () => {
	const [selectedIndex, setSelectedIndex] = useState(0);

	const handleTabSelect = (index: number) => {
		setSelectedIndex(index);
	};

	const navTitles: [string, Icon][] = [
		["Prepare production", RocketTakeoff]
	];
	const navItems = navTitles.map(([title, icon], index) => {
		return (
			<TabButtonL
				key={`config-nav-${index}`}
				title={title}
				Icon={icon}
			/>
		);
	});

	const tabItems = [
		<Prep key="Prep" />
	];

	return (
		<TabSpace
			name="configuration"
			navItems={navItems}
			tabItems={tabItems}
			selectedIndex={selectedIndex}
			handleSelect={handleTabSelect}
		/>
	);
};

export default Config;
