import { useState, useRef, useEffect, useCallback, MouseEvent } from 'react';
import 'reactflow/dist/base.css';
import './StoryFlow.scss';
import ConditionalEdge from './ConditionalEdge';
import EdgeSettings from './EdgeSettings';
import { BotNetImgNode, BotTextNode, CommentNode, UserButtonNode, UserDateNode, UserListNode, UserTextNode, UserTimeNode } from './nodes/customNodes';
import AddToProgramNode from './nodes/AddToProgramNode.tsx';
import BotMediaNode from './nodes/BotMediaNode';
import PickProgramNode from './nodes/PickProgramNode';
import PickQuestNode from './nodes/PickQuestNode';
import NotifyPermNode from './nodes/NotifyPermNode';
import UserSliderNode from './nodes/UserSliderNode';
import { FlowSet, MsgNext, MsgType, Story, StoryFlowMsg } from '../../types';
import { randomString } from '../../helpers';
import { getSelectedFlow, storyFlowSavedToEditor } from './flowUtils';
import Button from '../../utils/Button';
import FillIcon from '../../utils/FillIcon';
import Loading from '../../utils/Loading';
import Modal from '../../utils/Modal';
import Tooltip from '../../utils/Tooltip';
import firebase from '../../firebase';
import { get, getDatabase, ref, remove, set } from 'firebase/database';
import { useObject } from 'react-firebase-hooks/database';
import ReactFlow, { Background, Connection, Edge, getNodesBounds, MiniMap, Node, OnSelectionChangeParams, updateEdge, useEdgesState, useNodesState, useReactFlow, useStore, XYPosition } from 'reactflow';
import { Dialog } from '@headlessui/react';
import { useSingleton } from '@tippyjs/react';
import { Bell, BellFill, BoxArrowInRight, BoxArrowRight, Bullseye, CalendarEvent, CalendarEventFill, ChevronDown, ChevronUp, Clipboard, Clock, ClockFill, CollectionPlay, CollectionPlayFill, Compass, CompassFill, Cursor, CursorFill, DashLg, Files, HandIndexThumb, HandIndexThumbFill, Image, ImageFill, InputCursorText, LockFill, NodePlus, NodePlusFill, PlusLg, QuestionLg, Scissors, Sticky, StickyFill, TextareaT, Trash, TrashFill, Trophy, TrophyFill, UiChecks, Unlock } from 'react-bootstrap-icons';


const db = getDatabase(firebase);


const nodeTypes = {
	botText: BotTextNode,
	botIntMedia: BotMediaNode,
	botMedia: BotNetImgNode,
	userButton: UserButtonNode,
	userList: UserListNode,
	userText: UserTextNode,
	userDate: UserDateNode,
	userTime: UserTimeNode,
	userSlider: UserSliderNode,
	pickProgram: PickProgramNode,
	pickQuest: PickQuestNode,
	addToProgram: AddToProgramNode,
	notifyPerm: NotifyPermNode,
	comment: CommentNode
};

const edgeTypes = {
	conditionalEdge: ConditionalEdge
};

const deleteKeyCode = ["Backspace", "Delete"];


type EdgeSettingsData = {
	id: string;
	source: string;
	target: string;
	nextIndex: number;
	clickPos: {
		x: number;
		y: number;
	};
};

type StoryFlowProps = {
	flowID: string;
	story: Story;
	clipboard: string;
	setClipboard: React.Dispatch<React.SetStateAction<string>>;
};

const StoryFlow = ({ flowID, story, clipboard, setClipboard }: StoryFlowProps) => {
	// Canvas state
	const [tool, setTool] = useState("select");
	const [prevMoveTool, setPrevMoveTool] = useState("select");
	const [toolMenuExpanded, setToolMenuExpanded] = useState(false);
	const [interactive, setInteractive] = useState(true);
	const [showHelpMenu, setShowHelpMenu] = useState(false);
	const [sidebarTipSource, sidebarTipTarget] = useSingleton();


	// Set up flow state
	const flowName = story.flows[flowID];
	const flowPath = "storyFlows/" + flowID;
	const flowRef = ref(db, flowPath);
	const [flowSnapshot, flowLoading, flowError] = useObject(flowRef);

	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);

	const reactFlowWrapper = useRef<HTMLDivElement>(null);
	const reactFlowInstance = useReactFlow();

	const [selectedElements, setSelectedElements] = useState<OnSelectionChangeParams>();

	const [contextClickPosition, setContextClickPosition] = useState<XYPosition>({ x: 0, y: 0 });
	const [showPaneContext, setShowPaneContext] = useState(false);
	const [showSelectionContext, setShowSelectionContext] = useState(false);

	const [showEdgeSettings, setShowEdgeSettings] = useState(false);
	const [edgeSettingsData, setEdgeSettingsData] = useState<EdgeSettingsData>();


	// Get some values and methods from the internal flow state to help with selection
	const unselectNodesAndEdges = useStore(store => store.unselectNodesAndEdges);
	const addSelectedEdges = useStore(store => store.addSelectedEdges);


	// Get the story flow
	useEffect(() => {
		if (!flowSnapshot) return;
		// If flowSnapshot !== undefined but also !exists(), we are looking for a story that doesn't have any nodes (yet)
		// In this case, we explicitly save [] as we can't pass an empty dataSnapshot val to storyFlowSavedToEditor
		const newElements = flowSnapshot.exists() ? storyFlowSavedToEditor(flowSnapshot.val(), flowSnapshot.key!) : { nodes: [], edges: [] };
		setNodes(newElements.nodes);
		setEdges(newElements.edges);
	}, [flowSnapshot, setNodes, setEdges]);

	useEffect(() => {
		if (flowError) console.error(flowError);
	}, [flowError]);


	// Set the active tool
	const pickTool = (newTool: string) => {
		setTool(newTool);
		if (newTool === "select" || newTool === "pan") setPrevMoveTool(newTool);
	};


	// Helper methods for getting the position of the mouse
	// Either relative to the screen (for displaying the context menu)...
	const getClickPosition = useCallback((event: MouseEvent) => {
		event.preventDefault();

		const reactFlowBounds = reactFlowWrapper.current!.getBoundingClientRect();
		return {
			x: event.clientX - reactFlowBounds.left,
			y: event.clientY - reactFlowBounds.top
		};
	}, []);

	// ...or relative to the flow instance itself (for adding nodes)
	const getFlowPosition = useCallback((event: MouseEvent) => {
		return reactFlowInstance!.screenToFlowPosition({
			x: event.clientX,
			y: event.clientY
		});
	}, [reactFlowInstance]);


	// Moving nodes around
	const onNodeDragStop = useCallback((_: MouseEvent, node: Node) => {
		set(ref(db, flowPath + "/" + node.id + "/position"), node.position);
	}, [flowPath]);

	const onSelectionDragStop = useCallback((_: MouseEvent, nodes: Node[]) => {
		for (const node of nodes) set(ref(db, flowPath + "/" + node.id + "/position"), node.position);
	}, [flowPath]);


	// Deleting elements
	const onNodesDelete = useCallback((nodes: Node[]) => {
		for (const node of nodes) remove(ref(db, flowPath + "/" + node.id));
	}, [flowPath]);

	const onEdgesDelete = useCallback(async (edges: Edge[]) => {
		for (const edge of edges) {
			const source = await get(ref(db, flowPath + "/" + edge.source));
			const next: MsgNext[] = source.val().next;
			const index = next.findIndex(n => n.id === edge.target);
			next.splice(index, 1);
			set(ref(db, flowPath + "/" + edge.source + "/next"), next);
		}
	}, [flowPath]);

	const removeSelectedElements = useCallback(async () => {
		if (!selectedElements) return;

		await onEdgesDelete(selectedElements.edges);
		onNodesDelete(selectedElements.nodes);
	}, [selectedElements, onNodesDelete, onEdgesDelete]);

	const removeEdge = useCallback((edgeID: string) => {
		setShowEdgeSettings(false);
		onEdgesDelete([edges.find(edge => edge.id === edgeID)!]);
	}, [edges, onEdgesDelete]);


	// Connecting nodes and updating edges
	const onConnect = useCallback(async (connection: Connection) => {
		if(!connection.source || !connection.target) return;
		const source: StoryFlowMsg = (await get(ref(db, flowPath + "/" + connection.source))).val();

		if (source.type === MsgType.NotifyPerm) {
			if(connection.sourceHandle === "left") {
				const nextLeft = source.nextLeft ? [...source.nextLeft] : [];
				nextLeft.push({ id: connection.target })
				set(ref(db, flowPath + "/" + connection.source + "/nextLeft"), nextLeft);
			}
			else {
				const nextRight = source.nextRight ? [...source.nextRight] : [];
				nextRight.push({ id: connection.target })
				set(ref(db, flowPath + "/" + connection.source + "/nextRight"), nextRight);
			}
		}
		else {
			const next = source.next ? [...source.next] : [];
			next.push({ id: connection.target })
			set(ref(db, flowPath + "/" + connection.source + "/next"), next);
		}
	}, [flowPath]);

	const onEdgeUpdate = useCallback(async (oldEdge: Edge, newConnection: Connection) => {
		setEdges(edges => updateEdge(oldEdge, newConnection, edges));

		const oldSource: StoryFlowMsg = (await get(ref(db, flowPath + "/" + oldEdge.source))).val();
		const oldNext: MsgNext[] = oldSource.next!;
		const oldNextIndex = oldNext.findIndex(n => n.id === oldEdge.target);

		// If we're moving targets
		if (oldEdge.source === newConnection.source) {
			if (!newConnection.target) return;

			oldNext[oldNextIndex].id = newConnection.target;
			set(ref(db, flowPath + "/" + oldEdge.source + "/next"), oldNext);
		}
		// If we're moving sources
		else {
			if (!newConnection.target) return;

			const newSource = await get(ref(db, flowPath + "/" + newConnection.source));
			let newNext: MsgNext[] = newSource.val().next;
			const newNextItem: MsgNext = { id: newConnection.target, condition: oldNext[oldNextIndex].condition ?? null };

			oldNext.splice(oldNextIndex, 1);
			if (newNext) {
				newNext.push(newNextItem);
			} else {
				newNext = [newNextItem];
			}

			set(ref(db, flowPath + "/" + oldEdge.source + "/next"), oldNext);
			set(ref(db, flowPath + "/" + newConnection.source + "/next"), newNext);
		}
	}, [flowPath, setEdges]);


	// Creating a new node
	const createNode = useCallback((event: MouseEvent, type: MsgType) => {
		if (!reactFlowWrapper.current) return;

		// Don't create more than 1 input node
		if (type === MsgType.Input) {
			if(nodes.find(el => el.id === "start")) return;
		}

		const nodeID = type === MsgType.Input ? "start" : randomString();
		const position = getFlowPosition(event);

		const initialContent = 
			type === MsgType.UserText || type === MsgType.UserDate || type === MsgType.UserTime || type === MsgType.Output ? ["default"] :
			type === MsgType.PickQuest ? ["", "", ""] :
			[""];

		const newNode: StoryFlowMsg = {
			type,
			content: initialContent,
			position
		};

		set(ref(db, flowPath + "/" + nodeID), newNode);
	}, [nodes, flowPath, getFlowPosition]);


	// Canvas interaction
	const onPaneClick = useCallback((event: MouseEvent) => {
		if (tool === "select" || tool === "pan") return;
		if (!reactFlowInstance) return;
		if (flowName !== "Production") createNode(event, tool as MsgType);
		setTool(prevMoveTool);
	}, [flowName, tool, prevMoveTool, reactFlowInstance, createNode, setTool]);

	const onEdgeClick = useCallback((event: MouseEvent, edge: Edge) => {
		unselectNodesAndEdges();
		if (!edge.data) return;
		addSelectedEdges([edge.id]);
		setEdgeSettingsData({
			id: edge.id,
			source: edge.source,
			target: edge.target,
			nextIndex: edge.data.nextIndex,
			clickPos: getClickPosition(event)
		});
		setShowEdgeSettings(true);
	}, [unselectNodesAndEdges, addSelectedEdges, getClickPosition]);


	// Handle context menu
	const onSelectionChange = useCallback((selection: OnSelectionChangeParams) => {
		setSelectedElements(selection);
	}, []);

	const handleContextClick = useCallback((event: MouseEvent) => {
		if (!reactFlowWrapper.current) return;
		const position = getClickPosition(event);
		setContextClickPosition(position);
	}, [getClickPosition]);

	const onPaneContextMenu = useCallback((event: MouseEvent) => {
		handleContextClick(event);
		setShowPaneContext(true);
	}, [handleContextClick]);

	const onSelectionContextMenu = useCallback((event: MouseEvent) => {
		handleContextClick(event);
		setShowSelectionContext(true);
	}, [handleContextClick]);

	const handleContextClose = useCallback(() => {
		setShowPaneContext(false);
		setShowSelectionContext(false);
	}, []);


	// Cut, copy, and paste
	const clipSelection = useCallback((event: ClipboardEvent | MouseEvent, cut?: boolean) => {
		if (!(event.target as Element)?.closest(".react-flow")) return;
		if (!selectedElements || !selectedElements.nodes.length) return;

		event.preventDefault();

		const data = JSON.stringify([
			getSelectedFlow(selectedElements.nodes, nodes, edges),
			getNodesBounds(selectedElements.nodes),
			flowPath
		]);
		setClipboard(data);

		if (cut) removeSelectedElements();

		setShowPaneContext(false);
	}, [nodes, edges, selectedElements, flowPath, setClipboard, removeSelectedElements]);

	const pasteSelection = useCallback(async (event: MouseEvent) => {
		if (!(event.target as Element)?.closest(".react-flow")) return;
		if (!reactFlowWrapper.current) return;
		if (!clipboard) return;

		event.preventDefault();

		const parsedData = JSON.parse(clipboard);
		const elementsToAdd = parsedData[0] as FlowSet;
		const nodesBounds = parsedData[1] as XYPosition;
		const originalFlowPath = parsedData[2] as string;

		const position = getFlowPosition(event);
		const now = Date.now();

		elementsToAdd.nodes.forEach(async (node: Node) => {
			const original: StoryFlowMsg = (await get(ref(db, originalFlowPath + "/" + node.id))).val();
			const nodeID = `${node.id}_${now}`;

			const newNode = {...original};

			newNode.position = {
				x: position.x + (node.position.x - nodesBounds.x),
				y: position.y + (node.position.y - nodesBounds.y)
			};

			if(original.type === MsgType.NotifyPerm) {
				newNode.nextLeft = elementsToAdd.edges.filter(oldEdge => oldEdge.source === node.id && oldEdge.sourceHandle === "left").map(oldEdge => {
					return {
						id: `${oldEdge.target}_${now}`,
						condition: original.nextLeft?.find(edge => edge.id === oldEdge.target)?.condition ?? null
					};
				});
				newNode.nextRight = elementsToAdd.edges.filter(oldEdge => oldEdge.source === node.id && oldEdge.sourceHandle === "right").map(oldEdge => {
					return {
						id: `${oldEdge.target}_${now}`,
						condition: original.nextRight?.find(edge => edge.id === oldEdge.target)?.condition ?? null
					};
				});
			}
			else {
				newNode.next = elementsToAdd.edges.filter(oldEdge => oldEdge.source === node.id).map(oldEdge => {
					return {
						id: `${oldEdge.target}_${now}`,
						condition: original.next?.find(edge => edge.id === oldEdge.target)?.condition ?? null
					};
				});
			}

			set(ref(db, flowPath + "/" + nodeID), newNode);
		});

		setShowPaneContext(false);
	}, [flowPath, clipboard, getFlowPosition]);


	useEffect(() => {
		document.addEventListener("click", handleContextClose);
		return () => document.removeEventListener("click", handleContextClose);
	}, [handleContextClose]);


	return (
		<div className="w-full h-full" ref={reactFlowWrapper}>
			<ReactFlow
				nodeTypes={nodeTypes}
				edgeTypes={edgeTypes}
				nodes={nodes}
				edges={edges}
				onNodesChange={onNodesChange}
				onEdgesChange={onEdgesChange}
				fitView
				fitViewOptions={{ padding: 0.4 }}
				minZoom={0.01}
				onNodeDragStart={handleContextClose}
				onNodeDragStop={onNodeDragStop}
				onNodesDelete={onNodesDelete}
				onEdgeClick={flowName !== "Production" && interactive ? onEdgeClick : undefined}
				onEdgeUpdate={onEdgeUpdate}
				onEdgesDelete={onEdgesDelete}
				onConnect={onConnect}
				onMoveStart={handleContextClose}
				onSelectionChange={onSelectionChange}
				onSelectionDragStart={handleContextClose}
				onSelectionDragStop={onSelectionDragStop}
				onSelectionContextMenu={onSelectionContextMenu}
				onPaneClick={onPaneClick}
				onPaneContextMenu={flowName !== "Production" && interactive ? onPaneContextMenu : undefined}
				nodesDraggable={flowName !== "Production" && interactive}
				nodesConnectable={flowName !== "Production" && interactive}
				connectOnClick={flowName !== "Production" && interactive}
				elementsSelectable={flowName !== "Production" && interactive} 
				panOnDrag={tool === "pan" ? true : [2]}
				panOnScroll={true}
				selectionOnDrag={tool === "select"}
				zoomOnDoubleClick={false}
				connectionLineStyle={{ strokeWidth: "2px" }}
				deleteKeyCode={deleteKeyCode}
				proOptions={{ hideAttribution: true, account: "paid-custom" }}
			>
				{ flowLoading &&
					<div className="absolute w-full h-full bg-gray-20/80 shadow-inner z-10">
						<div className="absolute top-[40%] left-[calc(50%-50px)] p-3 bg-gray-10 rounded-lg shadow z-11">
							<Loading />
						</div>
					</div>
				}

				<Background />

				<Tooltip placement="right" singleton={sidebarTipSource} />

				<div className="absolute top-20 left-4 flex flex-col w-fit rounded-lg bg-gray-10 shadow overflow-hidden z-10">
					{[
						{ name: "select", tooltip: "Select", icon: tool === "select" ? <CursorFill /> : <Cursor /> },
						{ name: "pan", tooltip: "Pan", icon: tool === "pan" ? <HandIndexThumbFill /> : <HandIndexThumb /> }
					].map(node => {
						return (
							<Tooltip
								key={`sidebar-item-${node.name}`}
								content={node.tooltip}
								placement="right"
								singleton={sidebarTipTarget}
							>
								<button
									className={`w-9 h-9 toolbar-item ${node.name} ${node.name === tool ? "active" : ""}`}
									onClick={() => pickTool(node.name)}
								>
									{ node.icon }
								</button>
							</Tooltip>
						);
					})}
				</div>

				{ flowName !== "Production" &&
					<div className="absolute top-[10.5rem] left-4 flex flex-col w-fit rounded-lg bg-gray-10 shadow overflow-hidden z-10">
						{[
							{ name: MsgType.Input, tooltip: "Start node", icon: <BoxArrowInRight /> },
							{ name: MsgType.Output, tooltip: "Exit node", icon: <BoxArrowRight /> },
							{ name: MsgType.BotText, tooltip: "Bot text response", icon: <TextareaT /> },
							{ name: MsgType.BotMedia, tooltip: "Bot media response", icon: tool === MsgType.BotMedia ? <CollectionPlayFill /> : <CollectionPlay /> },
							{ name: MsgType.UserButton, tooltip: "User button", icon: <Bullseye /> },
							{ name: MsgType.UserList, tooltip: "User list", icon: <UiChecks /> },
							{ name: MsgType.UserText, tooltip: "User free text", icon: <InputCursorText /> },
							{ name: MsgType.AddToProgram, tooltip: "Add to programme", icon: tool === MsgType.AddToProgram ? <NodePlusFill /> : <NodePlus /> },
							{ name: MsgType.Comment, tooltip: "Comment", icon: tool === MsgType.Comment ? <StickyFill /> : <Sticky /> }
						].map(node => {
							if(!node) return <></>;
							return (
								<Tooltip
								key={`sidebar-item-${node.name}`}
								content={node.tooltip}
								placement="right"
								singleton={sidebarTipTarget}
								>
									<button
										className={`w-9 h-9 toolbar-item ${node.name} ${node.name === tool ? "active" : ""}`}
										onClick={() => pickTool(node.name)}
									>
										{ node.icon }
									</button>
								</Tooltip>
							);
						})}
						<Tooltip
							key={`sidebar-item-expand`}
							content={"More nodes"}
							placement="right"
							singleton={sidebarTipTarget}
							>
							<button
								className={`w-9 h-9 toolbar-item toolbar-expand`}
								onClick={() => setToolMenuExpanded(!toolMenuExpanded)}
								>
								{ toolMenuExpanded ? <ChevronUp /> : <ChevronDown /> }
							</button>
						</Tooltip>
						{ toolMenuExpanded &&
							[
								{ name: MsgType.BotNetImg, tooltip: "Bot internet image response", icon: tool === MsgType.BotNetImg ? <ImageFill /> : <Image /> },
								{ name: MsgType.UserDate, tooltip: "Date picker", icon: tool === MsgType.UserDate ? <CalendarEventFill /> : <CalendarEvent /> },
								{ name: MsgType.UserTime, tooltip: "Time picker", icon: tool === MsgType.UserTime ? <ClockFill /> : <Clock /> },
								{ name: MsgType.PickProgram, tooltip: "Programme preview", icon: tool === MsgType.PickProgram ? <CompassFill /> : <Compass /> },
								{ name: MsgType.NotifyPerm, tooltip: "Ask notification permissions", icon: tool === MsgType.NotifyPerm ? <BellFill /> : <Bell /> },
								{ name: MsgType.PickQuest, tooltip: "Quest picker (deprecated)", icon: tool === MsgType.PickQuest ? <TrophyFill /> : <Trophy /> },
								// { name: "userSlider", tooltip: "User slider", icon: <Sliders /> },
							].map(node => {
								return (
									<Tooltip
										key={`sidebar-item-${node.name}`}
										content={node.tooltip}
										placement="right"
										singleton={sidebarTipTarget}
									>
										<button
											className={`w-9 h-9 toolbar-item ${node.name} ${node.name === tool ? "active" : ""}`}
											onClick={() => pickTool(node.name)}
										>
											{ node.icon }
										</button>
									</Tooltip>
								);
							})
						}
					</div>
				}

				<div className="absolute left-4 bottom-4 flex flex-col w-fit rounded-lg bg-gray-10 shadow overflow-hidden z-10">
					{[
						{ name: "Zoom in", icon: <PlusLg />, fn: () => reactFlowInstance.zoomIn() },
						{ name: "Zoom out", icon: <DashLg />, fn: () => reactFlowInstance.zoomOut() },
						flowName !== "Production" ? { name: "Toggle interactivity", icon: interactive ? <Unlock /> : <LockFill />, fn: () => setInteractive(!interactive) } : undefined,
						{ name: "Help", icon: <QuestionLg />, fn: () => setShowHelpMenu(true) }
					].map(node => {
						if (!node) return <></>;
						return (
							<Tooltip
								key={`controls-item-${node.name}`}
								content={node.name}
								placement="right"
								singleton={sidebarTipTarget}
							>
								<button
									className={`w-9 h-9 toolbar-item hover:text-brown-600 ${node.name === "Toggle interactivity" && !interactive ? "bg-brown-600 text-white hover:text-white" : ""}`}
									onClick={node.fn}
								>
									{node.icon}
								</button>
							</Tooltip>
						);
					})}
				</div>

				<HelpMenu show={showHelpMenu} close={() => setShowHelpMenu(false)} />

				<MiniMap
					zoomable pannable
					nodeColor={(node) => {
						switch (node.type) {
							case MsgType.Input:
								return '#7375A5';
							case MsgType.UserButton:
							case MsgType.UserList:
							case MsgType.UserText:
							case MsgType.UserSlider:
							case MsgType.UserDate:
							case MsgType.UserTime:
							case MsgType.PickProgram:
							case MsgType.PickQuest:
								return '#6DA0A4';
							case MsgType.BotText:
							case MsgType.BotNetImg:
							case MsgType.BotMedia:
								return '#FBCC70';
							case MsgType.Output:
							case MsgType.AddToProgram:
							case MsgType.NotifyPerm:
								return '#E15A54';
							case MsgType.Comment:
								return '#E49668';
							default:
								return '#000000';
						}
					}}
					nodeStrokeWidth={2}
					className="bottom-4 bg-gray-50 rounded-lg shadow overflow-hidden"
				/>

				{ showPaneContext &&
					<div
						className="absolute p-1 bg-gray-10 rounded-lg shadow-md overflow-hidden z-50"
						style={{ top: contextClickPosition.y, left: contextClickPosition.x }}
					>
						<Button
							variant="light"
							disabled={clipboard === ""}
							onClick={pasteSelection}
						>
							<Clipboard className="inline w-4 h-4 mr-2 -mt-[2px]" />
							Paste
						</Button>
					</div>
				}

				{ showSelectionContext &&
					<div
						className="absolute p-1 bg-gray-10 rounded-lg shadow-md overflow-hidden z-50"
						style={{ top: contextClickPosition.y, left: contextClickPosition.x }}
					>
						<Button
							variant="light"
							onClick={(event) => clipSelection(event, true)}
						>
							<Scissors className="inline w-4 h-4 mr-2 -mt-[2px]" />
							Cut
						</Button>
						<Button
							variant="light"
							className="ml-1"
							onClick={clipSelection}
						>
							<Files className="inline w-4 h-4 mr-2 -mt-[2px]" />
							Copy
						</Button>
						<Button
							variant="light"
							className="group-icon ml-1 hover:text-red-500"
							onClick={removeSelectedElements}
						>
							<FillIcon Line={Trash} Fill={TrashFill} iconStyle="inline w-4 h-4 mr-2 -mt-[2px]" />
							Delete
						</Button>
					</div>
				}

				<EdgeSettings
					key={`${edgeSettingsData?.id}-menu`}
					show={showEdgeSettings}
					id={edgeSettingsData?.id ?? ""}
					flowID={flowID}
					sourceID={edgeSettingsData?.source ?? ""}
					targetID={edgeSettingsData?.target ?? ""}
					clickPos={edgeSettingsData?.clickPos ?? { x: 0, y: 0 }}
					nextIndex={edgeSettingsData?.nextIndex ?? 0}
					close={() => setShowEdgeSettings(false)}
					removeEdge={removeEdge}
				/>
			</ReactFlow>
		</div>
	);
};




type HelpMenuProps = {
	show: boolean;
	close: () => void;
};

const HelpMenu = ({ show, close }: HelpMenuProps) => (
	<Modal
		show={show}
		close={close}
		className="max-w-2xl pt-6"
	>
		<div className="flex justify-between mb-2">
			<Dialog.Title as="h2" className="text-2xl font-medium leading-6 text-gray-900">
				Using the Botany Story Builder
			</Dialog.Title>
		</div>

		<hr className="border-black" />

		<h3 className="mt-4">Getting around</h3>
		<p>
			While in Select mode, hold <kbd>Space</kbd> or use the right mouse button to pan around.
			<br /><br />
			While in Pan mode, hold <kbd>Shift</kbd> to select.
			<br /><br />
			Use the mouse wheel or the trackpad to zoom in and out.
			<br /><br />
			Select a node and press either <kbd>Backspace</kbd> or <kbd>Delete</kbd> to remove it.
			<br /><br />
			When multiple nodes are selected, right-click to cut or copy. Right-click anywhere on the canvas to paste.
			<br /><br />
			You don't have to drag the connector line from one node to another. If you click on a node's handle, and then on another node's handle, a connector will appear automatically.
		</p>

		<h3>Variables</h3>
		<p>
			The following variables can be used in text messages:
		</p>
		<ul className="pl-4">
			<li className="mt-2"><code className="text-brown-600">%NAME%</code> - User's name</li>
			<li className="mt-2"><code className="text-brown-600">%DATEOFBIRTH%</code> - User's date of birth</li>
			<li className="mt-2"><code className="text-brown-600">%PHYSICALSYMPTOMS%</code> - User's physical symptoms, combining the predefined ones from the checklist with any they added themselves in one phrase</li>
			<li className="mt-2"><code className="text-brown-600">%STOPPEDACTIVITIES%</code> - User's activities they stopped doing because of their symptoms (note: might be `none` or `noHobbies`)</li>
			<li className="mt-2"><code className="text-brown-600">%GREETING%</code> - Any from "Hi", "Hi there", "Hello", "Welcome back", "Good to see you", "Great you're here", and, if time-appropriate, "Good morning" or "Evening", plus the user's name and an exclamation mark - e.g. "Welcome back, Michael!"</li>
			<li className="mt-2"><code className="text-brown-600">%HOWAREYOU%</code> - Any from "How are you today?", "How have you been?", "How are you feeling today?", "How are you doing today?", and "How are things going?"</li>
			<li className="mt-2"><code className="text-brown-600">%DAYOFWEEK%</code> - The day of the week at the time the message was sent</li>
		</ul>

		<h3>Conditions</h3>
		<p>
			Click on a connector to open the Connector Settings menu. Here, you can select a condition which determines whether this connector will be followed or not in a conversation.
			<br /><br />
			Make sure that all conditions are exhaustive and/or provide a default option (without a condition) if necessary.
			<br /><br />
			A connector can have one condition. To create more complex conditions, chain several messages and give each their own condition, starting with the most general and ending with the most specific.
		</p>

		<h3>Deployment</h3>
		<p>
			Stories can have multiple versions: development versions that can be edited as well as one Production version that serves as the de facto saved version. Switch between versions with the button at the top left of the screen, next to the story name.
			<br /><br />
			In Settings, versions can be managed, and changes from a story version can be pushed to production.
		</p>
	</Modal>
);

export default StoryFlow;
