import { FormEvent, useEffect, useState } from 'react';
import StoryTabButton from './StoryTabButton';
import StoryEditor from './StoryEditor';
import Button from '../../utils/Button';
import Input from '../../utils/Input';
import MenuPlaceholder from '../../utils/MenuPlaceholder';
import { Story, StoryCat, TreeNode, StoryType } from '../../types';
import firebase from '../../firebase';
import { ref, push, set, remove, getDatabase } from 'firebase/database';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';
import { Tab } from "@headlessui/react";
import { DndProvider } from "react-dnd";
import { Tree, getDescendants, MultiBackend, getBackendOptions } from '@minoru/react-dnd-treeview';
import { FolderPlus, Stars, TrashFill } from 'react-bootstrap-icons';
import Tooltip from '../../utils/Tooltip';
import Loading from '../../utils/Loading';


const db = getDatabase(firebase);

const Stories = () => {
	const [selectedStoryID, setSelectedStoryID] = useState("none");
	const [openFolders, setOpenFolders] = useState<(string | number)[]>([]);
	const [fullscreen, setFullscreen] = useState(false);
	const [draftName, setDraftName] = useState("");
	const [clipboard, setClipboard] = useState("");


	const storiesRef = ref(db, 'stories/');
	const [stories, storiesLoading, storiesError] = useListVals<Story>(storiesRef, { keyField: "id" });

	const storyTreeRef = ref(db, 'storyTree/');
	const [storyTree, storyTreeLoading, storyTreeError] = useObjectVal<TreeNode[]>(storyTreeRef);

	const storyFlowsRef = ref(db, 'storyFlows/');


	useEffect(() => {
		if (storiesError) console.error(storiesError);
		if (storyTreeError) console.error(storyTreeError);
	}, [storiesError, storyTreeError]);


	const setStory = (storyID: string, story: Story) => {
		set(ref(db, 'stories/' + storyID), story);
	};

	const removeStory = (storyID: string) => {
		remove(ref(db, 'stories/' + storyID));
	};

	const removeStoryFlow = (storyFlowID: string) => {
		remove(ref(db, 'storyFlows/' + storyFlowID));
	};

	const pushStoryNode = (storyNode: TreeNode) => {
		set(ref(db, 'storyTree/' + storyTree?.length ?? 0), storyNode);
	};

	const removeStoryNode = (storyNodeID: TreeNode['id']) => {
		if (!storyTree) return;
		// We need to set a new tree instead of calling remove()
		// because in Firebase the indices of the array will
		// not automatically update
		const index = storyTree.findIndex(el => el.id === storyNodeID);
		const newTree = [...storyTree];
		newTree.splice(index, 1);
		set(storyTreeRef, newTree);
	};


	const handleMenuItemDrop = (newTreeList: TreeNode[]) => {
		set(storyTreeRef, newTreeList);
	};

	const handleNameFormUpdate = (val: string) => {
		setDraftName(val);
	};

	const handleNewStory = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		createStory();
	};


	const createStory = () => {
		if (draftName === "") return;

		const newStoryID = push(storiesRef).key!;
		const newDraftFlowID = push(storyFlowsRef).key!;
		const newProdFlowID = push(storyFlowsRef).key!;

		const newStory: Story = {
			id: newStoryID,
			name: draftName,
			type: StoryType.Story,
			category: StoryCat.Dev,
			live: false,
			flows: {
				[newDraftFlowID]: "Draft",
				[newProdFlowID]: "Production"
			}
		};

		const newStoryNode: TreeNode = {
			id: push(storyTreeRef).key!,
			parent: 0,
			droppable: false,
			text: newStoryID
		};

		setStory(newStoryID, newStory);
		pushStoryNode(newStoryNode);
		setDraftName("");
		setSelectedStoryID(newStoryID);
	};

	const deleteStory = (storyID: string) => {
		const story = stories?.find(story => story.id === storyID)!;

		const storyNode = storyTree?.find(node => node.text === storyID);
		if (!storyNode) return;

		removeStory(storyID);

		Object.keys(story.flows).forEach(flowID => removeStoryFlow(flowID));

		removeStoryNode(storyNode.id);
	};

	const pushFolder = () => {
		const newFolder: TreeNode = {
			id: push(storyTreeRef).key!,
			parent: 0,
			droppable: true,
			text: "New folder"
		}
		pushStoryNode(newFolder);
	};

	const renameFolder = (newName: string, folder: TreeNode) => {
		const index = storyTree?.findIndex(el => el.id === folder.id);
		if (index !== undefined) set(ref(db, 'storyTree/' + index + '/text'), newName);
	};

	const deleteFolder = (storyNodeID: TreeNode['id']) => {
		const deleteIDs = [
			...getDescendants(storyTree!, storyNodeID).map(node => node.id),
			storyNodeID
		];

		for (let id of deleteIDs) {
			const currNode = storyTree!.find(node => node.id === id);
			if (!currNode) continue;

			if (currNode.droppable) removeStoryNode(id);
			else deleteStory(currNode.text);
		}
	};


	const handleTabSelect = (storyID: string) => {
		setSelectedStoryID(storyID);
	};

	const handleFullscreen = () => {
		setFullscreen(!fullscreen);

		if (fullscreen) document.exitFullscreen();
		else document.documentElement.requestFullscreen();
	};


	return (
		<Tab.Panel className="w-full h-full flex focus-visible:outline-none">
			<div className={`flex flex-col h-[calc(100%-0.5rem)] w-1/3 lg:w-1/4 2xl:w-1/5 max-w-[400px] my-2 ml-1 mr-0 pt-6 pl-3`}>
				<div className="mr-4">
					<h1 className="text-4xl font-semibold text-brown-600">Stories</h1>
					<div className="flex flex-row w-full mt-2">
						<form
							onSubmit={handleNewStory}
							className="relative flex-grow mr-2"
						>
							<Input
								init={draftName}
								onChange={handleNameFormUpdate}
								onBlur={createStory}
								placeholder="Add story"
								className="w-full pl-4 pr-10"
							/>
							<Tooltip content="Add story" placement="top">
								<Button submit
									className="absolute w-9 h-9 top-[0.375rem] right-[0.375rem] pl-0 pr-0 !rounded-full"
								>
									→
								</Button>
							</Tooltip>
						</form>

						<Tooltip content="Add folder" placement="top">
							<Button
								onClick={pushFolder}
							>
								<FolderPlus className="w-5 h-5" />
							</Button>
						</Tooltip>
					</div>
				</div>

				<div className="flex-grow w-full mt-6 pr-4 pb-10 space-y-1 overflow-y-auto">
					{ storiesLoading || storyTreeLoading ? <Loading /> :
						<DndProvider backend={MultiBackend} options={getBackendOptions()}>
							<Tree
								tree={storyTree ?? []}
								initialOpen={openFolders}
								rootId={0}
								sort={false}
								insertDroppableFirst={false}
								dropTargetOffset={10}
								onDrop={handleMenuItemDrop}
								canDrop={(_, { dragSource, dropTargetId }) => {
									if (dragSource?.parent === dropTargetId) return true;
								}}
								onChangeOpen={newOpenIDs => setOpenFolders(newOpenIDs)}
								render={(storyNode: TreeNode, options) => {
									return storyNode.droppable ?
										<StoryTabButton
											node={storyNode}
											handleTabSelect={handleTabSelect}
											renameFolder={renameFolder}
											deleteFolder={deleteFolder}
											{...options}
										/>
										:
										<StoryTabButton
											node={storyNode}
											storyID={storyNode.text}
											storyName={stories?.find(story => story.id === storyNode.text)?.name}
											storyType={stories?.find(story => story.id === storyNode.text)?.type}
											selectedStory={selectedStoryID}
											handleTabSelect={handleTabSelect}
											deleteStory={deleteStory}
											{...options}
										/>
								}}
								classes={{
									draggingSource: "opacity-40",
									placeholder: "relative"
								}}
								placeholderRender={(_, { depth }) =>
									<MenuPlaceholder depth={depth} />
								}
							/>
						</DndProvider>
					}
				</div>
			</div>

			<div className={`h-full ${fullscreen ? "absolute w-full left-0" : "relative float-right w-2/3 lg:w-3/4 2xl:w-4/5 rounded-l-lg"} shadow-inner bg-gray-20 overflow-hidden`}>
				{ // If loading
					!stories || storiesLoading ?
						<div className="absolute w-full h-full bg-gray-20/80 shadow-inner z-10">
							<div className="absolute top-[40%] left-[calc(50%-50px)] p-3 bg-gray-10 rounded-lg shadow z-11">
								<Loading />
							</div>
						</div>
				// If no story is selected
					: selectedStoryID === "none" ?
						<div className="h-full" key="story-canvas-get-started">
							<div className="h-full flex flex-col justify-center items-center">
								<Stars className="w-8 h-8 mb-8 fill-green-500" />
								<p>Select a story to start editing!</p>
							</div>
						</div>
				// If a story has just been deleted, either here or by another user
					: !stories?.find(s => s.id === selectedStoryID) ?
						<div className="h-full" key="story-canvas-deleted">
							<div className="h-full flex flex-col justify-center items-center">
								<TrashFill className="w-8 h-8 mb-8 fill-red-500" />
								<p>Story deleted!</p>
							</div>
						</div>
				// If we have a story
					:
						<StoryEditor
							story={stories.find(story => story.id === selectedStoryID)!}
							clipboard={clipboard}
							fullscreen={fullscreen}
							setStory={setStory}
							setClipboard={setClipboard}
							handleFullscreen={handleFullscreen}
						/>
				}
			</div>
		</Tab.Panel>
	);
};

export default Stories;
