import { useEffect, useState } from 'react';
import { MediaLocation, MediaType, Story, Quest } from '../../../types';
import FillIcon from '../../../utils/FillIcon';
import MediaPreview from '../../../utils/MediaPreview';
import Tooltip from '../../../utils/Tooltip';
import firebase from '../../../firebase';
import { getDatabase, orderByChild, query, ref } from 'firebase/database';
import { useListVals } from 'react-firebase-hooks/database';
import { Check2, ChevronExpand, Trash, TrashFill } from 'react-bootstrap-icons';
import { Combobox, Tab } from '@headlessui/react';


const db = getDatabase(firebase);

type StorySettingsQuestsProps = {
	story: Story;
	setStory: (storyID: string, story: Story) => void;
};

const StorySettingsQuests = ({ story, setStory }: StorySettingsQuestsProps) => {
	const [questQuery, setQuestQuery] = useState("");

	const questsRef = ref(db, "quests/");
	const [quests, questsLoading, questsError] = useListVals<Quest>(query(questsRef, orderByChild("title")), { keyField: "id" });
	useEffect(() => {
		if (questsError) console.error(questsError);
	}, [questsError]);

	const setQuests = (questIDs: string[]) => {
		const newStory = { ...story };
		newStory.quests = questIDs;
		setStory(story.id, newStory);
	};

	const removeQuest = (questID: string) => {
		const newQuests = [...story.quests ?? []];
		const questIndex = newQuests.findIndex(el => el === questID);
		if(questIndex === -1) return;
		newQuests.splice(questIndex, 1);
		setQuests(newQuests);
	};

	const filteredQuests =
		!quests || questsLoading || questsError ? [] :
		questQuery === "" ? quests :
		// Filter by input, ignoring case & whitespace
		quests.filter(quest => quest.title.toLowerCase().replace(/\s+/g, "").includes(questQuery.toLowerCase().replace(/\s+/g, "")));

	return (
		<Tab.Panel>
			<h3 className="mt-4">Add quest</h3>
			<div className="relative w-full">
				<Combobox
					multiple
					value={story.quests ?? []}
					onChange={setQuests}
				>
					<Combobox.Input
						onChange={event => setQuestQuery(event.target.value)}
						placeholder="Type or press the arrows to select..."
						className="relative w-full h-12 pl-4 pr-10 py-2 text-left placeholder:text-olive-600 truncate bg-olive-50 hover:bg-olive-100 rounded-md"
					/>
					<Combobox.Button
						className="absolute inset-y-0 right-0 flex items-center pr-3"
					>
						<ChevronExpand
							className="h-5 w-5 hover:scale-110 transition-transform"
							aria-hidden={true}
						/>
					</Combobox.Button>
					<Combobox.Options
						className="absolute w-full mt-1 overflow-hidden border-2 border-olive-100 bg-olive-20 rounded-lg shadow z-50"
					>
						<div className="max-h-96 py-1 overflow-x-hidden overflow-y-auto">
							{ filteredQuests.length === 0 && questQuery !== "" ? (
								<div className="relative w-full py-2 pl-8 pr-2 text-left italic select-none truncate">
									No quests found.
								</div>
							) : filteredQuests.map(quest => (
								<Combobox.Option
									key={quest.id}
									value={quest.id}
								>
									{({ selected, active }) => (
										<button
											className={`relative w-full py-2 pl-8 pr-2 text-left truncate cursor-pointer ${active ? "bg-olive-100" : "hover:bg-olive-100"} active:font-semibold ${selected ? "font-semibold" : "font-normal"}`}
										>
											{ quest.title }
											<Check2
												className={`${selected ? "block" : "hidden"} absolute top-3 left-2`}
												aria-hidden={true}
											/>
										</button>
									)}
								</Combobox.Option>
							))}
						</div>
					</Combobox.Options>
				</Combobox>
			</div>

			<h3>Quests</h3>
			<p className="mb-2">
				These quests will be unlocked upon completing this story.
			</p>

			{ !story.quests || story.quests?.length === 0 ?
				<p className="italic ml-8">None yet!</p>
			: story.quests?.map((questID, index) => {
				const quest = quests?.find(el => el.id === questID);
				if(!quest) return <></>;
				return (
					<div
						key={index}
						className="flex items-center pl-2 mb-2 bg-gray-21 rounded"
					>
						<MediaPreview
							location={MediaLocation.Local}
							type={MediaType.Icon}
							src={quest.icon}
							className="!w-12 !h-12 my-1 mr-4"
						/>
						{ quest.title }
						<Tooltip content="Remove quest" >
							<button
								className={`group-icon h-14 w-14 ml-auto rounded-md cursor-pointer hover:bg-red-500`}
								onClick={() => removeQuest(questID)}
							>
								<FillIcon
									Line={Trash}
									Fill={TrashFill}
									iconStyle="w-5 h-5 fill-red-500 group-icon-hover:fill-white"
								/>
							</button>
						</Tooltip>
					</div>
				);
			})}
		</Tab.Panel>
	);
};

export default StorySettingsQuests;
