import { useEffect, useState } from 'react';
import firebase from '../firebase';
import { FullMetadata, getMetadata, getStorage, ref, StorageError } from 'firebase/storage';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { MediaType } from '../types';


const storage = getStorage(firebase);

type StoredMediaRenderProps = {
	downloadURL?: string;
	animationData?: any;
	urlLoading: boolean;
	urlError?: StorageError;
	metadata?: FullMetadata;
};

type StoredMediaProps = {
	fileType: MediaType;
	src: string;
	children: (props: StoredMediaRenderProps) => JSX.Element;
};

const WithStoredMedia = ({ fileType, src, children }: StoredMediaProps) => {
	const [metadata, setMetadata] = useState<FullMetadata>();

	const mediaRef = ref(storage, fileType + "/" + src);
	const [downloadURL, urlLoading, urlError] = useDownloadURL(mediaRef);

	const [animationData, setAnimationData] = useState<any>();

	useEffect(() => {
		const fetchMetadata = async () => {
			const mediaRef = ref(storage, fileType + "/" + src);
			const data = await getMetadata(mediaRef);
			setMetadata(data);
		};

		fetchMetadata();
	}, [fileType, src]);

	useEffect(() => {
		if (!urlError) return;
		console.error(urlError);
	}, [urlError]);

	useEffect(() => {
		if (fileType !== MediaType.Animation || !downloadURL) return;

		getJSON(downloadURL, (status, response) => {
			if(status === 200) {
				setAnimationData(response);
			} else {
				console.error("Couldn't fetch animation data: " + status);
			}
		})
	}, [fileType, downloadURL]);

	return children({ downloadURL, animationData, urlLoading, urlError, metadata });
};

export default WithStoredMedia;




const getJSON = (url: string, callback: (status: number, response: any) => void) => {
	const xhr = new XMLHttpRequest();
	xhr.open("GET", url, true);
	xhr.responseType = "json";
	xhr.onload = function () {
		callback(xhr.status, xhr.response);
	};
	xhr.send();
};
