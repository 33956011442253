import { ReactNode } from 'react';
import Tooltip from '../../../utils/Tooltip';
import { Handle, Position } from 'reactflow';
import { Icon } from 'react-bootstrap-icons';


type NodeProps = {
	children: ReactNode;
	title: string;
	handleTop: boolean;
	handleBottom: 0 | 1 | 2;
	headerButtons?: ReactNode;
	tipSource?: any;
	Icon: Icon;
};

const Node = ({ children, title, handleTop, handleBottom, headerButtons, tipSource, Icon }: NodeProps) => {
	return (
		<div className="group">
			<span className="header">
				{ handleTop &&
					<Handle
						type="target"
						position={Position.Top}
					/>
				}

				<div className="flex h-7 py-1 px-2">
					{ tipSource && <Tooltip singleton={tipSource} />}

					<div>
						<Icon className="w-4 h-4" />&nbsp;&nbsp;<span>{ title }</span>
					</div>
					<div className="grow" />
					{ headerButtons }
				</div>
			</span>

			{ children }

			{ handleBottom === 1 &&
				<Handle
					type="source"
					position={Position.Bottom}
				/>
			}
			{ handleBottom === 2 && <>
				<Handle
					id="left"
					type="source"
					position={Position.Bottom}
					style={{ left: 76 }}
				/>
				<Handle
					id="right"
					type="source"
					position={Position.Bottom}
					style={{ left: 172 }}
				/>
			</> }
		</div>
	);
};

export default Node;
