import Node from "./Node";
import EffectContainer from './EffectContainer';
import Select, { SelectOptions } from '../../../utils/Select';
import { MsgType, NodeData } from '../../../types';
import firebase from '../../../firebase';
import { getDatabase, ref, set } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Icon, Save } from 'react-bootstrap-icons';


const db = getDatabase(firebase);

const SelectNode = (type: MsgType, title: string, Icon: Icon, options: SelectOptions, data: NodeData) => {
	// Get the node's content
	const contentRef = ref(db, 'storyFlows/' + data.flowID + '/' + data.nodeID + '/content');
	const [content, loading, error] = useObjectVal<string[]>(contentRef);
	const value: string = content ? content[0] : "";

	// Handle input
	const handleSelectUpdate = (value: string) => {
		set(contentRef, [value]);
	};

	// Render the node
	return (
		<Node
			title={title}
			handleTop handleBottom={1}
			Icon={Icon}
		>
			{ loading ?
				<p>Loading...</p>
			: error ?
				<p>Error: { error.toString() }</p>
			: type === MsgType.UserText || type === MsgType.UserDate || type === MsgType.UserTime ?
				<EffectContainer
					title="Save as"
					Icon={Save}
				>
					<div className="relative w-full">
						<Select
							value={value}
							options={options}
							size="sm"
							onChange={handleSelectUpdate}
						/>
					</div>
				</EffectContainer>
				:
				<div className="relative w-full p-1">
					<Select
						value={value}
						options={options}
						size="sm"
						onChange={handleSelectUpdate}
					/>
				</div>
			}
		</Node>
	);
};

export default SelectNode;
