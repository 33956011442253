import { useEffect } from "react";
import { Story } from "../types";
import firebase from "../firebase";
import { getDatabase, ref } from "firebase/database";
import { useObjectVal } from "react-firebase-hooks/database";


const db = getDatabase(firebase);

type StoryNameProps = {
	id: string;
};

const StoryName = ({ id }: StoryNameProps) => {
	const [story, storyLoading, storyError] = useObjectVal<Story>(ref(db, "stories/" + id));

	useEffect(() => {
		if(storyError) console.error(storyError);
	}, [storyError]);

	if(storyLoading || !story) return <>...</>;
	return <>{ story.name }</>;
};

export default StoryName;
