import { useEffect, useState } from "react";
import { MediaLocation, MediaType, Quest, QuestCat } from "../../types";
import EditText from "../../utils/EditText";
import MediaPreview from "../../utils/MediaPreview";
import Select from "../../utils/Select";
import Switch from "../../utils/Switch";
import firebase from "../../firebase";
import { getDatabase, ref, set } from "firebase/database";


const db = getDatabase(firebase);

type QuestEditorProps = {
	quest: Quest;
};

const QuestEditor = ({ quest }: QuestEditorProps) => {
	const updateQuest = (field: string, value: string | boolean) => {
		set(ref(db, "quests/" + quest.id + "/" + field), value);
	};

	const [descDraft, setDescDraft] = useState(quest.description);
	useEffect(() => { // :/
		setDescDraft(quest.description);
	}, [quest.description]);


	return (
		<div className="pb-60" key={quest.id}>
			<div className="w-fit mt-2 -ml-2 mb-2 px-1 font-bold text-2xl">
				<EditText
					name="title"
					init={quest.title}
					fn={updateQuest}
				/>
			</div>

			<div
				className="group relative max-w-[800px] min-h-[72px] mb-4 p-4 pb-2 border border-gray-200 bg-gray-20 rounded-lg"
			>
				<div className="flex flex-row items-center">
					<MediaPreview
						location={MediaLocation.Local}
						type={MediaType.Icon}
						src={quest.icon}
						className={`!w-12 !h-12 mr-3 shrink-0`}
						changeMedia={icon => updateQuest("icon", icon)}
					/>

					<div className="w-px h-10 bg-gray-200 mr-3" />

					<label className="mr-2">Category:</label>
					<Select
						value={quest.category}
						options={[
							[QuestCat.None, "None"],
							[QuestCat.Relax, "Relax"],
							[QuestCat.BeActive, "Be active"],
							[QuestCat.ManageLife, "Manage your life"]
						]}
						onChange={category => updateQuest("category", category)}
					/>
				</div>

				<div className="flex flex-row mt-2 mb-4">
					<MediaPreview
						location={MediaLocation.Local}
						type={MediaType.Image}
						src={quest.banner}
						className={`!w-1/4 mr-2`}
						changeMedia={banner => updateQuest("banner", banner)}
					/>
					<textarea
						value={descDraft}
						onChange={event => setDescDraft(event.target.value)}
						onBlur={() => updateQuest("description", descDraft)}
						placeholder="Quest description"
						className="w-full min-h-12 pl-4 pr-10 py-4 bg-olive-50 hover:bg-olive-100 focus:bg-olive-100 text-olive-900 rounded-md placeholder:text-olive-600 transition"
					/>
				</div>

				<Switch
					checked={quest.live}
					label="Enable this quest in the app"
					onChange={checked => updateQuest("live", checked)}
				/>
			</div>
		</div>
	);
};

export default QuestEditor;
