import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from "react";
import MediaCard from "./MediaCard";
import { Media, MediaFolder, MediaType } from "../../types";
import { capFirstLetter } from "../../helpers";
import Button from "../../utils/Button";
import FileInput from "../../utils/FileInput";
import Input from "../../utils/Input";
import Loading from "../../utils/Loading";
import Modal from "../../utils/Modal";
import Popover from "../../utils/Popover";
import firebase from "../../firebase";
import { getDatabase, push, ref as dbRef, remove, set } from "firebase/database";
import { deleteObject, getStorage, ref as storageRef, uploadBytesResumable } from "firebase/storage";
import { Dialog, RadioGroup } from "@headlessui/react";
import { CloudUpload, FolderPlus, FolderSymlink, Icon, InputCursorText, SortAlphaDown, SortAlphaDownAlt, Stars, Trash } from "react-bootstrap-icons";
import { useListVals } from "react-firebase-hooks/database";


const db = getDatabase(firebase);
const storage = getStorage(firebase);


enum MediaSort {
    AZ = "az",
    ZA = "za",
    New = "new"
};

type SortType = {
    type: MediaSort;
    name: string;
    Icon: Icon;
};

const sortTypes: SortType[] = [
    { type: MediaSort.AZ, name: "A-Z", Icon: SortAlphaDown },
    { type: MediaSort.ZA, name: "Z-A", Icon: SortAlphaDownAlt },
    { type: MediaSort.New, name: "Recent", Icon: Stars }
];


type SelectedItem = {
    id: string;
    type: "item" | "folder";
};


type MediaContentProps = {
    location: "panel" | "modal";
    fileType: MediaType;
    mime: string;
    onSelect?: (mediaID: string) => void;
};

const MediaContent = ({ location, fileType, mime, onSelect }: MediaContentProps) => {
    const [mediaList, setMediaList] = useState<Media[]>([]);
    const [openFolder, setOpenFolder] = useState("root");
    const [folderTree, setFolderTree] = useState<string[]>([]);
    const [selected, setSelected] = useState<SelectedItem>();
    const [nameInput, setNameInput] = useState("");
    const [sort, setSort] = useState(MediaSort.AZ);
    const [showRenameDialog, setShowRenameDialog] = useState(false);
    const [showUploadWarning, setShowUploadWarning] = useState(false);

    const [fileToUpload, setFileToUpload] = useState<File>();
    const [uploadProgress, setUploadProgress] = useState(0);


    const [mediaFolders, mediaFoldersLoading, mediaFoldersError] = useListVals<MediaFolder>(dbRef(db, "mediaFolders/"), { keyField: "id" });
    useEffect(() => {
        if(mediaFoldersError) console.error(mediaFoldersError);
    }, [mediaFoldersError]);

    const [mediaFiles, mediaFilesLoading, mediaFilesError] = useListVals<Media>(dbRef(db, "media/"), { keyField: "id" });
    useEffect(() => {
        if(mediaFilesError) console.error(mediaFilesError);
    }, [mediaFilesError]);


    const getParent = useCallback((id: string) => mediaFolders?.find(folder => folder.id === id)?.parent, [mediaFolders]);
    const getFolderName = useCallback((id: string) => mediaFolders?.find(folder => folder.id === id)?.name, [mediaFolders]);
    const getItemName = useCallback((id: string) => mediaList.find(item => item.id === id)?.name, [mediaList]);


    // Create the breadcrumbs when moving into a new folder
    useEffect(() => {
        if(openFolder === "root") return;

        let id = openFolder;
        let parent = "";
        let parents: string[] = [];

        while(parent !== "root") {
            parent = getParent(id)!;
            parents.push(parent);
            id = parent;
        }

        setFolderTree(parents);
    }, [openFolder, getParent]);


    // Filter and sort the list of media files
    useEffect(() => {
        if(!mediaFiles) return;

        const list = [...mediaFiles.filter(file => file.type === fileType && file.folder === openFolder)];

        if(sort === MediaSort.AZ) list.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);
        else if(sort === MediaSort.ZA) list.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? -1 : a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0);
        else if(sort === MediaSort.New) list.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());

        setMediaList(list);
    }, [mediaFiles, fileType, openFolder, sort]);


    // Reset the name input when the selected item changes
    useEffect(() => {
        if(!selected) return;
        else if(selected.type === "folder") {
            setNameInput(getFolderName(selected.id)!);
        }
    }, [selected, getFolderName]);


    // Upload new files
    const handleSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : undefined;
        setFileToUpload(file);
    };

    const upload = async () => {
        if(!fileToUpload) return;

        const alreadyExists = mediaFiles?.find(item => item.name === fileToUpload.name);
        if(alreadyExists && alreadyExists.folder !== openFolder) {
            setShowUploadWarning(true);
            return;
        }

        const mediaRef = storageRef(storage, fileType + '/' + fileToUpload.name);
        const uploadTask = uploadBytesResumable(mediaRef, fileToUpload);

        uploadTask.on("state_changed",
            snapshot => {
                const percent = Math.round(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(percent);
            },
            error => console.error(error),
            // On complete
            () => {
                if(!alreadyExists) {
                    // If we're an audio file, get the file duration and save it as metadata
                    // Having this preset greatly improves the experience in the app
                    if(fileType === MediaType.Audio) {
                        const reader = new FileReader();
                        reader.readAsArrayBuffer(fileToUpload);
                        reader.onloadend = event => {
                            const ctx = new AudioContext();
                            const audioArrayBuffer = event.target?.result;
                            if(!audioArrayBuffer) return;
                            ctx.decodeAudioData(audioArrayBuffer as ArrayBuffer,
                                data => {
                                    push(dbRef(db, "media/"), {
                                        name: fileToUpload.name,
                                        created: Date.now(),
                                        type: fileType,
                                        folder: openFolder,
                                        duration: data.duration
                                    });
                                },
                                error => console.error(error)
                            );
                        }
                    }
                    else {
                        push(dbRef(db, "media/"), {
                            name: fileToUpload.name,
                            created: Date.now(),
                            type: fileType,
                            folder: openFolder
                        });
                    }
                }
            }
        );
    };


    // Interact with files
    const selectMedia = (id: string) => {
        setSelected({ id, type: "item" });
    };

    const deleteMedia = (id: string) => {
        setSelected(undefined);
        const name = getItemName(id);
        deleteObject(storageRef(storage, fileType + "/" + name));
        remove(dbRef(db, "media/" + id));
    };


    // Interact with folders
    const goToFolder = (newFolderID: string) => {
        setOpenFolder(newFolderID);
        setSelected(undefined);
        setShowUploadWarning(false);
    };

    const createFolder = () => {
        push(dbRef(db, "mediaFolders/"), {
            name: "New folder",
            type: fileType,
            parent: openFolder
        });
    };

    const handleNameFormUpdate = (val: string) => {
        setNameInput(val);
    };

    const handleNameFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(selected?.type === "folder") renameFolder();
    };

    const renameFolder = () => {
        set(dbRef(db, "mediaFolders/" + selected?.id + "/name"), nameInput);
        setShowRenameDialog(false);
    };

    const moveToFolder = (destination: string) => {
        if(!selected) return;
        else if(selected.type === "folder") {
            if(getParent(destination) === selected.id) set(dbRef(db, "mediaFolders/" + destination + "/parent"), getParent(selected.id));
            set(dbRef(db, "mediaFolders/" + selected.id + "/parent"), destination);
        }
        else if(selected.type === "item") {
            set(dbRef(db, "media/" + selected.id + "/folder"), destination);
        }
    };


    // Sort the media list
    const handleUpdateSort = (newSort: MediaSort) => {
        setSort(newSort);
    };


    const currentFolders = mediaFolders?.filter(folder => folder.type === fileType && folder.parent === openFolder).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);


    // Render the media explorer
    return (
        <>
            <h2 className="text-2xl font-bold mt-3 mb-2">
                { openFolder === "root" ? capFirstLetter(fileType) : <>
                    <button
                        className="px-2 rounded-lg hover:bg-gray-21"
                        onClick={() => goToFolder(getParent(openFolder) ?? openFolder)}
                    >
                        &larr;
                    </button>

                    { folderTree.reverse().map(parent => (
                        <>
                            { parent !== "root" && <span>&nbsp;/&nbsp;</span> }
                            <button
                                key={parent}
                                className="rounded-lg hover:bg-gray-21"
                                onClick={() => goToFolder(parent)}
                            >
                                { parent === "root" ? capFirstLetter(fileType) : getFolderName(parent) }
                            </button>
                        </>
                    )) }
                    <span>&nbsp;/&nbsp;{getFolderName(openFolder)}</span>
                </> }
            </h2>

            <div className="h-12 mb-4 flex items-stretch z-10">
                <Button
                    onClick={createFolder}
                >
                    <FolderPlus className="w-5 h-5 mr-2" /> Add folder
                </Button>

                <div className="w-[2px] h-8 mt-2 bg-gray-50 mx-4" />

                <FileInput
                    accept={mime}
                    className="h-12"
                    onChange={handleSelectFile}
                />
                { (uploadProgress !== 0 && uploadProgress !== 100) ?
                    <Loading
                        customText={`Uploading... ${uploadProgress}%`}
                        className="self-center"
                    />
                :
                    <Button
                        onClick={upload}
                    >
                        <CloudUpload className="w-5 h-5 mr-2" /> Upload
                    </Button>
                }

                { showUploadWarning &&
                    <p className="w-80 ml-4 text-red-600">
                        There already exists a file with this name in another folder. Move or delete that file or give this one a different name.
                    </p>
                }
            </div>

            <div className="flex h-12 mb-4">
                <RadioGroup
                    value={sort}
                    onChange={(newSort: MediaSort) => handleUpdateSort(newSort)}
                    className="flex items-center gap-2"
                >
                    <RadioGroup.Label>Sort by</RadioGroup.Label>

                    { sortTypes.map((sortType, index) => (
                        <RadioGroup.Option
                            key={index}
                            value={sortType.type}
                            className="px-4 py-2 text-olive-900 border-olive-5 border hover:bg-olive-5 ui-checked:bg-olive-5 rounded-3xl cursor-pointer"
                        >
                            <sortType.Icon className="w-5 h-5 mr-2" />
                            <RadioGroup.Label className="cursor-pointer">
                                { sortType.name }
                            </RadioGroup.Label>
                        </RadioGroup.Option>
                    ))
                    }
                </RadioGroup>

                { location === "panel" &&<>
                    <div className="w-[2px] h-8 mt-2 bg-gray-50 mx-4" />

                    <Button
                        variant="light"
                        disabled={!selected || selected.type === "item"}
                        onClick={() => setShowRenameDialog(true)}
                    >
                        <InputCursorText className="w-5 h-5 mr-2" /> Rename
                    </Button>

                    <Popover
                        content={
                            <div className="flex flex-col p-2 max-h-96 overflow-y-auto">
                                { mediaFolders &&
                                    <NestedButton
                                        key="root"
                                        level={0}
                                        id="root"
                                        selectedID={selected?.id}
                                        fileType={fileType}
                                        mediaFolders={mediaFolders}
                                        moveToFolder={moveToFolder}
                                    />
                                }
                            </div>
                        }
                        className="!p-0"
                    >
                        <Button
                            variant="light"
                            disabled={!selected}
                        >
                            <FolderSymlink className="w-5 h-5 mr-2" /> Move to
                        </Button>
                    </Popover>

                    <Popover
                        variant="danger"
                        className="cursor-auto"
                        content={ <>
                            <p className="mb-4">
                                Are you sure you want to delete "{selected?.type === "folder" ? getFolderName(selected?.id) : getItemName(selected?.id!)}"? This can't be reversed!
                            </p>
                            <Button
                                variant="danger"
                                onClick={
                                    selected?.type === "item" ? () => deleteMedia(selected?.id) :
                                    () => {}
                                }
                            >
                                Delete {selected?.type}
                            </Button>
                        </> }
                    >
                        <Button
                            variant="light"
                            disabled={!selected || selected.type === "folder"}
                            className="hover:text-red-600"
                        >
                            <Trash className="w-5 h-5 mr-2" /> Delete
                        </Button>
                    </Popover>
                </>}
            </div>

            <hr className="bg-gray-50 mb-4" />

            <div className="w-full flex flex-row flex-wrap mb-8 gap-4">
                { mediaFoldersLoading ? <Loading /> : currentFolders?.map(folder => (
                    <button
                        key={folder.id}
                        className={`group w-60 py-4 flex flex-col items-center rounded-lg ${selected?.id === folder.id ? "bg-gray-21" : "hover:bg-gray-21"} cursor-pointer`}
                        onClick={() => { setSelected({ id: folder.id, type: "folder" }) }}
                        onDoubleClick={() => { goToFolder(folder.id) }}
                        >
                        <div className="relative w-[100px] h-[75px]">
                            <div className="absolute w-full h-[95%] bottom-0 rounded-lg bg-olive-200" />
                            <div className="absolute w-[30%] h-[100%] rounded-lg bg-olive-200" />
                            <div className="absolute w-full h-[80%] group-hover:h-[50%] bottom-0 rounded-lg bg-olive-100 border-t border-t-olive-10 border-b-2 border-b-olive-400 transition-[height] duration-75" />
                        </div>
                        <div className="mt-4">
                            { folder.name }
                        </div>
                    </button>
                )) }
            </div>

            <div className="w-full flex flex-row flex-wrap gap-4">
                { mediaFilesLoading || !mediaFiles ? 
                    <Loading />
                : <>
                    { mediaList.map((file, index) => (
                        <MediaCard
                            key={index}
                            location={location}
                            file={file}
                            fileType={fileType}
                            selectedID={selected?.id}
                            onPanelSelect={selectMedia}
                            onModalSelect={onSelect}
                        />
                    )) }
                </> }
            </div>

            { mediaList.length === 0 && currentFolders?.length === 0 && <p>Nothing yet!</p> }

            <Modal
                show={showRenameDialog}
                close={() => setShowRenameDialog(false)}
                className="max-w-xl pb-4"
            >
                <div className="flex justify-between mb-2">
                    <Dialog.Title as="h2" className="text-2xl font-medium leading-6 text-gray-900">
                        Rename {selected?.type}
                    </Dialog.Title>
                </div>

                <hr className="border-black mb-4" />

                <form
                    className="flex"
                    onSubmit={handleNameFormSubmit}
                >
                    <Input
                        init={nameInput}
                        onChange={handleNameFormUpdate}
                        onBlur={selected?.type === "folder" ? renameFolder : () => {}}
                        placeholder="Folder name"
                        className="grow mr-4"
                    />
                    <Button submit>
                        Save
                    </Button>
                </form>
            </Modal>
        </>
    );
};

export default MediaContent;




type NestedButtonProps = {
    level: number;
    id: string;
    selectedID?: string;
    fileType: MediaType;
    mediaFolders: MediaFolder[];
    moveToFolder: (destination: string) => void;
};

const NestedButton = ({ level, id, selectedID, fileType, mediaFolders, moveToFolder }: NestedButtonProps) => {
    const folder = mediaFolders.find(f => f.id === id);
    const children = mediaFolders.filter(f => f.parent === id).sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0);

    const inset = (level + 1) * 8;

    return (<>
        <Button
            variant="light"
            disabled={id === selectedID}
            className={`${inset} text-left`}
            style={{ paddingLeft: inset }}
            onClick={() => moveToFolder(id)}
        >
            { folder?.name ?? capFirstLetter(fileType) }
        </Button>

        { children.map(child => (
            <NestedButton
                key={child.id}
                level={level + 1}
                id={child.id}
                selectedID={selectedID}
                fileType={fileType}
                mediaFolders={mediaFolders}
                moveToFolder={moveToFolder}
            />
        )) }
    </>);
};
