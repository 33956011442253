import { useState, useEffect, FormEvent, ReactNode } from 'react';
import ProgramEditor from './ProgramEditor';
import Input from '../../utils/Input';
import Tooltip from '../../utils/Tooltip';
import Button from '../../utils/Button';
import TabButtonS from '../../utils/TabButtonS';
import TabSpace from '../../utils/TabSpace';
import { Program } from '../../types';
import firebase from '../../firebase';
import { getDatabase, ref, push, remove } from 'firebase/database';
import { useListVals } from 'react-firebase-hooks/database';
import { Files } from 'react-bootstrap-icons';
import { randomString } from '../../helpers';


const db = getDatabase(firebase);

const Programs = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [draftName, setDraftName] = useState("");

    const programsRef = ref(db, 'programs/');
	const [programs, programsLoading, programsError] = useListVals<Program>(programsRef, { keyField: "id" });

    useEffect(() => {
        if(programsError) console.error(programsError);
    }, [programsError]);


    const removeProgram = (programID: string) => {
		remove(ref(db, 'programs/' + programID));
	};


    const handleNameFormUpdate = (val: string) => {
        setDraftName(val);
    };

    const handleNewProgram = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        createProgram();
    };


    const createProgram = () => {
        if(draftName === "") return;

        const newProgram = {
            name: draftName,
            live: false,
            nodes: [{ nodeID: randomString(), storyID: "" }],
            description: "",
            banner: ""
        };

        push(programsRef, newProgram);
        setDraftName("");
        setSelectedIndex(programs?.length ?? 0);
    };

    const duplicateProgram = (planID: string) => {
        const program = programs!.find(p => p.id === planID)!;

        const newProgram = {
            ...program,
            name: `${program.name} (copy)`
        };

        push(programsRef, newProgram);
    };


    const handleTabSelect = (index: number) => {
        setSelectedIndex(index);
    };


    // Generate React nodes
    const navItems: ReactNode[] = [];
    const tabItems: ReactNode[] = [];

    programs?.forEach(program => {
        navItems.push(
            <TabButtonS
                key={`program-nav-${program.id}`}
                id={program.id}
                type="programme"
                title={program.name ?? "(empty programme name)"}
                secondaryTitle="Duplicate programme"
                SecondaryIcon={Files}
                handleSecondary={duplicateProgram}
                handleDelete={removeProgram}
            />
        );

        tabItems.push(
            <ProgramEditor
                key={`program-tab-${program.id}`}
                program={program}
            />
        );
    });


    const headerItem = (
        <div className="flex flex-row w-full mt-2">
            <form
                onSubmit={handleNewProgram}
                className="relative flex-grow mr-2"
            >
                <Input  
                    init={draftName}
                    onChange={handleNameFormUpdate}
                    onBlur={createProgram}
                    placeholder="Add programme"
                    className="w-full pl-4 pr-10"
                />
                <Tooltip content="Add programme" placement="top">
                    <Button submit
                        className="absolute w-9 h-9 top-[0.375rem] right-[0.375rem] pl-1 pr-1 py-1 !rounded-full"
                    >
                        →
                    </Button>
                </Tooltip>
            </form>
        </div>
    );


    return (
        <TabSpace
            name="programmes"
            navItems={navItems}
            tabItems={tabItems}
            headerItem={headerItem}
            loading={programsLoading}
            selectedIndex={selectedIndex}
            handleSelect={handleTabSelect}
        />
    );
};

export default Programs;
