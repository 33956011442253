import { Fragment, ReactNode } from "react";
import FillIcon from "./FillIcon";
import { Dialog, Transition } from "@headlessui/react";
import { XCircle, XCircleFill } from "react-bootstrap-icons";


type ModalProps = {
	show: boolean;
	children: ReactNode;
	className?: string;
	close: () => void;
};

const Modal = ({ show, children, className, close }: ModalProps) => {
	return (
		<Transition appear show={show} as={Fragment}>
			<Dialog as="div" className="relative z-[60]" onClose={close}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel className={`w-full transform overflow-hidden rounded-2xl bg-gray-10 p-4 pb-10 text-left align-middle shadow-xl transition-all ${className}`}>
							<button
								className="absolute top-4 right-4 group-icon p-0 border-0 bg-none"
								onClick={close}
							>
								<FillIcon Line={XCircle} Fill={XCircleFill} iconStyle="w-6 h-6 transition" fillStyle="fill-red-600 active:fill-red-700" />
							</button>
							
							{ children }
						</Dialog.Panel>
					</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default Modal;
