import { Fragment } from "react";
import { Listbox } from "@headlessui/react";
import { Check2, ChevronDown } from "react-bootstrap-icons";


export type SelectOptions = [string, string, boolean?][];


type OptionProps = {
    children: string;
    value: string;
    disabled?: boolean;
    accentColour?: string;
};

const Option = ({ children, value, disabled, accentColour }: OptionProps) => {
    return (
        <Listbox.Option
            value={value}
            as={Fragment}
        >
            {({ selected }) => (
                <button
                    className={`option relative w-full py-1 pl-8 pr-2 text-left truncate ${disabled ? "text-gray-500" : `cursor-pointer hover:bg-${accentColour ?? "olive"}-100 active:font-semibold`}  ${selected ? "font-semibold" : "font-normal"}`}
                    disabled={disabled}
                >
                    { children }
                    <Check2 className={`${selected ? "block" : "hidden"} absolute top-2 left-2`} />
                </button>
            )}
        </Listbox.Option>
    )
};



type SelectProps = {
    value: string;
    options: SelectOptions;
    size?: "sm" | "md";
    disabled?: boolean;
    className?: string;
    containerStyle?: string;
    listStyle?: string;
    accentColour?: string;
    baggage?: any;
    onChange: (value: string, baggage?: any) => void;
};

const Select = ({ value, options, size, disabled, className, containerStyle, listStyle, accentColour, baggage, onChange }: SelectProps) => {
    return (
        <div className={`relative w-full ${containerStyle}`}>
            <Listbox
                value={value}
                onChange={(val: string) => onChange(val, baggage)}
                disabled={disabled}
            >
                <Listbox.Button className={`select relative w-full ${size === "sm" ? "pl-2 pr-8 py-1" : "h-12 pl-4 pr-10 py-2"} text-left truncate ${disabled ? "bg-gray-50 cursor-not-allowed" : `bg-${accentColour ?? "olive"}-50 hover:bg-${accentColour ?? "olive"}-100`} transition rounded-md ${className}`}>
                    <span className={disabled ? "opacity-80" : ""}>{ options.filter(el => el[0] === value)[0]?.[1] ?? "Choose an option..." }</span>
                    <ChevronDown className={`absolute ${size === "sm" ? "top-2 right-2" : "top-4 right-4"}`} />
                </Listbox.Button>
                <Listbox.Options
                    className={`options nowheel absolute w-full min-w-[50%] mt-1 overflow-hidden border-2 border-${accentColour ?? "olive"}-100 bg-${accentColour ?? "olive"}-20 rounded-lg shadow z-50 ${listStyle}`}
                >
                    <div className="max-h-56 py-1 overflow-x-hidden overflow-y-auto">
                        { options.map(([optionValue, title, disabled], index) => (
                            <Option key={index} value={optionValue} disabled={disabled} accentColour={accentColour}>{ title }</Option>
                        ))}
                    </div>
                </Listbox.Options>
            </Listbox>
        </div>
    );
};

export default Select;
