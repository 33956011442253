import StorySettingsGeneral from './StorySettingsGeneral';
import StorySettingsQuests from './StorySettingsQuests';
import StorySettingsVersioning from './StorySettingsVersioning';
import StorySettingsAdvanced from './StorySettingsAdvanced';
import StorySettingsNotes from './StorySettingsNotes';
import { Story } from '../../../types';
import Offcanvas from '../../../utils/Offcanvas';
import TabButtonL from '../../../utils/TabButtonL';
import { Clipboard2Check, GearWideConnected, JournalBookmarkFill, Option, Tools } from 'react-bootstrap-icons';
import { Tab } from '@headlessui/react';


type StorySettingsProps = {
	show: boolean;
	story: Story;
	flowID: string;
	handleClose: () => void;
	setStory: (storyID: string, story: Story) => void;
	setFlowIDToDrafts: () => void;
};

const StorySettings = ({ show, story, flowID, handleClose, setStory, setFlowIDToDrafts }: StorySettingsProps) => (
	<Offcanvas
		show={show}
		title="Story settings"
		size="lg"
		close={handleClose}
	>
		<Tab.Group
			vertical
			as="div"
			className="flex"
		>
			<Tab.List className="self-start sticky top-[4.25rem] w-1/3 pt-4 pr-8 space-y-1 z-50">
				<TabButtonL
					title="General"
					Icon={GearWideConnected}
				/>
				<TabButtonL
					title="Quests"
					Icon={Clipboard2Check}
				/>
				<TabButtonL
					title="Versioning"
					Icon={Option}
				/>
				<TabButtonL
					title="Advanced"
					Icon={Tools}
				/>
				<TabButtonL
					title="Notes"
					Icon={JournalBookmarkFill}
				/>
			</Tab.List>

			<Tab.Panels className="w-2/3">
				<StorySettingsGeneral
					story={story}
					setStory={setStory}
				/>
				<StorySettingsQuests
					story={story}
					setStory={setStory}
				/>
				<StorySettingsVersioning
					story={story}
					flowID={flowID}
					setFlowIDToDrafts={setFlowIDToDrafts}
				/>
				<StorySettingsAdvanced
					story={story}
					setStory={setStory}
				/>
				<StorySettingsNotes
					story={story}
				/>
			</Tab.Panels>
		</Tab.Group>
	</Offcanvas>
);

export default StorySettings;
