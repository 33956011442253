import { useEffect, useState } from 'react';
import { Story, StoryNote } from '../../../types';
import Button from '../../../utils/Button';
import EditText from '../../../utils/EditText';
import Popover from '../../../utils/Popover';
import Select from '../../../utils/Select';
import Tooltip from '../../../utils/Tooltip';
import firebase from '../../../firebase';
import { getAuth } from 'firebase/auth';
import { get, getDatabase, push, ref, remove, set } from 'firebase/database';
import { useAuthState } from 'react-firebase-hooks/auth';
import { ArrowCounterclockwise, Files, PlusLg, RocketTakeoffFill, TrashFill } from 'react-bootstrap-icons';
import { Tab } from '@headlessui/react';


const auth = getAuth(firebase);
const db = getDatabase(firebase);

type StorySettingsVersioningProps = {
	story: Story;
	flowID: string;
	setFlowIDToDrafts: () => void;
};

const StorySettingsVersioning = ({ story, flowID, setFlowIDToDrafts }: StorySettingsVersioningProps) => {
	const [user, userLoading, userError] = useAuthState(auth);
	useEffect(() => {
		if (userError) console.error(userError);
	}, [userError]);


	const [versionToPush, setVersionToPush] = useState("");


	const createVersion = () => {
		push(ref(db, 'stories/' + story.id + '/flows'), `Version ${Object.keys(story.flows).length + 1}`);
	};

	const updateVersionName = (newName: string, id: string) => {
		if (Object.values(story.flows).includes(newName)) return;
		set(ref(db, 'stories/' + story.id + '/flows/' + id), newName);
	};

	const duplicateVersion = (id: string) => {
		const newKey = push(ref(db, 'stories/' + story.id + '/flows'), `${story.flows[id]} (copy)`).key!;

		get(ref(db, 'storyFlows/' + id))
			.then(flow => {
				set(ref(db, 'storyFlows/' + newKey), flow.val());
			})
			.catch(error => console.error(error));
	};

	const resetVersion = (id: string) => {
		const prodID = Object.keys(story.flows).find(id => story.flows[id] === "Production");
		if (!prodID) return;

		get(ref(db, 'storyFlows/' + prodID))
			.then(flow => {
				set(ref(db, 'storyFlows/' + id), flow.val());
			})
			.catch(error => console.error(error));
	};

	const deleteVersion = (id: string) => {
		if (flowID === id) setFlowIDToDrafts();
		createAutoNote(`Deleted version ${story.flows[id]}`);
		remove(ref(db, 'stories/' + story.id + '/flows/' + id));
		remove(ref(db, 'storyFlows/' + id));
	};

	const pushToProduction = () => {
		const prodID = Object.keys(story.flows).find(id => story.flows[id] === "Production");
		if (!prodID) return;

		get(ref(db, 'storyFlows/' + versionToPush))
			.then(flow => {
				set(ref(db, 'storyFlows/' + prodID), flow.val());
			})
			.catch(error => console.error(error));

		createAutoNote(`Pushed version ${story.flows[versionToPush]} to production`);
	};


	const createAutoNote = (text: string) => {
		const note: StoryNote = {
			author: "🤖 " + (userLoading || userError || !user?.displayName ? "Unknown author" : user.displayName),
			content: text,
			date: Date.now()
		};
		push(ref(db, 'stories/' + story.id + '/notes'), note);
	};


	return (
		<Tab.Panel>
			<h3 className="mt-4">Versions</h3>
			<Button
				variant="dark"
				className="mb-2"
				onClick={createVersion}
			>
				<PlusLg className="mr-2" /> Add blank version
			</Button>

			<p className="mb-4">Use the Reset button to set a version to be a copy of the current Production version.</p>

			<div>
				{ Object.entries(story.flows).map(([id, name]) => (
					<div
						key={id}
						className="flex items-center rounded-lg [&>*]:p-2 odd:bg-gray-21"
					>
						<div className="grow pr-0">
							<EditText
								name="version-name"
								init={name}
								readOnly={name === "Production" || name === "Draft"}
								restrictHeight
								className="w-[168px]"
								baggage={id}
								fn={(_, value, id) => { updateVersionName(value, id) }}
							/>
						</div>
						<div>
							<Button onClick={() => duplicateVersion(id)}>
								<Files className="mr-2" /> Duplicate
							</Button>
						</div>
						<div>
							<Tooltip content="Done!" trigger="click" placement="right">
								<Button
									disabled={name === "Production"}
									onClick={() => resetVersion(id)}
								>
									<ArrowCounterclockwise className="mr-2" /> Reset
								</Button>
							</Tooltip>
						</div>
						<div>
							<Popover
								variant="danger"
								className="cursor-auto"
								content={<>
									<p className="mb-4">
										Are you sure you want to delete "{ name }"? This can't be reversed!
									</p>
									<Button
										variant="danger"
										onClick={() => { deleteVersion(id) }}
									>
										Delete version
									</Button>
								</>}
							>
								<Button
									variant="danger"
									disabled={name === "Production" || name === "Draft"}
								>
									<TrashFill className="mr-2" /> Delete
								</Button>
							</Popover>
						</div>
					</div>
				))}
			</div>

			<h3>Manage production</h3>
			<p className="mb-2">Choose a version of the story to set as the production version. This is the version of the story that will be used when stories are downloaded from the Configuration page.</p>

			<div className="flex">
				<Select
					value={versionToPush}
					options={Object.entries(story.flows).filter(([_, name]) => name !== "Production").map(([id, name]) => [id, name])}
					onChange={val => setVersionToPush(val)}
				/>
				<Tooltip content="Done!" trigger="click" placement="top">
					<Button
						variant="dark"
						className="ml-4 shrink-0"
						onClick={pushToProduction}
					>
						<RocketTakeoffFill className="w-5 h-5 mr-2" /> Update production
					</Button>
				</Tooltip>
			</div>
		</Tab.Panel>
	);
};

export default StorySettingsVersioning;
