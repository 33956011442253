import Node from "./Node";
import { Bell } from "react-bootstrap-icons";


const NotifyPermNode = () => (
	<Node
		title="Ask for notification permissions"
		handleTop handleBottom={2}
		Icon={Bell}
	>
		<div className="mb-1 flex justify-evenly text-center">
			<span className="italic">Accepted</span>
			<span className="italic">Declined</span>
		</div>
	</Node>
);

export default NotifyPermNode;
