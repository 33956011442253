/// <reference lib="dom" />
/// <reference lib="dom.iterable" />
import { useState, Fragment } from 'react';
import Stories from './build/stories/Stories';
import Media from './build/media/Media';
import Programs from './build/programs/Programs';
import Quests from './build/quests/Quests';
// import Questlines from './build/questlines/Questlines';
import Config from './build/config/Config';
// import Users from './test/Users';
import Feedback from './test/Feedback';
import pkg from '../package.json';
import Button from './utils/Button';
import Loading from './utils/Loading';
import Popover from './utils/Popover';
import FillIcon from './utils/FillIcon';
import firebase from './firebase';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Tab } from '@headlessui/react';
import { Clipboard2Check, Clipboard2CheckFill, CollectionPlay, CollectionPlayFill, Compass, CompassFill, Diagram3, Diagram3Fill, DoorClosed, DoorOpen, EmojiFrown, Gear, GearFill, Google, Icon, List, PersonCircle, Reply, ReplyFill, Stars } from 'react-bootstrap-icons';


// Firebase initialisation
const auth = getAuth(firebase);
const provider = new GoogleAuthProvider();
provider.setCustomParameters({
	hd: "voxsio.com"
});
const signInWithGoogle = () => signInWithPopup(auth, provider);


const App = () => {
	const [user, userLoading, userError] = useAuthState(auth);
	const [menuExpanded, setMenuExpanded] = useState(false);

	if(!user) return (
		<div className="h-screen w-full pt-40 text-green-725 bg-gray-50 bg-[url('../public/assets/botany.png')] bg-no-repeat">	
			<div className="flex flex-col h-3/4 w-1/3 min-w-[500px] mx-auto px-16 bg-white shadow-2xl shadow-gray-500/40 rounded-lg text-center">
				<h1 className="py-12">
					<span className="font-logo text-5xl uppercase">
						Botany
					</span>&nbsp;
					<span className="text-5xl text-green-300 lowercase">
						Dashboard
					</span>
				</h1>

				{ userError &&
					<p className="text-red-500 mb-12">
						{ userError.toString() }
					</p>
				}

				{ userLoading ?
					<Loading />
					:
					<div>
						<Button onClick={signInWithGoogle}>
							<Google className="mr-3" />
							{ userError ? "Try again" : "Sign in with Google" }
						</Button>
					</div>
				}

				<div className="text-2xl justify-self-end mt-auto mb-16 mx-auto">
					{ userError ? <EmojiFrown /> : userLoading ? <DoorOpen /> : <DoorClosed /> }
				</div>
			</div>

			<p className="absolute w-fit bottom-12 mx-auto left-0 right-0 p-2 bg-gray-50 rounded">
				v{pkg.version} &copy; Voxsio
			</p>
		</div>
	);
	else return (
		<div className="flex h-screen w-full p-0 bg-gray-10">
			<Tab.Group manual vertical>
				<Tab.List className={`flex flex-col items-center ${menuExpanded ? "w-72" : "w-16"} h-full px-1 bg-green-600/5 rounded-r-xl overflow-x-hidden transition-all`}>
					<button
						className="group flex items-center w-full h-14 mt-6 border-2 border-transparent rounded-md hover:border-green-100 hover:bg-green-100 active:bg-green-50"
						onClick={() => { setMenuExpanded(!menuExpanded) }}
					>
						<div className="ml-[0.725rem]">
							<List className="w-7 h-7 fill-green-800 transition-all group-hover:fill-green-900 group-active:scale-x-50" />
						</div>
						<h1 className={`ml-4 pt-1 font-logo text-4xl uppercase ${menuExpanded ? "inline-block" : "hidden"}`}>Botany</h1>
					</button>

					<MenuHeader title="Build" />
					<MenuLink name="Stories" IconLine={Diagram3} IconFilled={Diagram3Fill} />
					<MenuLink name="Media" IconLine={CollectionPlay} IconFilled={CollectionPlayFill} />
					<MenuLink name="Programmes" IconLine={Compass} IconFilled={CompassFill} />
					<MenuLink name="Quests" IconLine={Clipboard2Check} IconFilled={Clipboard2CheckFill} />
					{/* <MenuLink name="Questlines (old)" IconLine={Trophy} IconFilled={TrophyFill} /> */}
					<MenuLink name="Configuration" IconLine={Gear} IconFilled={GearFill} />

					<MenuHeader title="Monitor" />
					{/* <MenuLink name="Users" IconLine={People} IconFilled={PeopleFill} /> */}
					<MenuLink name="Feedback" IconLine={Reply} IconFilled={ReplyFill} />

					<Popover
						placement="top"
						content={
							<div className="min-w-[250px] h-full">
								<h2 className="text-3xl font-medium">{user.displayName}</h2>
								<p>{user.email}</p>
								
								{ process.env.REACT_APP_DATABASE_LOCATION === "dev-only" &&
									<p className="mt-2">Using the mock database!</p>
								}

								<p className="my-5 p-3 bg-gray-100 rounded-lg">
									<Stars className="inline mr-3" />
									Botany Dashboard v{pkg.version}
								</p>
								<Button className="" onClick={() => { auth.signOut() }}>
									Log out
								</Button>
							</div>
						}
						className="p-5"
					>
						<button
							className="group flex items-center w-full h-14 mt-auto mb-8 border-2 border-transparent rounded-md hover:border-green-100 hover:bg-green-100 active:bg-green-50"
						>
							<span className="w-10 h-10 ml-1">
								{ user.photoURL ?
									<img
										className="rounded-full"
										src={user.photoURL}
										alt="Your account"
										onError={({ currentTarget }) => {
											currentTarget.onerror = null;
											currentTarget.src = "/assets/person-circle.svg";
											currentTarget.className = "w-6 h-6 mt-2 ml-2 fill-green-800 group-hover:fill-green-900";
										}}
									/>
								:
									<PersonCircle className="w-6 h-6 mt-2 fill-green-800 group-hover:fill-green-900" />
								}
							</span>
							<span
								className={`ml-2 font-medium text-green-800 group-hover:text-green-900 ${menuExpanded ? "inline" : "hidden"}`}
							>
								Account
							</span>
						</button>
					</Popover>
				</Tab.List>

				<Tab.Panels className={`h-full w-full overflow-hidden transition-all`}>
					<Stories />
					<Media />
					<Programs />
					<Quests />
					{/* <Questlines /> */}
					<Config />
					{/* <Users /> */}
					<Feedback />
				</Tab.Panels>
			</Tab.Group>
		</div>
	);
};

export default App;



// UI components
type MenuHeaderProps = {
	title: string;
};

const MenuHeader = ({ title }: MenuHeaderProps) => {
	return (
		<div className="flex items-center w-full h-4 mt-6 ml-[7.5rem] transition-all">
			<h4 className="text-[0.75rem] text-brown-600 font-bold uppercase">{title}</h4>
		</div>
	);
};




type MenuLinkProps = {
	name: string;
	IconLine: Icon;
	IconFilled: Icon;
};

const MenuLink = ({ name, IconLine, IconFilled }: MenuLinkProps) => {
	return (
		<Tab as={Fragment}>
			{({ selected }) => (
				<button
					className={`group-icon flex items-center w-full h-14 mt-4 p-[0.4rem] border-2 border-transparent rounded-md
						hover:border-green-100 hover:bg-green-100 active:bg-green-50 ${selected && "border-green-100 bg-green-100"}
					`}
				>
					<FillIcon
						Line={IconLine}
						Fill={IconFilled}
						groupStyle="ml-[0.375rem] mr-3"
						iconStyle={`w-6 h-6 ${selected ? "fill-green-900" : "fill-green-800 group-icon-hover:fill-green-900"}`}
						alwaysShowFill={selected}
					/>
					<span
						className={`ml-2 font-medium ${selected ? "text-green-900" : "text-green-800"} group-icon-hover:text-green-900`}
					>
						{ name }
					</span>
				</button>
			)}
		</Tab>
	);
};
