import { ReactNode } from "react";
import { Icon } from "react-bootstrap-icons";


type EffectContainerProps = {
	children: ReactNode;
	title: string;
	Icon: Icon;
};

const EffectContainer = ({ children, title, Icon }: EffectContainerProps) => {
	return (
		<div className="flex items-center m-1 text-white">
			<div className="grow shrink-0 pl-1 mr-2">
				<Icon className="mr-1" />&nbsp;
				{ title }
			</div>
			{ children }
		</div>
	);
};

export default EffectContainer;
