import Node from "./Node";
import { NodeDataProps } from './customNodes';
import Select, { SelectOptions } from '../../../utils/Select';
import { DEPRECATED_Questline } from '../../../types';
import firebase from '../../../firebase';
import { getDatabase, orderByChild, query, ref, set } from 'firebase/database';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';
import { Trophy } from 'react-bootstrap-icons';


const db = getDatabase(firebase);

const PickQuestNode = ({ data }: NodeDataProps) => {
	const questlinesRef = ref(db, 'questlines/');
	const [questlines, questlinesLoading, questlinesError] = useListVals<DEPRECATED_Questline>(query(questlinesRef, orderByChild("name")), { keyField: "id" });

	const contentRef = ref(db, 'storyFlows/' + data.flowID + '/' + data.nodeID + '/content');
	const [content, contentLoading, contentError] = useObjectVal<string[]>(contentRef);

	const handleSelectUpdate0 = (value: string) => {
		set(contentRef, [value, content![1], content![2]]);
	};
	const handleSelectUpdate1 = (value: string) => {
		set(contentRef, [content![0], value, content![2]]);
	};
	const handleSelectUpdate2 = (value: string) => {
		set(contentRef, [content![0], content![1], value]);
	};

	const options: SelectOptions = questlines?.map(q => [q.id, q.name]) ?? [];


	return (
		<Node
			title="Quest picker (deprecated)"
			handleTop
			handleBottom={1}
			Icon={Trophy}
		>
			<div className="nodrag">
				{ contentError ?
					<p>Error: {contentError.toString()}</p>
				: questlinesError ?
					<p>Error: {questlinesError.toString()}</p>
				: contentLoading || questlinesLoading || !content || !questlines ?
					<p>Loading...</p>
				:
					<div className="relative w-full p-1">
						<Select
							value={content[0] ?? ""}
							options={options}
							size="sm"
							onChange={handleSelectUpdate0}
						/>
						<Select
							value={content[1] ?? ""}
							options={options}
							size="sm"
							onChange={handleSelectUpdate1}
							className="mt-1"
						/>
						<Select
							value={content[2] ?? ""}
							options={options}
							size="sm"
							onChange={handleSelectUpdate2}
							className="mt-1"
						/>
					</div>
				}
			</div>
		</Node>
	);
};

export default PickQuestNode;
