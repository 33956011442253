import { CondOp, CondTrait, EdgeData } from '../../types';
import { capFirstLetter } from '../../helpers';
import StoryName from '../../utils/StoryName';
import { EdgeLabelRenderer, getBezierPath, Position } from 'reactflow';


type ConditionalEdgeProps = {
	style?: React.CSSProperties;
	data?: EdgeData;
	sourceX: number;
	sourceY: number;
	sourcePosition: Position;
	targetX: number;
	targetY: number;
	targetPosition: Position;
};

const ConditionalEdge = ({ style, data, sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition }: ConditionalEdgeProps) => {
	const [path, labelX, labelY] = getBezierPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });

	return (<>
		{/* Visible connector path */}
		<path
			d={path}
			className={"stroke-2 transition-[stroke-width] ease-[cubic-bezier(.25,.8,.25,1)] duration-200 react-flow__edge-path"}
			style={style}
		/>

		{/* Invisible border for easier clicking */}
		<path
			d={path}
			fill="none"
			strokeOpacity={0}
			strokeWidth={25}
		/>

		{/* Condition label */}
		{ data?.condition && data.condition.trait && data!.condition.trait !== "none" &&
			<EdgeLabelRenderer>
				<div
					className="absolute max-w-[200px] py-1 px-2 bg-brown-100 rounded-md text-center font-mono text-sm text-brown-800"
					style={{
						transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`
					}}
				>
					{ capFirstLetter(data.condition.trait) }
					{
						data!.condition!.op === CondOp.True ? " enabled" :
						data!.condition!.op === CondOp.False ? " disabled" :
						data!.condition!.op === CondOp.Empty ? " not set" :
						data!.condition!.op === CondOp.Equals ? " = " :
						data!.condition!.op === CondOp.NotEquals ? " ≠ " :
						data!.condition!.op === CondOp.Includes ? " incl. " :
						data!.condition!.op === CondOp.NotIncludes ? " excl. " :
						" ? "
					}
					{
						data!.condition!.op === CondOp.True ? "" :
						data!.condition!.op === CondOp.False ? "" :
						data!.condition!.op === CondOp.Empty ? "" :
						data.condition.value === "" ? "?" :
						data.condition.trait === CondTrait.History || data.condition.trait === CondTrait.Program ? <StoryName id={data.condition.value} /> :
						data.condition.value
					}
				</div>
			</EdgeLabelRenderer>
		}
	</>);
};

export default ConditionalEdge;
