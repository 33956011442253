import { ChangeEvent } from "react";


type FileInputProps = {
    accept?: string;
    className?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const FileInput = ({ accept, className, onChange }: FileInputProps) => {
    return (
        <input
            type="file"
            accept={accept}
            className={`px-2 pt-1 pb-[5px] mr-4 bg-olive-50 text-olive-900 rounded-md cursor-pointer
                shadow-olive-100 shadow-[inset_0_-2px] active:shadow-none active:translate-y-[2px]
                file:h-[100%] file:mr-4 file:p-2 file:bg-green-100 file:border-0
                file:rounded-md file:cursor-pointer file:ease-out file:duration-[50ms]
                hover:brightness-95 ${className}
            `}
            onChange={onChange}
        />
    );
};

export default FileInput;
