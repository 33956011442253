import { Fragment } from "react";
import { Tab } from "@headlessui/react";
import { Icon } from "react-bootstrap-icons";


type TabButtonLProps = {
    title: string,
    Icon: Icon
};

const TabButtonL = ({ title, Icon }: TabButtonLProps) => {
    return (
        <Tab as={Fragment}>
            {({ selected }) => (
                <button className={`block w-full p-4 text-left rounded-l-lg rounded-r-[2rem] transition-all hover:bg-gray-50 ${
                    selected ? "bg-gray-50 font-bold cursor-default" : ""
                }`}>
                    <Icon className="w-5 h-5 mr-4" />
                    { title }
                </button>
            )}
        </Tab>
    );
};

export default TabButtonL;
