import { ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useState } from "react";


type InputProps = {
    init: string;
    placeholder?: string;
    required?: boolean;
    className?: string;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
};

const Input = ({init, placeholder, required, className, onChange, onBlur}: InputProps) => {
    const [value, setValue] = useState(init);

    useEffect(() => {
        setValue(init);
    }, [init]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        setValue(val);
        if(onChange) onChange(val);
    };

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        if(!onBlur) return;
        event.preventDefault();
        onBlur(event.target.value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if(event.key === "Enter") (event.target as HTMLInputElement).blur();
    };

    return (
        <input
            type="text"
            value={value}
            placeholder={placeholder}
            required={required}
            className={`h-12 px-4 py-2 bg-olive-50 hover:bg-olive-100 focus:bg-olive-100 text-olive-900 rounded-md placeholder:text-olive-600 transition ${className}`}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
        />
    );
};

export default Input;
