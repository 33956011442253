import { ReactNode, useEffect, useRef, useState } from 'react';
import Loading from './Loading';
import { Tab } from '@headlessui/react';


type TabSpaceProps = {
	name: string,
	navItems: ReactNode[] | ReactNode,
	tabItems: ReactNode[],
	headerItem?: ReactNode,
	loading?: boolean,
	selectedIndex: number,
	handleSelect?: (index: number) => void
};

const TabSpace = ({ name, navItems, tabItems, headerItem, loading, selectedIndex, handleSelect }: TabSpaceProps) => {
	const [headerHeight, setHeaderHeight] = useState(0);

	const header = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!header.current) return;
		const height = header.current.clientHeight;
		setHeaderHeight(height);
	}, []);

	return (
		<Tab.Panel className="w-full h-full flex focus-visible:outline-none">
			<Tab.Group
				manual vertical
				selectedIndex={selectedIndex}
				onChange={handleSelect}
			>
				<div
					className={`flex flex-col h-[calc(100%-0.5rem)] w-1/3 lg:w-1/4 2xl:w-1/5 max-w-[400px] m-2 ml-1 pt-6 px-3`}
				>
					<div ref={header}>
						<h1 className="text-4xl font-semibold text-brown-600 capitalize">{name}</h1>
						{ headerItem }
					</div>

					<Tab.List
						className="flex-grow w-full mt-2 pr-2 space-y-1 overflow-y-auto"
						style={{ paddingBottom: headerHeight + 36 }}
					>
						{ loading ? <Loading /> : navItems }
					</Tab.List>
				</div>

				<Tab.Panels className="h-full w-2/3 lg:w-3/4 2xl:w-4/5 p-6 pb-24 float-right overflow-y-auto">
					{ tabItems }
				</Tab.Panels>
			</Tab.Group>
		</Tab.Panel>
	);
};

export default TabSpace;
