import { Icon } from "react-bootstrap-icons";


type FillIconProps = {
    Line: Icon,
    Fill: Icon,
    groupStyle?: string,
    iconStyle?: string,
    fillStyle?: string,
    alwaysShowFill?: boolean
};

const FillIcon = ({ Line, Fill, groupStyle, iconStyle, fillStyle, alwaysShowFill }: FillIconProps) => {
    return (
        <span className={`hover:cursor-pointer ${groupStyle}`}>
            <Line className={`group-icon-hover:hidden ${iconStyle} ${alwaysShowFill ? "hidden" : ""}`} />
            <Fill className={`group-icon-hover:inline ${iconStyle} ${fillStyle} ${alwaysShowFill ? "inline" : "hidden"}`} />
        </span>
    );
};

export default FillIcon;