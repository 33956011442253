import { EdgeData, EdgeType, FlowSet, MsgType, NodeData, StoryFlowMsg } from "../../types";
import { randomString } from "../../helpers";
import { Node, Edge, getConnectedEdges } from "reactflow";


export const storyFlowSavedToEditor = (savedFlow: Record<string, StoryFlowMsg>, flowID: string) => {
	const nodes: Node[] = [];
	const edges: Edge[] = [];

	Object.entries(savedFlow).forEach(([msgID, msg]) => {
		const nodeData: NodeData = {
			nodeID: msgID,
			flowID: flowID,
			label: msg.type === MsgType.Output ? "Finish" : msg.type === MsgType.Input ? msgID === "start" ? "Start" : "Start (copied)" : undefined
		};

		const node: Node<NodeData> = {
			id: msgID,
			type: msg.type,
			data: nodeData,
			position: msg.position
		};
		nodes.push(node);

		msg.next?.forEach((next, nextIndex) => {
			const edgeData: EdgeData = {
				nextIndex: nextIndex,
				condition: next.condition ?? undefined
			};

			const edge: Edge<EdgeData> = {
				id: randomString(),
				type: EdgeType.CondEdge,
				source: msgID,
				target: next.id,
				data: edgeData
			};

			edges.push(edge);
		});

		msg.nextLeft?.forEach((next, nextIndex) => {
			const edgeData: EdgeData = {
				nextIndex: nextIndex,
				condition: next.condition ?? undefined
			};

			const edge: Edge<EdgeData> = {
				id: randomString(),
				type: EdgeType.CondEdge,
				source: msgID,
				sourceHandle: "left",
				target: next.id,
				data: edgeData
			};

			edges.push(edge);
		});

		msg.nextRight?.forEach((next, nextIndex) => {
			const edgeData: EdgeData = {
				nextIndex: nextIndex,
				condition: next.condition ?? undefined
			};

			const edge: Edge<EdgeData> = {
				id: randomString(),
				type: EdgeType.CondEdge,
				source: msgID,
				sourceHandle: "right",
				target: next.id,
				data: edgeData
			};

			edges.push(edge);
		});
	});

	return {
		nodes: nodes,
		edges: edges
	};
};



//// Flow helpers
// From a flow selection, get only the edges that are inside the selection itself
export const getSelectedFlow = (selectedNodes: Node[], allNodes: Node[], allEdges: Edge[]) => {
	const allNodeMap: Record<string, Node> = {};

	allNodes.forEach((node) => {
		allNodeMap[node.id] = node;
	});

	const connectedEdgeMap: Record<string, Edge> = {};
	const selectedNodeIDs: string[] = [];
	const selectedFlow: FlowSet = {
		nodes: [],
		edges: []
	};

	selectedNodes.forEach((node) => {
		selectedNodeIDs.push(node.id);

		const connectedEdges = getConnectedEdges([node], allEdges);
		connectedEdges.forEach((edge) => (connectedEdgeMap[edge.id] = edge));

		selectedFlow.nodes.push(allNodeMap[node.id]);
	});

	// Find edges which have both nodes present in selectedNodes
	Object.values(connectedEdgeMap).forEach((edge) => {
		if (selectedNodeIDs.includes(edge.source) && selectedNodeIDs.includes(edge.target)) {
			selectedFlow.edges.push(edge);
		}
	});

	return selectedFlow;
};
