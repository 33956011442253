import { useEffect } from "react";
import { MediaLocation, MediaType, Program, ProgramNode, Story } from "../../types";
import { colourDict } from "../../helpers";
import Button from "../../utils/Button";
import EditText from "../../utils/EditText";
import FillIcon from "../../utils/FillIcon";
import Loading from "../../utils/Loading";
import MediaPreview from "../../utils/MediaPreview";
import Select, { SelectOptions } from "../../utils/Select";
import Tooltip from "../../utils/Tooltip";
import firebase from "../../firebase";
import { getDatabase, orderByChild, query, ref, set } from "firebase/database";
import { useListVals } from "react-firebase-hooks/database";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { Tab } from "@headlessui/react";
import { GripVertical, PlusLg, Trash, TrashFill } from "react-bootstrap-icons";
import { randomString, reorderList } from "../../helpers";


const db = getDatabase(firebase);

type ProgramEditorProps = {
    program: Program;
};

const ProgramEditor = ({ program }: ProgramEditorProps) => {
    const updateProgram = (field: string, value: string) => {
        set(ref(db, "programs/" + program.id + "/" + field), value);
    };

    const addNode = () => {
        const newNodes: ProgramNode[] = [...program.nodes, { nodeID: randomString(), storyID: "" }];
        set(ref(db, "programs/" + program.id + "/nodes"), newNodes);
    };

    const addPlaceholder = () => {
        const newNodes: ProgramNode[] = [...program.nodes, { nodeID: randomString(), storyID: "placeholder" }];
        set(ref(db, "programs/" + program.id + "/nodes"), newNodes);
    };

    const onDragEnd = (result: DropResult) => {
        if(!result.destination) return;
        if(result.destination.index === result.source.index) return;

        const newStories = reorderList([...program.nodes], result.source.index, result.destination.index);
        set(ref(db, "programs/" + program.id + "/nodes"), newStories);
    };


    return (
        <Tab.Panel className="pb-60">
            <div className="w-fit mt-2 -ml-2 mb-2 px-1 font-bold text-2xl">
                <EditText
                    key={program.id}
                    name="name"
                    init={program.name}
                    fn={updateProgram}
                />
            </div>

            <div className="max-w-[800px] mb-4 p-4 border border-gray-200 bg-gray-20 rounded-lg">
                <h4 className="font-semibold">Configuration</h4>

                <p className="mt-2">Description</p>
                <EditText
                    key={program.id}
                    name="description"
                    init={program.description ?? ""}
                    className="flex-grow !p-4 !bg-olive-50 hover:!bg-olive-100 focus:!bg-olive-100 text-olive-900"
                    fn={updateProgram}
                />

                <p className="mt-4">Banner</p>
                <div className="flex mt-4">
                    <div className="relative w-1/3 mr-6 rounded-lg overflow-hidden">
                        <MediaPreview
                            location={MediaLocation.Local}
                            type={MediaType.Image}
                            src={program.banner ?? ""}
                            className={`border-4 ${colourDict.find(cD => cD.colour === program.bannerColour)?.border ?? "border-black"} ${colourDict.find(cD => cD.colour === program.bannerColour)?.bg ?? "bg-black"}`}
                            changeMedia={name => updateProgram("banner", name)}
                        />
                        <p
                            className={`absolute bottom-0 w-fit max-w-[90%] px-4 py-2 font-nublack text-xl leading-snug text-white rounded-tr-lg ${colourDict.find(cD => cD.colour === program.bannerColour)?.bg ?? "bg-black"}`}
                        >
                            { program.name }
                        </p>
                    </div>

                    <div className="self-start grid grid-cols-3 gap-2">
                        { colourDict.map(cD => (
                            <button
                                key={cD.colour}
                                className={`w-10 h-10 rounded-full flex items-center justify-center border-8 ${cD.border} ${program.bannerColour === cD.colour ? "bg-gray-10" : cD.bg}`}
                                onClick={() => { updateProgram("bannerColour", cD.colour) }}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="programsList">
                    { provided =>
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            { program.nodes.map((node, index) => (
                                <ProgramItem
                                    key={node.nodeID}
                                    node={node}
                                    index={index}
                                    program={program}
                                />
                            )) }
                            { provided.placeholder }
                        </div>
                    }
                </Droppable>
            </DragDropContext>

            <Button variant="dark" onClick={addNode}>
                <PlusLg className="mr-2" />
                Add story
            </Button>

            <Button variant="primary" className="ml-2" onClick={addPlaceholder}>
                <PlusLg className="mr-2" />
                Add placeholder
            </Button>
        </Tab.Panel>
    );
};

export default ProgramEditor;



type ProgramItemProps = {
    node: ProgramNode;
    index: number;
    program: Program;
};

const ProgramItem = ({ node, index, program }: ProgramItemProps) => {
    // Get the list of stories to choose from
    const storiesRef = ref(db, 'stories/');
	const [stories, storiesLoading, storiesError] = useListVals<Story>(query(storiesRef, orderByChild("name")), { keyField: "id" });

    useEffect(() => {
        if(storiesError) console.error(storiesError);
    }, [storiesError]);

    const liveStories = stories?.filter(s => s.live) ?? [];
    const storyOptions: SelectOptions = [["none", "None"]];
    liveStories.forEach(s => storyOptions.push([s.id, s.name]));

    // Handler methods
    const updateNode = (newStoryID: string) => {
        const nodes = [...program.nodes];
        nodes[index].storyID = newStoryID;
        set(ref(db, "programs/" + program.id + "/nodes"), nodes);
    };

    const removeNode = () => {
        const nodes = [...program.nodes];
        nodes.splice(index, 1);
        set(ref(db, "programs/" + program.id + "/nodes"), nodes);
    };

    return (
        <Draggable
            draggableId={node.nodeID}
            index={index}
        >
            { provided =>
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className="group relative max-w-[800px] min-h-[72px] flex items-center mb-4 px-2 py-4 border border-gray-200 bg-gray-20 rounded-lg"
                >
                    <div {...provided.dragHandleProps} className="group-sec self-stretch flex items-center mr-2" >
                        <GripVertical className="group-sec-hover:text-green-700 h-6 w-6" />
                    </div>

                    <div>
                        <h4 className="font-semibold mb-2">
                            Story {index + 1} { node.storyID === "placeholder" && "- Activity placeholder" }
                        </h4>

                        { node.storyID === "placeholder" ?
                            <p>
                                <em>This is replaced using an "Add to Programme" node in the story previous or skipped otherwise</em>
                            </p>
                        :
                            <div className="w-96">
                                { storiesLoading ? <Loading /> :
                                    <Select
                                        value={node.storyID}
                                        options={storyOptions}
                                        containerStyle="inline-block"
                                        onChange={updateNode}
                                    />
                                }
                            </div>
                        }

                        { program.nodes.length > 1 &&
                            <Tooltip content="Delete step">
                                <button
                                    className={`group-icon hidden group-hover:block absolute h-8 w-8 top-0 right-0 px-[0.375rem] rounded-md cursor-pointer hover:bg-red-500`}
                                    onClick={removeNode}
                                >
                                    <FillIcon
                                        Line={Trash}
                                        Fill={TrashFill}
                                        iconStyle="w-5 h-5 pt-px fill-red-500 group-icon-hover:fill-white"
                                    />
                                </button>
                            </Tooltip>
                        }
                    </div>
                </div>
            }
        </Draggable>
    );
};
