import { ReactNode, useState } from 'react';
import MediaContent from './MediaContent';
import { MediaType } from '../../types';
import TabSpace from '../../utils/TabSpace';
import TabButtonL from '../../utils/TabButtonL';
import { Tab } from '@headlessui/react';
import { AppIndicator, Film, Hypnotize, Icon, Image, Mic } from 'react-bootstrap-icons';


const Media = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleSelect = (index: number) => {
        setSelectedIndex(index);
    };


    const navItems: ReactNode[] = ([
        ["Images", Image],
        ["Icons", AppIndicator],
        ["Animation", Hypnotize],
        ["Video", Film],
        ["Audio", Mic]
    ] as [string, Icon][]).map(([title, Icon], index) => (
        <TabButtonL
            key={index}
            title={title}
            Icon={Icon}
        />
    ));


    const tabItems: ReactNode[] = ([
        [MediaType.Image, "image/*"],
        [MediaType.Icon, ".png"],
        [MediaType.Animation, ".json"],
        [MediaType.Video, "video/*"],
        [MediaType.Audio, "audio/*"]
    ]).map(([fileType, mime], index) => (
        <Tab.Panel
            key={index}
        >
            <MediaContent
                location="panel"
                fileType={fileType as MediaType}
                mime={mime}
            />
        </Tab.Panel>
    ));


    return (
        <TabSpace
            name="media"
            navItems={navItems}
            tabItems={tabItems}
            selectedIndex={selectedIndex}
            handleSelect={handleSelect}
        />
    );
};

export default Media;
