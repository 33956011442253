import { ChangeEvent, KeyboardEvent } from 'react';
import Node from "./Node";
import { NodeDataProps } from './customNodes';
import firebase from '../../../firebase';
import { getDatabase, ref, set } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';
import { Sliders } from 'react-bootstrap-icons';


const db = getDatabase(firebase);

const UserSliderNode = ({ data }: NodeDataProps) => {
	// Get the node's content
	const minRef = ref(db, 'storyFlows/' + data.flowID + '/' + data.nodeID + '/content/min');
	const [minVal, minLoading, minError] = useObjectVal<number>(minRef);
	const min = minVal ?? ""; // Make sure the input val is not null as React doesn't like that

	const maxRef = ref(db, 'storyFlows/' + data.flowID + '/' + data.nodeID + '/content/max');
	const [maxVal, maxLoading, maxError] = useObjectVal<number>(maxRef);
	const max = maxVal ?? "";

	// Handle input
	const handleInputUpdate = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.name === "min") set(minRef, event.target.value)
		else set(maxRef, event.target.value);
	};

	// Lose focus when Enter is pressed
	const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') (event.target as HTMLInputElement).blur();
	};

	// Render the node
	return (
		<Node
			title="Slider"
			handleTop handleBottom={1}
			Icon={Sliders}
		>
			{ minLoading || maxLoading ?
				<p>Loading...</p>
			: minError || maxError ?
				<p>Error: {minError?.toString()} {maxError?.toString()}</p>
			:
				<div className="relative w-full flex p-1">
					<p className="text-white mr-2">From</p>
					<input
						type="number"
						name="min"
						value={min}
						onChange={handleInputUpdate}
						onKeyDown={handleKeyDown}
						className="w-[40%] pl-1 rounded"
					/>
					<p className="text-white mx-2">to</p>
					<input
						type="number"
						name="max"
						value={max}
						onChange={handleInputUpdate}
						onKeyDown={handleKeyDown}
						className="w-[40%] pl-1 rounded"
					/>
				</div>
			}
		</Node>
	);
};

export default UserSliderNode;
