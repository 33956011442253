import { useEffect } from 'react';
import { CondOp, CondTrait, MsgType, Story, StoryFlowMsg } from '../../types';
import Button from '../../utils/Button';
import Loading from '../../utils/Loading';
import OutsideListener from '../../utils/OutsideListener';
import Select, { SelectOptions } from '../../utils/Select';
import firebase from '../../firebase';
import { getDatabase, orderByChild, query, ref, set } from 'firebase/database';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';
import { Transition } from '@headlessui/react';
import { TrashFill } from 'react-bootstrap-icons';


const db = getDatabase(firebase);

type EdgeSettingsProps = {
	show: boolean;
	id: string;
	flowID: string;
	sourceID: string;
	targetID: string;
	clickPos: {
		x: number;
		y: number;
	};
	nextIndex: number;
	close: () => void;
	removeEdge: (edgeID: string) => void;
};

const EdgeSettings = ({ show, id, flowID, sourceID, targetID, clickPos, nextIndex, close, removeEdge }: EdgeSettingsProps) => {
	const sourcePath = "storyFlows/" + flowID + "/" + sourceID;
	const conditionPath = sourcePath + "/next/" + nextIndex + "/condition";

	const sourceRef = ref(db, sourcePath);
	const [source, sourceLoading, sourceError] = useObjectVal<StoryFlowMsg>(sourceRef);

	useEffect(() => {
		if (!sourceError) return;
		console.error(sourceError);
	}, [sourceError]);

	const trait = source?.next?.[nextIndex]?.condition?.trait;
	const op = source?.next?.[nextIndex]?.condition?.op;
	const value = source?.next?.[nextIndex]?.condition?.value;


	const storiesRef = ref(db, "stories/");
	const [stories, storiesLoading, storiesError] = useListVals<Story>(query(storiesRef, orderByChild("name")), { keyField: "id" });
	const storyOptions = stories?.filter(story => story.live).map(s => [s.id, s.name]);


	const updateConditionTrait = (newTrait: string) => {
		const newCondition = {
			trait: newTrait,
			op: newTrait === CondTrait.None ? CondOp.None : "",
			value: newTrait === CondTrait.None ? CondOp.None : ""
		};
		set(ref(db, conditionPath), newCondition);
	};

	const updateConditionOp = (newOp: string) => {
		set(ref(db, conditionPath + "/op"), newOp);
		if (trait === CondTrait.Checklist || newOp === CondOp.True || newOp === CondOp.False || newOp === CondOp.Empty) set(ref(db, conditionPath + "/value"), "");
	};

	const updateConditionValue = (newValue: string) => {
		set(ref(db, conditionPath + "/value"), newValue);
	};


	const traitOptions: SelectOptions = [
		[CondTrait.None, "No condition"],
		[CondTrait.History, "Stories completed by user"],
		[CondTrait.Program, "Stories in user's programme"],
		[CondTrait.Goal, "User's goal"],
		[CondTrait.PhysicalSyms, "User's physical symptoms"],
		[CondTrait.HeardOfFuncSyms, "User had heard of functional symptoms"],
		[CondTrait.PhysicalSymsWorrying, "User's worrying about symptoms"],
		[CondTrait.StoppedActivities, "User's stopped activities"],
		[CondTrait.ExerciseLevel, "User's exercise levels"],
		[CondTrait.LowMood, "User's experience of low mood"],
		[CondTrait.EnergyLevel, "User's energy levels"],
		[CondTrait.SleepTrouble, "User's sleep troubles"],
		[CondTrait.DevMode, "Development mode"]
	];
	if (source?.type === MsgType.UserList) traitOptions.splice(1, 0, [CondTrait.Checklist, "Checklist selection"]);


	return (
		<OutsideListener fn={close}>
			<Transition
				show={show}
				appear
				enter="ease-out duration-300"
				enterFrom="opacity-0 scale-95"
				enterTo="opacity-100 scale-100"
				leave="ease-in duration-200"
				leaveFrom="opacity-100 scale-100"
				leaveTo="opacity-0 scale-95"
				className={`${show ? "visible" : "hidden"} absolute p-4 bg-gray-10 border-2 border-brown-600 shadow-lg rounded-xl z-40`}
				style={{ top: Math.max(clickPos.y - 280, 5), left: clickPos.x + 5 }}
			>
				<h2 className="text-lg font-semibold">Connector</h2>
				<p className="text-gray-500 mb-4">
					Connector ID: {id}<br />
					Source node: {sourceID}<br />
					Target node: {targetID}
				</p>

				{ sourceLoading || !source ?
					<Loading />
				:
					<div className="items-center mb-4">
						<p className="mb-2">Only follow this connector if...</p>
						<Select
							value={trait ?? "none"}
							options={traitOptions}
							size="sm"
							onChange={updateConditionTrait}
						/>
						<Select
							value={op ?? ""}
							options={
								trait === CondTrait.DevMode ? [
									[CondOp.True, "is enabled"],
									[CondOp.False, "is disabled"]
								] :
								trait === CondTrait.Checklist ? [
									[CondOp.Equals, "equals"],
									[CondOp.NotEquals, "does not equal"],
									[CondOp.Includes, "includes"],
									[CondOp.NotIncludes, "does not include"]
								] :
								trait === CondTrait.History || trait === CondTrait.Program || trait === CondTrait.PhysicalSyms ? [
									[CondOp.Includes, "includes"],
									[CondOp.NotIncludes, "does not include"]
								] :
								trait === CondTrait.Goal || trait === CondTrait.HeardOfFuncSyms || trait === CondTrait.PhysicalSymsWorrying ||
								trait === CondTrait.StoppedActivities || trait === CondTrait.ExerciseLevel || trait === CondTrait.LowMood ||
								trait === CondTrait.EnergyLevel || trait === CondTrait.SleepTrouble ? [
								    [CondOp.Equals, "equals"],
								    [CondOp.NotEquals, "does not equal"]
								] : [
									[CondOp.None, "(no options available)"]
								]
							}
							size="sm"
							onChange={updateConditionOp}
							disabled={!trait || trait === "none"}
							className="my-2"
							accentColour="brown"
						/>
						<Select
							value={value ?? "none"}
							options={
								trait === CondTrait.Checklist && (op === CondOp.Equals || op === CondOp.NotEquals) ? [
									["unsure", "Unsure"],
									["none", "None"]
								] :
								trait === CondTrait.Checklist && (op === CondOp.Includes || op === CondOp.NotIncludes) ? [
									["other", "Other"]
								] :
								trait === CondTrait.History || trait === CondTrait.Program ? (storiesLoading || storiesError ? [] : (storyOptions! as SelectOptions)) :
								trait === CondTrait.Goal ? [
								    ["body", "Body"],
								    ["stress", "Stress"],
									["lowMood", "Low mood"]
								] :
								trait === CondTrait.PhysicalSyms ? [
								    ["nausea", "General nausea"],
									["nauseaMorning", "Morning nausea"],
								    ["burpWind", "Burp / pass wind"],
									["bloated", "Bloating"],
								    ["toileting", "Need toilet often"],
									["gastroReflex", "Need toilet during meal"],
									["diarrhoea", "Diarrhoea"],
								    ["constipation", "Constipation"],
									["painStomach", "Stomach pains"],
								    ["painGeneral", "General pains"],
									["other", "Other"],
									["none", "None"]
								] :
								trait === CondTrait.HeardOfFuncSyms ? [
								    ["yes", "Yes"],
								    ["noNotSure", "No / not sure"]
								] :
								trait === CondTrait.PhysicalSymsWorrying ? [
								    ["constant", "Constant"],
								    ["daily", "Daily"],
									["sometimes", "Sometimes"],
								    ["none", "Not really"]
								] :
								trait === CondTrait.StoppedActivities ? [
									["none", "Not really"],
								    ["noHobbies", "Has no hobbies"]
								] :
								trait === CondTrait.ExerciseLevel ? [
								    ["regular", "Regular"],
								    ["some", "Some"],
									["notAble", "Not able to"],
								    ["dislike", "Dislikes exercise"]
								] :
								trait === CondTrait.LowMood ? [
								    ["always", "Nearly every day"],
								    ["overHalf", "More than half the days"],
									["several", "Several days"],
								    ["none", "Not at all"]
								] :
								trait === CondTrait.EnergyLevel ? [
								    ["high", "High"],
								    ["medium", "Medium"],
									["boomBust", "Boom and bust"],
								    ["low", "Low"]
								] :
								trait === CondTrait.SleepTrouble ? [
								    ["always", "Nearly every day"],
								    ["overHalf", "More than half the days"],
									["several", "Several days"],
								    ["none", "Not at all"]
								] :
								[["none", "(no options available)"]]
							}
							size="sm"
							onChange={updateConditionValue}
							disabled={!trait || trait === "none" || trait === "devMode" || op === "none" || op === "true" || op === "false" || op === "empty"}
						/>

						<div className="bg-olive-20 hidden" />
						<div className="bg-brown-20 hidden" />
						<div className="bg-brown-50 hidden" />
						<div className="hover:bg-brown-100 border-brown-100 hidden" />
					</div>
				}

				<Button
					variant="danger"
					onClick={() => removeEdge(id)}
				>
					<TrashFill className="mr-2" /> Remove
				</Button>
			</Transition>
		</OutsideListener>
	);
};

export default EdgeSettings;
