import { useRef, useEffect, ReactElement, RefObject } from "react";


const useOutsideListener = (ref: RefObject<HTMLDivElement>, fn: () => void) => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                fn();
            }
        };
        
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, fn]);
};


type OutsideListenerProps = {
    children: ReactElement,
    fn: () => void
};

const OutsideListener = ({ children, fn }: OutsideListenerProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideListener(wrapperRef, fn);

    return <div ref={wrapperRef}>{ children }</div>;
};

export default OutsideListener;
