import { SelectOptions } from '../../../utils/Select';
import { MsgSaveAs, MsgType, NodeData } from '../../../types';
import { Bullseye, CalendarEvent, Clock, Image, InputCursorText, Sticky, TextareaT, UiChecks } from 'react-bootstrap-icons';
import TextInputNode from './TextInputNode';
import SelectNode from './SelectNode';


export type NodeDataProps = {
    data: NodeData;
};


export const BotTextNode = ({ data }: NodeDataProps) => TextInputNode(MsgType.BotText, "Text", TextareaT, data);
export const BotNetImgNode = ({ data }: NodeDataProps) => TextInputNode(MsgType.BotNetImg, "Internet image", Image, data);
export const UserButtonNode = ({ data }: NodeDataProps) => TextInputNode(MsgType.UserButton, "Button", Bullseye, data, true);
export const UserListNode = ({ data }: NodeDataProps) => TextInputNode(MsgType.UserList, "Checklist", UiChecks, data);
export const CommentNode = ({ data }: NodeDataProps) => TextInputNode(MsgType.Comment, "Comment", Sticky, data, true);


const userTextOptions: SelectOptions = [
    [MsgSaveAs.Default, "N/A"],
    [MsgSaveAs.FeedbackGeneral, "Feedback - general"],
    [MsgSaveAs.FeedbackStory, "Feedback - story"],
    [MsgSaveAs.Name, "Username"],
    [MsgSaveAs.DailyCheckText, "Daily check-in user input"],
    [MsgSaveAs.PhysicalSymsOther, "Other physical symptoms"]
];
export const UserTextNode = ({ data }: NodeDataProps) => SelectNode(MsgType.UserText, "Free text", InputCursorText, userTextOptions, data);

const userDateOptions: SelectOptions = [
    [MsgSaveAs.Default, "N/A"],
    [MsgSaveAs.DoB, "Date of birth"]
];
export const UserDateNode = ({ data }: NodeDataProps) => SelectNode(MsgType.UserDate, "Date picker", CalendarEvent, userDateOptions, data);

const userTimeOptions: SelectOptions = [
    [MsgSaveAs.Default, "N/A"],
    [MsgSaveAs.NotificationTime, "Notification time"]
];
export const UserTimeNode = ({ data }: NodeDataProps) => SelectNode(MsgType.UserTime, "Time picker", Clock, userTimeOptions, data);
