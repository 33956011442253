import { useEffect, useState } from 'react';
import { MediaLocation, MediaType, StorySubCat, Story, StoryCat, StoryType } from '../../../types';
import { colourDict } from '../../../helpers';
import Input from '../../../utils/Input';
import MediaPreview from '../../../utils/MediaPreview';
import Select from '../../../utils/Select';
import Switch from '../../../utils/Switch';
import firebase from '../../../firebase';
import { getDatabase, orderByChild, query, ref } from 'firebase/database';
import { useListVals } from 'react-firebase-hooks/database';
import { Tab } from '@headlessui/react';


const db = getDatabase(firebase);

type StorySettingsGeneralProps = {
	story: Story;
	setStory: (storyID: string, story: Story) => void;
};

const StorySettingsGeneral = ({story, setStory}: StorySettingsGeneralProps) => {
	const storiesRef = ref(db, "stories/");
	const [stories, storiesLoading, storiesError] = useListVals<Story>(query(storiesRef, orderByChild("name")), { keyField: "id" });
	useEffect(() => {
		if (storiesError) console.error(storiesError);
	}, [storiesError]);

	const [descDraft, setDescDraft] = useState(story.desc ?? "");
	const [summaryDraft, setSummaryDraft] = useState(story.summary ?? "");

	const handleSwitchUpdate = (name: string, checked: boolean) => {
		const newStory = { ...story };
		newStory[name] = checked;
		setStory(story.id, newStory);
	};

	const handleValueUpdate = (key: string, value: string) => {
		const newStory = { ...story };
		newStory[key] = value;
		setStory(story.id, newStory);
	};

	const handleTypeUpdate = (value: string) => {
		const newStory = { ...story };
		newStory.type = value as StoryType;
		setStory(story.id, newStory);
	};

	const handleCatUpdate = (value: string) => {
		const newStory = { ...story };
		newStory.category = value as StoryCat;
		setStory(story.id, newStory);
	};

	const handleSubCatUpdate = (value: string) => {
		const newStory = { ...story };
		newStory.subcategory = value as StorySubCat;
		setStory(story.id, newStory);
	};
	
	const handlePriorityUpdate = (value: number) => {
		const newStory = { ...story };
		newStory.priority = value;
		setStory(story.id, newStory);
	};


	const onboardingStories = stories?.filter(s => s.type === StoryType.Onboarding) ?? [];
	const reonboardingStories = stories?.filter(s => s.type === StoryType.ReOnboarding) ?? [];
	const programChangeStories = stories?.filter(s => s.type === StoryType.ChooseProgramme) ?? [];
	const dailyCheckStories = stories?.filter(s => s.type === StoryType.DailyCheck) ?? [];


	return (
		<Tab.Panel>
			<h3 className="mt-4">Options</h3>
			<div className="flex items-center mb-4">
				<span className="basis-1/4 mr-4">
					Story type
				</span>
				<Select
					value={story.type}
					options={storiesLoading ? [] : [
						[StoryType.Story, "Story"],
						[StoryType.Test, "Test"],
						[StoryType.Onboarding, "Onboarding", onboardingStories.length >= 1],
						[StoryType.ReOnboarding, "Re-onboarding", reonboardingStories.length >= 1],
						[StoryType.ChooseProgramme, "Change programme", programChangeStories.length >= 1],
						[StoryType.DailyCheck, "Daily check-in", dailyCheckStories.length >= 1]
					]}
					disabled={story.type === StoryType.Onboarding  || story.type === StoryType.ChooseProgramme}
					onChange={handleTypeUpdate}
				/>
			</div>

			<div className="flex items-center mb-4">
				<span className="basis-1/4 mr-4">
					Category
				</span>
				<Select
					value={story.category}
					options={[
						[StoryCat.Dev, "Developer (hidden)"],
						[StoryCat.AboutApp, "About the app"],
						[StoryCat.Activity, "Activity corner"],
						[StoryCat.Body, "How the body works"],
						[StoryCat.StressAnxiety, "Stress and anxiety"],
						[StoryCat.LowMood, "Low mood"],
						[StoryCat.BeActive, "Being active"],
						[StoryCat.Sleep, "Sleep"],
						[StoryCat.Help, "Help page"]
					]}
					onChange={handleCatUpdate}
				/>
			</div>

			{ story.category === StoryCat.Activity &&
				<div className="flex items-center mb-4">
					<span className="basis-1/4 mr-4">
						Subcategory
					</span>
					<Select
						value={story.subcategory ?? ""}
						options={[
							[StorySubCat.Relax, "Relaxation"],
							[StorySubCat.Thinking, "Manage your thoughts"],
							[StorySubCat.LookAfterYourself, "Look after yourself"]
						]}
						onChange={handleSubCatUpdate}
					/>
				</div>
			}

			<div className="flex items-center mb-6">
				<span className="basis-1/4 mr-4">
					Library priority
				</span>
				<Input
					init={story.priority?.toString() ?? ""}
					onBlur={value => handlePriorityUpdate(parseInt(value))}
					placeholder="Enter a number"
					className="w-full"
				/>
			</div>

			<Switch
				checked={story.live}
				label="Enable this story in the app"
				onChange={checked => handleSwitchUpdate("live", checked)}
			/>

			<p className="mt-4 mb-1">
				Title
			</p>
			<Input
				init={story.title ?? ""}
				onBlur={value => handleValueUpdate("title", value)}
				placeholder="If set, the title that will be displayed in the app over its Botany title"
				className="w-full"
			/>

			<p className="mt-4 mb-1">
				Description
			</p>
			<textarea
				value={descDraft}
				onChange={event => setDescDraft(event.target.value)}
				onBlur={() => handleValueUpdate("desc", descDraft)}
				placeholder="A short description of this story before the user starts it"
				className="w-full min-h-12 pl-4 pr-10 py-4 bg-olive-50 hover:bg-olive-100 focus:bg-olive-100 text-olive-900 rounded-md placeholder:text-olive-600"
			/>

			<p className="mt-4 mb-1">
				Summary
			</p>
			<textarea
				value={summaryDraft}
				onChange={event => setSummaryDraft(event.target.value)}
				onBlur={() => handleValueUpdate("summary", summaryDraft)}
				placeholder="A short summary of this story when the user has completed it"
				className="w-full min-h-12 pl-4 pr-10 py-4 bg-olive-50 hover:bg-olive-100 focus:bg-olive-100 text-olive-900 rounded-md placeholder:text-olive-600"
			/>

			<p className="mt-4 mb-1">
				Duration
			</p>
			<Input
				init={story.duration ?? ""}
				onBlur={value => handleValueUpdate("duration", value)}
				placeholder={`Include time units (e.g. "3-5 mins")`}
				className="w-full"
			/>


			<h3>Title card</h3>
			<p className="mb-2">
				Choose an image that represents this story.
			</p>
			<div className="flex mt-4">
				<div className="relative w-1/2 mr-6 rounded-lg overflow-hidden">
					<MediaPreview
						location={MediaLocation.Local}
						type={MediaType.Image}
						src={story.banner ?? ""}
						className={`border-4 ${colourDict.find(cD => cD.colour === story.bannerColour)?.border ?? "border-black"} ${colourDict.find(cD => cD.colour === story.bannerColour)?.bg ?? "bg-black"}`}
						changeMedia={value => handleValueUpdate("banner", value)}
					/>
				</div>

				<div className="self-start grid grid-cols-3 gap-2">
					{ colourDict.map(cD => (
						<button
							key={cD.colour}
							className={`w-10 h-10 rounded-full flex items-center justify-center border-8 ${cD.border} ${story.bannerColour === cD.colour ? "bg-gray-10" : cD.bg}`}
							onClick={() => handleValueUpdate("bannerColour", cD.colour)}
						/>
					)) }
				</div>
			</div>
		</Tab.Panel>
	);
};

export default StorySettingsGeneral;
