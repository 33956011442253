import { CSSProperties, ForwardedRef, forwardRef, ReactNode } from "react";


type ButtonProps = {
    children: ReactNode;
    variant?: "primary" | "secondary" | "danger" | "light" | "dark";
    disabled?: boolean;
    submit?: boolean;
    className?: string;
    style?: CSSProperties;
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Button = forwardRef(({ children, variant: _variant, disabled, submit, className, style, onClick }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const variant = _variant ?? "primary";
    
    return (
        <button
            ref={ref}
            type={submit ? "submit" : "button"}
            disabled={disabled}
            className={`
                px-4 py-2 rounded-md select-none ease-out duration-[50ms] hover:brightness-95 active:shadow-none

                ${ variant === "primary" ? 
                    "bg-green-100 shadow-green-200"
                : variant === "secondary" ?
                    "bg-brown-100 shadow-brown-200"
                : variant === "danger" ?
                    "bg-red-600 shadow-red-700 text-white"
                : variant === "light" ?
                    "hover:bg-black/5 hover:brightness-100"
                : variant === "dark" ?
                    "bg-green-500 shadow-green-600 text-white"
                : ""
                }

                ${ variant !== "light" ? 
                    "shadow-[inset_0_-2px] active:translate-y-[2px] disabled:bg-gray-100 disabled:shadow-gray-100 disabled:text-gray-800 disabled:hover:brightness-100 disabled:active:translate-y-0"
                :
                    "disabled:text-gray-500 disabled:hover:bg-transparent "
                }

                ${className}
            `}
            style={style}
            onClick={onClick}
        >
            { children }
        </button>
    );
});

export default Button;
