import { useState, useEffect } from 'react';
import Node from "./Node";
import { NodeDataProps } from './customNodes';
import Select, { SelectOptions } from '../../../utils/Select';
import { Story } from '../../../types';
import firebase from '../../../firebase';
import { getDatabase, orderByChild, query, ref, set } from 'firebase/database';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';
import { NodePlus } from 'react-bootstrap-icons';


const db = getDatabase(firebase);

const AddToProgramNode = ({ data }: NodeDataProps) => {
	const [options, setOptions] = useState<SelectOptions>([]);

	const storiesRef = ref(db, "stories/");
	const [stories, storiesLoading, storiesError] = useListVals<Story>(query(storiesRef, orderByChild("name")), { keyField: "id" });


	useEffect(() => {
		if (!stories || storiesLoading || storiesError) return;

		const thisStory = stories.find(story => Object.keys(story.flows ?? {}).includes(data.flowID));
		const filteredStories = stories.filter(story => story.id !== thisStory?.id && story.live);
		const storiesMap: SelectOptions = filteredStories.map(story => [story.id, story.name]);

		setOptions(storiesMap);
	}, [stories, storiesLoading, storiesError, data.flowID]);


	const contentRef = ref(db, "storyFlows/" + data.flowID + "/" + data.nodeID + "/content");
	const [content, contentLoading, contentError] = useObjectVal<string[]>(contentRef);
	const value = content ? content[0] : "";

	const handleSelectUpdate = (value: string) => {
		set(contentRef, [value]);
	};


	return (
		<Node
			title="Add to programme"
			handleTop handleBottom={1}
			Icon={NodePlus}
		>
			<div className="nodrag">
				{ contentLoading || storiesLoading ?
					<p>Loading...</p>
				: contentError ?
					<p>Error: { contentError.toString() }</p>
				: storiesError ?
					<p>Error: { storiesError.toString() }</p>
				:
					<div className="relative w-full p-1">
						<Select
							value={value}
							options={options}
							size="sm"
							onChange={handleSelectUpdate}
						/>
					</div>
				}
			</div>
		</Node>
	);
};

export default AddToProgramNode;
