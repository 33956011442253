import { FirebaseApp, getApps, initializeApp } from "firebase/app";
// import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";


declare global {
    var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}


const firebaseConfig = {
    apiKey: "AIzaSyAXdU6qEmJE5rvf4w1dvpcio42XO_Yh2wM",
    authDomain: "voxsio-botany.firebaseapp.com",
    databaseURL: `https://voxsio-botany-${process.env.REACT_APP_DATABASE_LOCATION}.europe-west1.firebasedatabase.app`,
    projectId: "voxsio-botany",
    storageBucket: "voxsio-botany.appspot.com",
    messagingSenderId: "764812430341",
    appId: "1:764812430341:web:cb3e03a09581cd44da606c",
    measurementId: "G-SE7RXX7XWT"
};

const apps = getApps();
let app: FirebaseApp;

if(!apps.length) {
    app = initializeApp(firebaseConfig);
} else {
    app = apps[0];
}


// window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaEnterpriseProvider("6LdufPwpAAAAAJXLEzx64G6UZ1yTssqNkM45ZKtd"),
//     isTokenAutoRefreshEnabled: true
// });
// app = appCheck.app;


export default app;
