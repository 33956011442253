import { useEffect, useState } from 'react';
import { MediaLocation, MediaType } from '../types';
import MediaContent from '../build/media/MediaContent';
import Modal from './Modal';
import Loading from './Loading';
import Tooltip from './Tooltip';
import WithStoredMedia from './WithStoredMedia';
import Lottie from "lottie-react";
import { ArrowRepeat, ArrowUpRightSquareFill, EmojiFrown, QuestionDiamond } from 'react-bootstrap-icons';


type MediaPreviewProps = {
	location: MediaLocation;
	type?: MediaType;
	src?: string;
	className?: string;
	changeMedia?: (name: string) => void;
};

const MediaPreview = ({ type, location, src, className, changeMedia }: MediaPreviewProps) => {
	const [showPicker, setShowPicker] = useState(false);
	const closePicker = () => setShowPicker(false);

	const [error, setError] = useState(false);

	// Force check again whenever a new src is passed down
	useEffect(() => {
		if (src === "") setError(true);
		else setError(false);
	}, [src]);

	return (
		<div className={`relative h-full w-full flex flex-col items-center justify-center rounded-md overflow-hidden ${className}`}>
			{ location === MediaLocation.Local ? <>
				{ !type || !src || error ?
					type !== MediaType.Audio && <ErrorMsg type={type} />
				:
					<WithStoredMedia
						fileType={type}
						src={src}
					>
						{ media => {
							return media.urlLoading ?
								<Loading />
							: media.urlError ?
								<ErrorMsg type={type} />
							: type === MediaType.Image || type === MediaType.Icon ?
								<img
									src={media.downloadURL}
									alt={media.metadata?.name ?? "Couldn't load"}
									className="w-full h-full object-contain rounded-[inherit]"
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										setError(true);
									}}
								/>
							: type === MediaType.Animation ?
								<Lottie
									animationData={media.animationData}
								/>
							: type === MediaType.Video ?
								<video
									src={media.downloadURL}
									controls
									className="w-full h-full rounded-t-lg object-contain"
								/>
							: type === MediaType.Audio ?
								<audio
									src={media.downloadURL}
									controls
									className="w-full"
								/>
							: <></>
						} }
					</WithStoredMedia>
				}
				{ changeMedia && type !== MediaType.Audio &&
					<button
						className="absolute w-full h-full flex flex-col items-center justify-center bg-[#000000AA] rounded-[inherit] opacity-0 hover:opacity-100 cursor-pointer"
						onClick={() => setShowPicker(true)}
					>
						{ type === MediaType.Icon ?
							<ArrowUpRightSquareFill className="h-8 w-8 fill-white" />
						: <>
							<ArrowUpRightSquareFill className="h-8 w-8 mb-4 fill-white" />
							<p className="text-white">Choose</p>
						</> }
					</button>
				}

				{ changeMedia && type === MediaType.Audio &&
					<div className="w-full my-2 px-2 flex items-center">
						<Tooltip content="Select file">
							<button
								className="group-icon mr-4 p-1 bg-black/10 hover:bg-black rounded-full"
								onClick={() => setShowPicker(true)}
							>
								<ArrowRepeat className="h-6 w-6 group-icon-hover:fill-white" />
							</button>
						</Tooltip>
						<div className="">
							{ src || "No audio file selected" }
						</div>
					</div>
				}

				{ changeMedia &&
					<Modal
						show={showPicker}
						close={closePicker}
						className="max-w-[81rem] min-h-screen"
					>
						<MediaContent
							location="modal"
							fileType={type ?? MediaType.Image}
							mime={
								type === MediaType.Image ? "image/*" :
								type === MediaType.Icon ? ".png" :
								type === MediaType.Animation ? ".json" :
								type === MediaType.Video ? "video/*" :
								type === MediaType.Audio ? "audio/*" : ""
							}
							onSelect={(name: string) => { changeMedia(name); closePicker() }}
						/>
					</Modal>
				}
			</> : // location === MediaLocation.Internet
			error ?
				<ErrorMsg type={type} />
			:	
				<img
					src={src}
					alt={"Preview"}
					className="w-full h-full object-contain rounded-[inherit]"
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						setError(true);
					}}
				/>
			}
		</div>
	)
};

export default MediaPreview;



type ErrorMsgProps = {
	type?: MediaType;
};

const ErrorMsg = ({ type }: ErrorMsgProps) => (
	<div className="w-full aspect-square flex flex-col items-center justify-center bg-black/20 rounded-[inherit]">
		{ type === MediaType.Icon ?
			<QuestionDiamond className="block w-8 h-8 fill-black/90" />
		: <>
			<EmojiFrown className="block w-8 h-8 mb-4 fill-black/90" />
			<p className="text-black/90 text-center">No valid item selected.</p>
		</> }
	</div>
);
