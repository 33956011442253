import { FormEvent, useEffect, useState } from 'react';
import QuestEditor from './QuestEditor';
import QuestTabButton from './QuestTabButton';
import Button from '../../utils/Button';
import Input from '../../utils/Input';
import Loading from '../../utils/Loading';
import MenuPlaceholder from '../../utils/MenuPlaceholder';
import Tooltip from '../../utils/Tooltip';
import { Quest, QuestCat, TreeNode } from '../../types';
import firebase from '../../firebase';
import { ref, push, set, remove, getDatabase } from 'firebase/database';
import { useListVals, useObjectVal } from 'react-firebase-hooks/database';
import { Tab } from "@headlessui/react";
import { DndProvider } from "react-dnd";
import { Tree, getDescendants, MultiBackend, getBackendOptions } from '@minoru/react-dnd-treeview';
import { FolderPlus, Stars, TrashFill } from 'react-bootstrap-icons';


const db = getDatabase(firebase);

const Quests = () => {
	const [selectedQuestID, setSelectedQuestID] = useState("none");
	const [openFolders, setOpenFolders] = useState<(string | number)[]>([]);
	const [draftTitle, setDraftTitle] = useState("");


	const questsRef = ref(db, 'quests/');
	const [quests, questsLoading, questsError] = useListVals<Quest>(questsRef, { keyField: "id" });

	const questTreeRef = ref(db, 'questTree/');
	const [questTree, questTreeLoading, questTreeError] = useObjectVal<TreeNode[]>(questTreeRef);


	useEffect(() => {
		if (questsError) console.error(questsError);
		if (questTreeError) console.error(questTreeError);
	}, [questsError, questTreeError]);


	const setQuest = (questID: string, quest: Quest) => {
		set(ref(db, 'quests/' + questID), quest);
	};

	const removeQuest = (questID: string) => {
		remove(ref(db, 'quests/' + questID));
	};

	const pushQuestNode = (questNode: TreeNode) => {
		const length = questTree?.length;
		set(ref(db, 'questTree/' + (length && length > 0 ? length : 0)), questNode);
	};
	const removeQuestNode = (questNodeID: TreeNode['id']) => {
		if (!questTree) return;
		// We need to set a new tree instead of calling remove()
		// because in Firebase the indices of the array will
		// not automatically update
		const index = questTree.findIndex(el => el.id === questNodeID);
		const newTree = [...questTree];
		newTree.splice(index, 1);
		set(questTreeRef, newTree);
	};


	const handleMenuItemDrop = (newTreeList: TreeNode[]) => {
		set(questTreeRef, newTreeList);
	};

	const handleNameFormUpdate = (val: string) => {
		setDraftTitle(val);
	};

	const handleNewQuest = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		createQuest();
	};


	const createQuest = () => {
		if (draftTitle === "") return;

		const newQuestID = push(questsRef).key!;

		const newQuest: Quest = {
			id: newQuestID,
			title: draftTitle,
			category: QuestCat.None,
			live: false,
			icon: "",
			description: "",
			banner: ""
		};

		const newQuestNode: TreeNode = {
			id: push(questTreeRef).key!,
			parent: 0,
			droppable: false,
			text: newQuestID
		};

		setQuest(newQuestID, newQuest);
		pushQuestNode(newQuestNode);
		setDraftTitle("");
		setSelectedQuestID(newQuestID);
	};

	const deleteQuest = (questID: string) => {
		const questNode = questTree?.find(node => node.text === questID);
		if (!questNode) return;

		removeQuest(questID);
		removeQuestNode(questNode.id);
	};

	const pushFolder = () => {
		const newFolder: TreeNode = {
			id: push(questTreeRef).key!,
			parent: 0,
			droppable: true,
			text: "New folder"
		}
		pushQuestNode(newFolder);
	};

	const renameFolder = (newName: string, folder: TreeNode) => {
		const index = questTree?.findIndex(el => el.id === folder.id);
		if (index !== undefined) set(ref(db, 'questTree/' + index + '/text'), newName);
	};

	const deleteFolder = (questNodeID: TreeNode['id']) => {
		const deleteIDs = [
			...getDescendants(questTree!, questNodeID).map(node => node.id),
			questNodeID
		];

		for (let id of deleteIDs) {
			const currNode = questTree!.find(node => node.id === id);
			if (!currNode) continue;

			if (currNode.droppable) removeQuestNode(id);
			else deleteQuest(currNode.text);
		}
	};


	const handleTabSelect = (questID: string) => {
		setSelectedQuestID(questID);
	};


	return (
		<Tab.Panel className="relative w-full h-full flex focus-visible:outline-none">
			<div className={`flex flex-col h-[calc(100%-0.5rem)] w-1/3 lg:w-1/4 2xl:w-1/5 max-w-[400px] my-2 ml-1 mr-0 pt-6 pl-3`}>
				<div className="mr-4">
					<h1 className="text-4xl font-semibold text-brown-600">Quests</h1>
					<div className="flex flex-row w-full mt-2">
						<form
							onSubmit={handleNewQuest}
							className="relative flex-grow mr-2"
						>
							<Input
								init={draftTitle}
								onChange={handleNameFormUpdate}
								onBlur={createQuest}
								placeholder="Add quest"
								className="w-full pl-4 pr-10"
							/>
							<Tooltip content="Add quest" placement="top">
								<Button submit
									className="absolute w-9 h-9 top-[0.375rem] right-[0.375rem] pl-0 pr-0 !rounded-full"
								>
									→
								</Button>
							</Tooltip>
						</form>

						<Tooltip content="Add folder" placement="top">
							<Button
								onClick={pushFolder}
							>
								<FolderPlus className="w-5 h-5" />
							</Button>
						</Tooltip>
					</div>
				</div>

				<div className="flex-grow w-full mt-6 pr-4 pb-10 space-y-1 overflow-y-auto">
					{ questsLoading || questTreeLoading ? <Loading /> :
						<DndProvider backend={MultiBackend} options={getBackendOptions()}>
							<Tree
								tree={questTree ?? []}
								initialOpen={openFolders}
								rootId={0}
								sort={false}
								insertDroppableFirst={false}
								dropTargetOffset={10}
								onDrop={handleMenuItemDrop}
								canDrop={(_, { dragSource, dropTargetId }) => {
									if (dragSource?.parent === dropTargetId) return true;
								}}
								onChangeOpen={newOpenIDs => setOpenFolders(newOpenIDs)}
								render={(questNode: TreeNode, options) => {
									return questNode.droppable ?
										<QuestTabButton
											node={questNode}
											handleTabSelect={handleTabSelect}
											renameFolder={renameFolder}
											deleteFolder={deleteFolder}
											{...options}
										/>
										:
										<QuestTabButton
											node={questNode}
											questID={questNode.text}
											questTitle={quests?.find(quest => quest.id === questNode.text)?.title}
											selectedQuest={selectedQuestID}
											handleTabSelect={handleTabSelect}
											deleteQuest={deleteQuest}
											{...options}
										/>
								}}
								classes={{
									draggingSource: "opacity-40",
									placeholder: "relative"
								}}
								placeholderRender={(_, { depth }) =>
									<MenuPlaceholder depth={depth} />
								}
							/>
						</DndProvider>
					}
				</div>
			</div>

			<div className={`h-full w-2/3 lg:w-3/4 2xl:w-4/5 pt-6 pl-8 pr-6 pb-24 float-right overflow-y-auto`}>
				{ // If loading
					!quests || questsLoading ?
						<div className="absolute w-full h-full bg-gray-20/80 shadow-inner z-10">
							<div className="absolute top-[40%] left-[calc(50%-50px)] p-3 bg-gray-10 rounded-lg shadow z-11">
								<Loading />
							</div>
						</div>
				// If no quest is selected
					: selectedQuestID === "none" ?
						<div className="h-full" key="quest-canvas-get-started">
							<div className="h-full flex flex-col justify-center items-center">
								<Stars className="w-8 h-8 mb-8 fill-green-500" />
								<p>Select a quest to start editing!</p>
							</div>
						</div>
				// If a quest has just been deleted, either here or by another user
					: !quests?.find(ql => ql.id === selectedQuestID) ?
						<div className="h-full" key="quest-canvas-deleted">
							<div className="h-full flex flex-col justify-center items-center">
								<TrashFill className="w-8 h-8 mb-8 fill-red-500" />
								<p>Quest deleted!</p>
							</div>
						</div>
				// If we have a quest
					: <QuestEditor quest={quests?.find(ql => ql.id === selectedQuestID)!} />
				}
			</div>
		</Tab.Panel>
	);
};

export default Quests;
