import { ReactNode } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import { roundArrow } from "tippy.js";


type PopoverProps = {
    children: TippyProps['children'],
    content: ReactNode,
    variant?: "default" | "danger",
    placement?: TippyProps['placement'],
    className?: string,
    onShow?: TippyProps['onShow'],
    onHide?: TippyProps['onHide'] 
};

const Popover = ({ children, content, variant, placement, className, onShow, onHide }: PopoverProps) => {
    return (
        <Tippy
            trigger="click"
            interactive
            role="popover"
            content={content}
            placement={placement}
            className={`p-2 border-2 shadow rounded-lg
            ${variant === "danger" ? "text-red-900 border-red-500 fill-red-500 bg-red-50 shadow-red-500/40" : "text-green-900 border-gray-400 fill-gray-400 bg-gray-21 shadow-gray-500/40"}
            ${className}`}
            arrow={roundArrow}
            animation="shift-away"
            onShow={onShow}
            onHidden={onHide}
        >
            { children }
        </Tippy>
    );
};

export default Popover;
