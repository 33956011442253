import { useEffect, useState } from 'react';
import StoryFlow from './StoryFlow';
import StorySettings from './settings/StorySettings';
import { Story } from '../../types';
import Button from '../../utils/Button';
import EditText from '../../utils/EditText';
import FillIcon from '../../utils/FillIcon';
import Select from '../../utils/Select';
import Tooltip from '../../utils/Tooltip';
import { ReactFlowProvider } from 'reactflow';
import { useSingleton } from '@tippyjs/react';
import { Fullscreen, FullscreenExit, Gear, Link45deg } from 'react-bootstrap-icons';


type StoryEditorProps = {
    story: Story;
    clipboard: string;
    fullscreen: boolean;
    setStory: (storyID: string, story: Story) => void;
    setClipboard: React.Dispatch<React.SetStateAction<string>>;
    handleFullscreen: () => void;
};

const StoryEditor = ({ story, clipboard, fullscreen, setStory, setClipboard, handleFullscreen }: StoryEditorProps) => {
    const [flowID, setFlowID] = useState("");

    const [showSettings, setShowSettings] = useState(false);
    const [topbarTipSource, topbarTipTarget] = useSingleton();


    const setFlowIDToDrafts = () => setFlowID(Object.keys(story.flows).find(id => story.flows[id] === "Draft") ?? "");

    // Automatically set the displayed draft to Drafts but only when changing to a new story
    useEffect(() => {
        setFlowIDToDrafts();
    //eslint-disable-next-line
    }, [story.id]);


    const handleStoryNameUpdate = (_: string, value: string) => {
        const newStory = {...story};
        newStory.name = value;
        setStory(story.id, newStory);
    };


    const copyTestLink = () => {
        const url = `https://test.ubok.app?story=${story.id}&ver=${flowID}`;
        navigator.clipboard.writeText(url);
    };


    return (
        <ReactFlowProvider>
            <div className="absolute flex h-12 top-4 left-4 font-semibold text-lg z-10">
                <div className="w-fit p-1 mr-4 bg-gray-10 rounded-md shadow">
                    <EditText
                        key={story.id}
                        name="story-name"
                        className="h-10 px-2 flex flex-col justify-center"
                        init={story.name}
                        fn={handleStoryNameUpdate}
                    />
                </div>

                <div className="rounded-md shadow">
                    <Select
                        value={flowID}
                        options={ Object.entries(story.flows).map(([id, name]) => [id, name]) }
                        onChange={val => setFlowID(val)}
                        listStyle="!w-60"
                    />
                </div>
            </div>

            <div className="absolute flex items-stretch h-12 top-4 right-4 z-10">
                <Tooltip singleton={topbarTipSource} />

                <Tooltip content="Test link copied!" trigger="click" placement="bottom">
                    <Button
                        variant="dark"
                        className="mr-2 shadow shadow-black/10 text-lg"
                        onClick={copyTestLink}
                    >
                        <Link45deg className="-ml-1 mr-2 text-[1.4rem]" />
                        Test
                    </Button>
                </Tooltip>

                <Tooltip content={"Settings"} singleton={topbarTipTarget}>
                    <Button
                        className="w-12 mr-2 shadow shadow-black/10"
                        onClick={() => setShowSettings(true)}
                    >
                        <Gear className="-ml-[2px] text-[1.3rem]" />
                    </Button>
                </Tooltip>

                <Tooltip content={fullscreen ? "Exit fullscreen" : "Go fullscreen"} singleton={topbarTipTarget}>
                    <Button
                        variant="light"
                        className="w-12 !px-3 bg-gray-10 hover:bg-gray-21 shadow"
                        onClick={handleFullscreen}
                    >
                        <FillIcon Line={Fullscreen} Fill={FullscreenExit} iconStyle="-mt-px text-[1.1rem]" alwaysShowFill={fullscreen} />
                    </Button>
                </Tooltip>
            </div>

            <StorySettings
                key={`${story.id}-settings`}
                show={showSettings}
                story={story}
                flowID={flowID}
                handleClose={() => setShowSettings(false)}
                setStory={setStory}
                setFlowIDToDrafts={setFlowIDToDrafts}
            />

            <StoryFlow
                key={`${story.id}-${flowID}`}
                flowID={flowID}
                story={story}
                clipboard={clipboard}
                setClipboard={setClipboard}
            />
        </ReactFlowProvider>
    );
};

export default StoryEditor;
