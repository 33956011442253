import { useState, MouseEvent, useRef, KeyboardEvent } from 'react';
import { TreeNode, StoryType } from '../../types';
import EditText from '../../utils/EditText';
import Button from '../../utils/Button';
import FillIcon from '../../utils/FillIcon';
import Popover from '../../utils/Popover';
import Tooltip from '../../utils/Tooltip';
import OutsideListener from '../../utils/OutsideListener';
import { useDragOver } from '@minoru/react-dnd-treeview';
import { useSingleton } from '@tippyjs/react';
import { Folder, Folder2Open, Pencil, PencilFill, Trash, TrashFill } from 'react-bootstrap-icons';


type StoryTabButtonProps = {
	node: TreeNode,
	depth: number,
	isOpen: boolean,
	storyID?: string,
	storyName?: string,
	storyType?: StoryType,
	selectedStory?: string | null,
	onToggle: (storyNodeid: TreeNode['id']) => void,
	handleTabSelect: (storyID: string) => void,
	deleteStory?: (storyID: string) => void,
	renameFolder?: (newName: string, folder: TreeNode) => void,
	deleteFolder?: (storyNodeID: TreeNode['id']) => void
};

const StoryTabButton = ({ node, depth, isOpen, storyID, storyName, storyType, selectedStory, onToggle, handleTabSelect, deleteStory, renameFolder, deleteFolder }: StoryTabButtonProps) => {
	// Ref for the delete button to enable both a popover and a tooltip
	const btnDeleteRef = useRef<HTMLButtonElement | null>(null);

	// UI state
	const [showEditFolder, setShowEditFolder] = useState(false);
	const [showDeleteMessage, setShowDeleteMessage] = useState(false);

	// Tooltip management
	const [tipSource, tipTarget] = useSingleton();

	const isFolder = node.droppable || false;
	const selected = (!isFolder && storyID === selectedStory) || showEditFolder || showDeleteMessage;

	const dragOverProps = useDragOver(node.id, isOpen, onToggle);


	const handleSelect = () => {
		if (isFolder) onToggle(node.id);
		else handleTabSelect(storyID!);
	};

	const handleClick = (event: MouseEvent) => {
		event.stopPropagation();
		handleSelect();
	};

	const handleKeyDown = (event: KeyboardEvent) => {
		if (!showEditFolder && (event.key === "Enter" || event.key === " ")) {
			handleSelect();
		}
	};


	const handleEditClick = (event: MouseEvent) => {
		event.stopPropagation();
		setShowEditFolder(!showEditFolder);
	};

	const handleNameUpdate = (_: string, value: string, baggage: TreeNode) => {
		renameFolder!(value, baggage);
		setShowEditFolder(false);
	};

	return (
		<div
			key={`story-nav-${node.id}`}
			className={`group flex flex-row items-center relative w-full min-h-[2rem] mt-1 rounded-md hover:bg-gray-50 cursor-pointer ${selected ? "bg-gray-50" : ""}`}
			style={{ marginInlineStart: `${depth}rem`, width: `calc(100% - ${depth}rem)` }}
			{...dragOverProps}
		>
			<Tooltip singleton={tipSource} />

			<span
				className={`w-full px-2 py-1 ${!isFolder ? `${selected ? "font-semibold" : ""} active:font-semibold transition-[font-weight]` : ""}`}
				onClick={handleClick}
				onKeyDown={handleKeyDown}
				tabIndex={0}
			>
				{ isFolder &&
					(isOpen ?
						<Folder2Open className="w-5 h-5 mr-2" />
						:
						<Folder className="w-5 h-5 mr-2" />
					)
				}

				{ isFolder ? node.text : storyName ? storyName : "(empty story name)" }
			</span>

			{ isFolder &&
				<Tooltip content="Edit folder name" singleton={tipTarget} >
					<button
						className={`group-icon absolute h-8 w-8 top-0 right-8 bg-gray-50 rounded-md cursor-pointer ${selected ? "block" : "hidden"} hover:bg-green-500 group-hover:block`}
						onClick={handleEditClick}
					>
						<FillIcon
							Line={Pencil}
							Fill={PencilFill}
							iconStyle="w-5 h-5 fill-green-500 group-icon-hover:fill-white"
						/>
					</button>
				</Tooltip>
			}

			{ showEditFolder &&
				<OutsideListener fn={() => { setShowEditFolder(false) }}>
					<div
						className="absolute w-[calc(100%-3.9rem)] top-0 left-[26px] bg-olive-50 text-olive-900 rounded-md shadow-sm z-50"
						onClick={e => e.stopPropagation()}
					>
						<EditText
							name="edit-folder-name"
							className="!p-[0.375rem]"
							init={node.text}
							baggage={node}
							fn={handleNameUpdate}
						/>
					</div>
				</OutsideListener>
			}

			<Popover
				variant="danger"
				onShow={() => setShowDeleteMessage(true)}
				onHide={() => setShowDeleteMessage(false)}
				className="cursor-auto"
				content={
					storyType === StoryType.Onboarding
						? <p>The Onboarding story cannot be deleted. If you'd like to change the onboarding process, edit this story instead.</p>
						: !isFolder && deleteStory && node.text
							? <>
								<p className="mb-4">
									Are you sure you want to delete "{storyName?.trim()}"? This can't be reversed!
								</p>
								<Button
									variant="danger"
									onClick={() => { deleteStory(node.text) }}
								>
									Delete story
								</Button>
							</>
							: deleteFolder && <>
								<p className="mb-4">
									Are you sure you want to delete {node.text}? This will also delete all folders and stories inside of it and it can't be reversed!
								</p>
								<Button
									variant="danger"
									onClick={() => { deleteFolder(node.id) }}
								>
									Delete folder and contents
								</Button>
							</>
				}
			>
				<button
					ref={btnDeleteRef}
					className={`group-icon absolute h-8 w-8 top-0 right-0 bg-gray-50 rounded-md cursor-pointer ${selected ? "block" : "hidden"} hover:bg-red-500 group-hover:block`}
				>
					<FillIcon
						Line={Trash}
						Fill={TrashFill}
						iconStyle="w-5 h-5 pt-px fill-red-500 group-icon-hover:fill-white"
					/>
				</button>
			</Popover>
			<Tooltip content={isFolder ? "Delete folder" : "Delete story"} ref={btnDeleteRef} singleton={tipTarget} />
		</div>
	);
};

export default StoryTabButton;
