import Node from "./Node";
import { Compass } from "react-bootstrap-icons";


const PickProgramNode = () => (
	<Node
		title="Programme preview"
		handleTop handleBottom={1}
		Icon={Compass}
	>
		<p className="p-2 pt-1 text-white italic">
			Generate the user's personalised programme and show them a preview.
		</p>
	</Node>
);

export default PickProgramNode;
