import { Switch as HlSwitch } from "@headlessui/react";


type SwitchProps = {
    checked: boolean;
    label: string;
    className?: string;
    onChange: (checked: boolean) => void;
};

const Switch = ({ checked, label, className, onChange }: SwitchProps) => {
    return (
        <div>
            <HlSwitch
                checked={checked}
                onChange={onChange}
                className={`${checked ? 'bg-green-500 border-green-500' : 'bg-transparent border-gray-200'} group
                    relative inline-flex flex-shrink-0 h-6 w-12 mb-2 border-2 rounded-full cursor-pointer
                    transition-colors ease-in-out duration-200
                    focus:outline-none focus-visible:ring-2 focus-visible:ring-black focus-visible:ring-opacity-75 ${className}
                `}
            >
                <span className="absolute left-14 whitespace-nowrap">{ label }</span>
                <span aria-hidden={true} className={`${checked ? 'bg-white left-[calc(100%-1.1rem)] group-hover:left-[calc(100%-1.35rem)]' : 'bg-gray-400 left-[.125rem]'}
                    absolute h-4 w-4 group-hover:w-5 mt-[0.125rem] rounded-full ring-0 transition-all ease-in-out duration-200 pointer-events-none`}
                />
            </HlSwitch>
        </div>
    );
};

export default Switch;
