import Node from "./Node";
import HeaderButton from './HeaderButton';
import { NodeDataProps } from './customNodes';
import Button from '../../../utils/Button';
import FillIcon from '../../../utils/FillIcon';
import MediaPreview from '../../../utils/MediaPreview';
import Tooltip from '../../../utils/Tooltip';
import { MediaLocation, MediaType, StoryFlowMsg } from '../../../types';
import firebase from '../../../firebase';
import { getDatabase, ref, set } from 'firebase/database';
import { useObjectVal } from 'react-firebase-hooks/database';
import { useSingleton } from '@tippyjs/react';
import { CheckCircleFill, Circle, CollectionPlay, Film, Hypnotize, Icon, Image, Mic, PlusCircle, PlusCircleFill, Trash, TrashFill } from 'react-bootstrap-icons';


const db = getDatabase(firebase);

export const BotMediaNode = ({ data }: NodeDataProps) => {
	const [addTipSource, addTipTarget] = useSingleton();
	const [typeTipSource, typeTipTarget] = useSingleton();


	// Get the node
	const nodePath = "storyFlows/" + data.flowID + "/" + data.nodeID;
	const nodeRef = ref(db, nodePath);
	const [node, loading, error] = useObjectVal<StoryFlowMsg>(nodeRef);


	// Shorthands
	const autoplay = node?.effect?.autoplay ?? false;
	const loop = node?.effect?.loop ?? false;


	// Handle input
	const handleSelectUpdate = (newValue: string, index: number) => {
		const valueRef = ref(db, nodePath + "/content/" + index);
		set(valueRef, newValue);
	};

	const changePath = (newType: string) => {
		set(ref(db, nodePath + "/content/"), [""]);
		set(ref(db, nodePath + "/meta/type/"), newType);
	};

	const handleAutoplayUpdate = () => {
		set(ref(db, nodePath + "/effect/autoplay"), !autoplay);
	};

	const handleLoopUpdate = () => {
		set(ref(db, nodePath + "/effect/loop"), !loop);
	};


	// Handle randomised fields
	const handleAddField = () => {
		if (!node) return;
		const newContent = [...node.content];
		newContent.push("");
		set(ref(db, nodePath + "/content/"), newContent);
	};

	const handleDeleteField = (index: number) => {
		if (!node) return;
		if (node.content.length <= 1) return;
		const newContent = [...node.content];
		newContent.splice(index, 1);
		set(ref(db, nodePath + "/meta/type/"), newContent);
	};


	// Render the node
	return (
		<Node
			title="Media"
			handleTop handleBottom={1}
			tipSource={addTipSource}
			headerButtons={
				<HeaderButton
					title="Add option"
					Line={PlusCircle} Fill={PlusCircleFill}
					tipTarget={addTipTarget}
					onClick={handleAddField}
				/>
			}
			Icon={CollectionPlay}
		>
			<div className="flex px-1">
				{([[MediaType.Image, Image], [MediaType.Animation, Hypnotize], [MediaType.Video, Film], [MediaType.Audio, Mic]] as [MediaType, Icon][])
					.map(([name, BtnIcon]: [MediaType, Icon]) => (
						<Tooltip content={name[0].toUpperCase() + name.substring(1)} singleton={typeTipTarget} key={name}>
							<Button variant="light" onClick={() => changePath(name)}
								className={`nodrag !py-1 !px-2 ${node?.meta?.type === name ? "bg-yellow-700 hover:bg-yellow-700" : ""}`}
							>
								<BtnIcon className="w-4 h-4" />
							</Button>
						</Tooltip>
					)
					)}
				<Tooltip singleton={typeTipSource} />
			</div>

			{ loading ?
				<p>Loading...</p>
			: error ?
				<p>Error: { error.toString() }</p>
			: node?.meta?.type ?
				<> { node.content?.map((value, index) => (
					<div key={`${value}-${index}`} className="group-sec relative w-full p-1">
						<MediaPreview
							location={MediaLocation.Local}
							type={node?.meta?.type}
							src={value}
							changeMedia={(name: string) => handleSelectUpdate(name, index)}
							className={node?.meta?.type === MediaType.Audio ? "mt-2" : ""}
						/>

						{ node?.content?.length > 1 &&
							<Tooltip content="Delete option">
								<button
									className={`group-icon absolute hidden h-7
										group-sec-hover:block group-sec-focus-within:block
										top-1 right-1 px-[0.4rem] bg-[#FFFFFFAA] border-0 rounded
										hover:bg-red-500
									`}
									onClick={() => { handleDeleteField(index) }}
								>
									<FillIcon Line={Trash} Fill={TrashFill}
										iconStyle="w-4 h-4"
										fillStyle="fill-white"
									/>
								</button>
							</Tooltip>
						}

						{ node?.meta?.type === MediaType.Animation &&
							<div className="flex flex-row mt-1 space-between space-x-1">
								<button
									className="check group-icon flex flex-row w-1/2 px-2 py-1 rounded"
									onClick={handleAutoplayUpdate}
								>
									<FillIcon Line={Circle} Fill={CheckCircleFill}
										groupStyle="mr-2 overflow-visible"
										iconStyle="w-4 h-4"
										alwaysShowFill={autoplay}
									/>
									<p>Autoplay</p>
								</button>
								<button
									className="check group-icon flex flex-row w-1/2 px-2 py-1 rounded"
									onClick={handleLoopUpdate}
								>
									<FillIcon Line={Circle} Fill={CheckCircleFill}
										groupStyle="mr-2 overflow-visible"
										iconStyle="w-4 h-4"
										alwaysShowFill={loop}
									/>
									<p>Loop</p>
								</button>
							</div>
						}
					</div>
				)) } </>
				:
				<div className="p-2 pt-1">
					&uarr; Choose a media type.
				</div>
			}
		</Node>
	);
};

export default BotMediaNode;
