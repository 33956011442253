import { ChangeEvent, useEffect, useState } from "react";
import firebase from "../../../firebase";
import { get, getDatabase, orderByChild, query, ref, set } from "firebase/database";
import { useListVals } from "react-firebase-hooks/database";
import { Story, StoryFlowMsg, StoryType } from "../../../types";
import Button from "../../../utils/Button";
import FileInput from "../../../utils/FileInput";
import Tooltip from "../../../utils/Tooltip";
import { Tab } from "@headlessui/react";
import { ArrowRepeat, BrightnessAltHighFill, CloudDownload, CloudUpload, PersonFillCheck, Recycle } from "react-bootstrap-icons";


const db = getDatabase(firebase);

type StorySettingsAdvancedProps = {
	story: Story;
	setStory: (storyID: string, story: Story) => void;
};

const StorySettingsAdvanced = ({ story, setStory }: StorySettingsAdvancedProps) => {
	const storiesRef = ref(db, "stories/");
	const [stories, storiesLoading, storiesError] = useListVals<Story>(query(storiesRef, orderByChild("name")), { keyField: "id" });
	useEffect(() => {
		if (storiesError) console.error(storiesError);
	}, [storiesError]);

	const [selectedImportFile, setSelectedImportFile] = useState<File>();

	const downloadStory = async () => {
		const flowsDownload = await Promise.all(Object.keys(story.flows).map(async flowID => {
			const flow = (await get(ref(db, 'storyFlows/' + flowID))).val();

			return {
				id: flowID,
				flow: flow
			};
		}));

		const storyDownload = {
			story: story,
			flows: flowsDownload
		};

		const data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(storyDownload));

		const downloadAnchor = document.createElement("a");
		downloadAnchor.setAttribute("href", data);
		downloadAnchor.setAttribute("download", story.name + ".botany");
		document.body.appendChild(downloadAnchor);
		downloadAnchor.click();
		downloadAnchor.remove();
	};

	const handleSelectImportFile = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files ? event.target.files[0] : undefined;
		setSelectedImportFile(file);
	};

	const uploadStory = async () => {
		if (!selectedImportFile) return;
		const importedData = await selectedImportFile.text();
		const importedStory = JSON.parse(importedData);

		set(ref(db, "stories/" + story.id), importedStory.story);
		importedStory.flows.forEach((flow: { id: string, flow: Record<string, StoryFlowMsg> }) => set(ref(db, "storyFlows/" + flow.id), flow.flow));
	};


	const setUniqueStoryType = (type: StoryType) => {
		if(storiesLoading) return;

		const newStory = { ...story };
		newStory.type = type;

		const oldStory = stories!.filter(s => s.type === type)![0];
		const updatedOldStory = { ...oldStory };
		updatedOldStory.type = StoryType.Test;

		setStory(newStory.id, newStory);
		setStory(updatedOldStory.id, updatedOldStory);
	};

	return (
		<Tab.Panel>
			<h3 className="mt-4">Information</h3>
			<p className="mb-6">Story ID: {story.id}</p>

			<h3>Backup</h3>
			<p className="mb-2">
				Make and restore manual backups of this story.
				<br />
				<span className="text-red-600">Warning:</span> uploading will override the current version of the story. This action is irreversible.
			</p>

			<Button variant="dark" className="mr-4 mb-2" onClick={downloadStory}>
				<CloudDownload className="w-5 h-5 mr-2" /> Download story
			</Button>

			<div className="mb-6">
				<FileInput
					accept=".botany"
					className="inline-block w-[50%] h-10 file:px-2 file:py-1"
					onChange={handleSelectImportFile}
				/>
				<Tooltip content="Done!" trigger="click" placement="right">
					<Button variant="dark" onClick={uploadStory}>
						<CloudUpload className="w-5 h-5 mr-2" /> Upload story
					</Button>
				</Tooltip>
			</div>

			<h3>Advanced story types</h3>
			<Tooltip content="Done!" trigger="click" placement="right">
				<Button variant="dark" onClick={() => setUniqueStoryType(StoryType.Onboarding)} className="mr-2 mb-2">
					<BrightnessAltHighFill className="w-5 h-5 mr-2" /> Set as Onboarding
				</Button>
			</Tooltip>
			<Tooltip content="Done!" trigger="click" placement="right">
				<Button variant="dark" onClick={() => setUniqueStoryType(StoryType.ReOnboarding)} className="mr-2 mb-2">
					<Recycle className="w-5 h-5 mr-2" /> Set as Re-onboarding
				</Button>
			</Tooltip>
			<Tooltip content="Done!" trigger="click" placement="right">
				<Button variant="dark" onClick={() => setUniqueStoryType(StoryType.ChooseProgramme)} className="mr-2 mb-2">
					<ArrowRepeat className="w-5 h-5 mr-2 -mt-px" /> Set as Choose Your Programme
				</Button>
			</Tooltip>
			<Tooltip content="Done!" trigger="click" placement="right">
				<Button variant="dark" onClick={() => setUniqueStoryType(StoryType.DailyCheck)} className="mr-2 mb-2">
					<PersonFillCheck className="w-5 h-5 mr-2 -mt-px" /> Set as Daily Check-in
				</Button>
			</Tooltip>
		</Tab.Panel>
	);
};

export default StorySettingsAdvanced;
