import { forwardRef, ReactElement } from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import { roundArrow } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/animations/shift-away.css';


type TooltipProps = {
    children?: ReactElement,
    trigger?: TippyProps['trigger'],
    content?: TippyProps['content'],
    placement?: TippyProps['placement'],
    singleton?: TippyProps['singleton'],
    moveTransition?: TippyProps['moveTransition']
};

const Tooltip = forwardRef(({ children, trigger, content, placement, singleton, moveTransition }: TooltipProps, ref: any) => {
    const baseProps = {
        content: content,
        trigger: trigger,
        placement: placement,
        singleton: singleton,
        arrow: roundArrow,
        animation: "shift-away",
        moveTransition: moveTransition,
        inertia: true,
        className: "fill-[#333]"
    };

    if(children) {
        return (
            <Tippy
                reference={ref}
                {...baseProps}
            >
                { children }
            </Tippy>
        );
    }
    else {
        return (
            <Tippy
                reference={ref}
                {...baseProps}
            />
        );
    }
});

export default Tooltip;
