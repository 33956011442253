import { Fragment, useRef, useState } from "react";
import FillIcon from "./FillIcon";
import Popover from "./Popover";
import Button from "./Button";
import Tooltip from "./Tooltip";
import { useSingleton } from "@tippyjs/react";
import { Icon, Trash, TrashFill } from "react-bootstrap-icons";
import { Tab } from "@headlessui/react";


type TabButtonSProps = {
    id: string,
    type: string,
    title: string,
    secondaryTitle?: string,
    SecondaryIcon?: Icon,
    handleDelete?: (id: string) => void,
    handleSecondary?: (id: string) => void
};

const TabButtonS = ({ id, type, title, secondaryTitle, SecondaryIcon, handleDelete, handleSecondary }: TabButtonSProps) => {
    const [showDeleteMessage, setShowDeleteMessage] = useState(false);
    const btnDeleteRef = useRef<HTMLButtonElement | null>(null);
    const [tipSource, tipTarget] = useSingleton();

    return (
        <Tab as={Fragment}>
            {({ selected }) => (
                <div
                    role="button"
                    className={`group flex flex-row items-center relative w-full min-h-[2rem] mt-1 px-2 py-1
                        rounded-md hover:bg-gray-50 cursor-pointer
                        ${showDeleteMessage || selected ? "bg-gray-50" : ""}
                    `}
                >
                    <span className={`w-full ${selected ? "font-semibold" : ""} active:font-semibold transition-[font-weight]`}>{ title }</span>
                    
                    { handleSecondary && secondaryTitle && SecondaryIcon &&
                        <Tooltip content={secondaryTitle} singleton={tipTarget}>
                            <button
                                className={`group-icon absolute h-8 w-8 right-8 top-0 px-[0.35rem] bg-gray-50 rounded-md cursor-pointer
                                    ${showDeleteMessage || selected ? "block" : "hidden"} hover:bg-green-500 group-hover:block
                                `}
                                onClick={() => { handleSecondary(id) }}
                            >
                                <SecondaryIcon className="w-5 h-5 fill-green-500 group-icon-hover:fill-white" />
                            </button>
                        </Tooltip>
                    }
        
                    { handleDelete && <>
                        <Popover
                            variant="danger"
                            onShow={() => setShowDeleteMessage(true)}
                            onHide={() => setShowDeleteMessage(false)}
                            className="cursor-auto"
                            content={<>
                                <p className="mb-4">
                                    Are you sure you want to delete "{title}"? This can't be reversed!
                                </p>
                                <Button
                                    variant="danger"
                                    onClick={() => { handleDelete(id) }}
                                >
                                    Delete {type}
                                </Button>
                            </>}
                        >
                            <button
                                ref={btnDeleteRef}
                                className={`group-icon absolute h-8 w-8 top-0 right-0 px-[0.375rem] bg-gray-50 rounded-md cursor-pointer
                                    ${showDeleteMessage || selected ? "block" : "hidden"} hover:bg-red-500 group-hover:block
                                `}
                            >
                                <FillIcon
                                    Line={Trash}
                                    Fill={TrashFill}
                                    iconStyle="w-5 h-5 pt-px fill-red-500 group-icon-hover:fill-white"
                                />
                            </button>
                        </Popover>
                        <Tooltip content={`Delete ${type}`} ref={btnDeleteRef} singleton={tipTarget} />
                    </> }
                    
                    <Tooltip singleton={tipSource} />
                </div>
            )}
        </Tab>
    );
};

export default TabButtonS;
