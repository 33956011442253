import { useEffect, useState } from "react";
import FeedbackCard from "./FeedbackCard";
import TabSpace from "../utils/TabSpace";
import TabButtonL from "../utils/TabButtonL";
import { FeedbackItem } from "../types";
import firebase from "../firebase";
import { DataSnapshot, getDatabase, ref } from "firebase/database";
import { useList } from "react-firebase-hooks/database";
import { Tab } from "@headlessui/react";
import { Bug, Diagram3, Icon, Lightbulb, Reply, Tags } from "react-bootstrap-icons";


const db = getDatabase(firebase);

const Feedback = () => {
    const [feedback, setFeedback] = useState<Record<string, FeedbackItem>>({});
    const [loading, setLoading] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);


    const feedbackRef = ref(db, "feedback/");
	const [feedbackSnaps, feedbackLoading, feedbackError] = useList(feedbackRef);

    useEffect(() => {
		if(!feedbackSnaps) return;

		const newFeedback: Record<string, FeedbackItem> = {};
		feedbackSnaps.forEach((snap: DataSnapshot) => {
			const feedbackID = snap.key!;
			const feedbackItem: FeedbackItem = {...snap.val()};
			newFeedback[feedbackID] = feedbackItem;
		});

		setFeedback(newFeedback);
	}, [feedbackSnaps]);

    useEffect(() => {
        feedbackLoading ? setLoading(true) : setLoading(false);
    }, [feedbackLoading]);

    useEffect(() => {
        if(feedbackError) console.error(feedbackError);
    }, [feedbackError]);


    const handleTabSelect = (index: number) => {
        setSelectedIndex(index);
    };


    const navTitles: [string, Icon][] = [
        ["All feedback", Reply],
        ["Problems", Bug],
        ["Suggestions", Lightbulb],
        ["Story reflections", Diagram3],
        ["Other", Tags]
    ];
    const navItems = navTitles.map(([title, icon], index) => {
        return (
            <TabButtonL
                key={`fb-nav-${index}`}
                title={title}
                Icon={icon}
            />
        );
    });


    const filteredFeedback = Object.entries(feedback).filter(([_, fb]) => {
        return selectedIndex === 1 ? fb.type === "bug" : selectedIndex === 2 ? fb.type === "feat" : selectedIndex === 3 ? fb.type === "story" : selectedIndex === 4 ? fb.type === "program" : selectedIndex === 5 ? fb.type === "general" : true;
    });
    const reverseFilFeedback = filteredFeedback.reverse();

    // It's a bit shitty to duplicate this six times but also ¯\_(ツ)_/¯
    const tabItems = [0, 1, 2, 3, 4, 5].map(no => ([
        <Tab.Panel key={`fb-tab-${no}`}>
            { reverseFilFeedback.map(([key, fb]) => {
                return (
                    <FeedbackCard key={key} id={key} {...fb} />
                );
            }) }
        </Tab.Panel>
    ]));


    return (
        <TabSpace
            name="feedback"
            navItems={navItems}
            tabItems={tabItems}
            loading={loading}
            selectedIndex={selectedIndex}
            handleSelect={handleTabSelect}
        />
    );
};

export default Feedback;
