import firebase from '../../firebase';
import { Media, MediaLocation, MediaType } from '../../types';
import Input from '../../utils/Input';
import MediaPreview from '../../utils/MediaPreview';
import { getDatabase, ref, set } from 'firebase/database';
import { ArrowUpRightSquareFill, CheckLg } from 'react-bootstrap-icons';


const db = getDatabase(firebase);

type MediaCardProps = {
	location: "panel" | "modal";
	file: Media;
	fileType: MediaType;
	selectedID?: string;
	onPanelSelect: (id: string) => void;
	onModalSelect?: (mediaID: string) => void;
};

const MediaCard = ({ location, file, fileType, selectedID, onPanelSelect, onModalSelect }: MediaCardProps) => {
	const updateAltText = async (val: string) => {
		set(ref(db, "media/" + file.id + "/alt"), val);
	};

	const isIcon = fileType === MediaType.Icon;
	const isAudio = fileType === MediaType.Audio;

	const height = isIcon ? "!h-40" : isAudio ? "h-auto" : "!h-60";
	const width = isIcon ? "!w-40" : isAudio ? "!w-80" : "!w-60";

	const isSelected = selectedID === file.id;

	return (
		<div className={`group relative ${width} ${isAudio ? `rounded-lg ${isSelected ? "bg-gray-21" : "hover:bg-gray-21"}` : ""}`}>
			<MediaPreview
				location={MediaLocation.Local}
				type={fileType}
				src={file.name}
				className={`${height} ${width} ${isAudio ? "" : `bg-gray-21 rounded-b-none ${isSelected ? "opacity-50" : ""}`}`}
			/>

			{ (location === "modal" && onModalSelect) &&
				<button
					className={`absolute top-0 left-0 flex flex-col items-center justify-center opacity-0 hover:opacity-100 cursor-pointer
						${isAudio ? "h-full w-full bg-[#000000CC] rounded" : `${height} ${width} bg-[#000000AA] rounded-t-lg`}
					`}
					onClick={() => onModalSelect(file.name)}
				>
					<ArrowUpRightSquareFill className="h-8 w-8 mb-4 fill-white" />
					<p className="text-white">Select</p>
				</button>
			}

			{ location === "panel" &&
				<button
					className={`absolute w-8 h-8 bg-gray-10 border-2 border-olive-900 rounded-full
						${isAudio ? "bottom-1.5 left-2" : "top-4 left-4"}
						${isSelected ? "block" : "hidden group-hover:block"}
					`}
					onClick={() => onPanelSelect(file.id)}
				>
					<CheckLg className={`w-5 h-5 mt-[3px] ml-[3px] fill-olive-600 ${isSelected ? "block" : "hidden"}`} />
				</button>
			}

			<div className="w-full">
				<Input
					init={file.alt ?? ""}
					className={`w-full !px-2 ${isAudio ? "mt-2" : "rounded-t-none"}`}
					placeholder={isAudio ? "Display name" : "Alt text"}
					onBlur={updateAltText}
				/>
			</div>

			<div className="w-full px-2 py-3 rounded-l-md">
				<p
					className={`block font-medium text-center truncate ${selectedID === file.id ? "text-green-600" : ""}`}
					title={file.name}
				>
					{file.name}
				</p>
			</div>
		</div>
	);
};

export default MediaCard;
