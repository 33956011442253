import { ReactNode } from "react";
import FillIcon from "./FillIcon";
import { XCircle, XCircleFill } from "react-bootstrap-icons";


type OffcanvasProps = {
    children: ReactNode;
    show: boolean;
    title: string;
    size?: "md" | "lg";
    close: () => void;
};

const Offcanvas = ({ children, show, title, size, close }: OffcanvasProps) => {
    return ( <>
        { show && 
            <div
                className={`fixed w-screen h-screen top-0 left-0 bg-black z-40 ${show ? "opacity-50" : "opacity-0"} transition-opacity ease-[cubic-bezier(.25,.8,.25,1)] duration-200`}
                onClick={close}
            />
        }

        <div
            className={`absolute ${size === "lg" ? "w-[850px]" : "w-[500px]"} h-full overflow-y-scroll right-0 px-6 pb-52 rounded-l-lg bg-gray-10 shadow z-40 ${
                show ? "translate-x-0" : size === "lg" ? "translate-x-[850px]" : "translate-x-[500px]"
            } transition ease-[cubic-bezier(.25,.8,.25,1)] duration-200`}
        >
            <div className="sticky top-0 pt-4 bg-gray-10 z-50">
                <div className="flex justify-between my-2">
                    <h2 className="text-2xl font-semibold">{ title }</h2>
                    <button className="group-icon p-0 border-0 bg-none" onClick={close}>
                        <FillIcon Line={XCircle} Fill={XCircleFill} iconStyle="w-6 h-6 transition" fillStyle="fill-red-600 active:fill-red-700" />
                    </button>
                </div>
                <hr className="border-black" />
            </div>

            <div>
                { children }
            </div>
        </div>
    </> )
};

export default Offcanvas;
